.ProductTile {
  // TODO: stacking contexts are mixed up here
  $stacking: background, top, badge, wrapper, touchedOverlay, checkedOverlay, tileButton, check;
  $indicator-size: 10px;
  $price-icon-size: 5px;
  $check-size: 50px;

  position: relative;
  z-index: 0;
  display: block;
  width: 100%;
  overflow: hidden;

  &__wrapper {
    @include absolute-overlay(z($stacking, wrapper));
  }

  &__checkedOverlay {
    @include absolute-overlay(z($stacking, checkedOverlay), true) {
      background-color: var(--primary-contrast-bg-main-alpha-medium);
      opacity: 0;
    }
  }

  &__touchedOverlay {
    @include absolute-overlay(z($stacking, touchedOverlay), true) {
      background: var(--primary-active-bg-main);
      opacity: 0;
      mix-blend-mode: multiply;
    }
  }

  &__image {
    z-index: z($stacking, background);
    padding: $space-xs $space-base;
    margin: $space-base 0 0;
    height: 60%;
  }

  &__check {
    position: absolute;
    top: 0;
    right: 0;
    height: $check-size;
    width: $check-size;
    color: var(--primary-contrast-fg-main);
    z-index: z($stacking, check);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $icon-size-base;
  }

  &__top {
    position: absolute;
    left: $space-base;
    top: $space-base;
    display: flex;
    align-items: center;
    height: $indicator-size;
    z-index: z($stacking, top);

    & > * + * {
      margin-left: $space-xs;
    }
  }

  &__badge {
    font-size: $font-size-xxs;
    display: none;
    position: absolute;
    top: $space-base;
    right: $space-base;
    z-index: z($stacking, badge);

    @include media(m) {
      display: block;
    }

    @include media(l) {
      font-size: $font-size-xs;
    }
  }

  &__year {
    font-size: $font-size-xs;
    color: var(--primary-fg-support);
  }

  &__title,
  &__price,
  &__priceIcon {
    color: var(--primary-fg-main);
  }

  &__title {
    width: 100%;
    padding: 0 $space-xs;
    text-align: center;
    font-weight: $font-weight-bold;

    * {
      @include scut-truncate;
    }
  }

  &__title,
  &__price {
    font-size: $font-size-s;
    line-height: $line-height-s;

    @include media(xl) {
      font-size: $font-size-base;
    }
  }

  &__price {
    width: 100%;
    text-align: center;
  }

  &__priceIcon {
    display: inline-block;
    width: $price-icon-size;
    height: $price-icon-size;
    background: currentColor;
    border-radius: 50%;
  }

  &__originalPrice {
    text-decoration: line-through;
    margin-left: $space-xs;
    color: var(--primary-fg-support);
  }

  &__tileButton {
    z-index: z($stacking, tileButton);
    position: absolute;
    top: 0;
    right: 0;
  }

  &--size-default {
    height: $product-tile-height;

    @include media(xl) {
      .ProductTile__image {
        padding: 0;
        margin-bottom: $space-base;
      }
    }
  }

  &--size-s {
    height: $product-tile-height-s;
  }

  &--checked &__tileButton {
    display: none;
  }

  &--checked &__checkedOverlay {
    opacity: 1;
  }

  &--touched &__touchedOverlay {
    opacity: 1;
  }

  &--background {
    &-default {
      background-color: var(--primary-bg-main);
    }

    &-gradient {
      background-color: var(--primary-bg-main);

      &.ProductTile::before {
        content: "";
        position: absolute;
        display: block;
        height: calc($product-tile-height / 4);
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to top, var(--primary-bg-gradient-support-to-main));
      }
    }
  }

  &--switchLines {
    .ProductTile {
      &__title {
        font-weight: unset;
        letter-spacing: 0.05em;
      }

      &__priceIcon {
        margin: 0 $space-xs 3px;
      }

      &__footer {
        width: 100%;
        text-align: center;
      }

      &__brandName,
      &__price {
        width: unset;
        display: inline-block;
      }

      &__brandName {
        font-weight: $font-weight-bold;
      }
    }
  }
}
