.PrintModalLayout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &__preview {
    flex-shrink: 0;
    width: 300px; // Design decision
    margin-right: 2 * $space-xxl;
  }

  &__content {
    overflow: hidden;

    & > * + * {
      margin-top: $space-base;
    }
  }
}
