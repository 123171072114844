.SingleInputFormLayout {
  display: flex;
  align-items: flex-start;
  gap: $space-l;

  &__sideComponents {
    display: flex;
    flex-shrink: 0;
    height: $field-height;
    align-items: center;
  }

  &--labeledInput {
    .SingleInputFormLayout__sideComponents {
      // We need to use the variable $labeled-field-label-height here,
      // because the sideComponents have to be on the same height like the input.
      margin-top: $labeled-field-label-height;
    }
  }

  &--isSmallInput {
    .SingleInputFormLayout__sideComponents {
      height: $field-height-s;
    }
  }

  &--layout {
    &-default {
      .SingleInputFormLayout__input {
        width: $width-base;
      }
    }

    &-input-auto-width {
      .SingleInputFormLayout__input {
        width: auto;
      }
    }

    &-full-width {
      width: 100%;

      .SingleInputFormLayout__input {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
      }

      .SingleInputFormLayout__sideComponents {
        flex-shrink: 0;
      }
    }
  }
}
