.RadioFieldWithImage {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  user-select: none;

  &__input {
    @include radio-base();
  }

  &__label {
    margin-top: $space-base;
    text-align: center;
  }

  &__image {
    margin-bottom: $space-base;
    border-radius: $border-radius-l;
    overflow: hidden;
    border: $border-width-l solid var(--color-util-transparent);
    transition: box-shadow $transition-fast;
  }

  &--inlineRadio {
    .RadioFieldWithImage__image {
      margin-bottom: $space-s;
    }

    .RadioFieldWithImage__input {
      position: absolute;
      top: $space-xxs;
      right: $space-xxs;
    }

    .RadioFieldWithImage__label {
      margin-top: 0;
    }
  }

  &--checked {
    .RadioFieldWithImage__input:after {
      background: var(--primary-accent-bg-main);
    }

    .RadioFieldWithImage__image {
      border: $border-width-l solid var(--primary-fg-accent);
      box-shadow: 0 $space-base $space-xxl var(--color-shadow-medium);
    }

    .RadioFieldWithImage__label {
      font-weight: $font-weight-medium;
    }
  }

  &[aria-disabled="true"] {
    &.RadioFieldWithImage--checked {
      .RadioFieldWithImage__input:after {
        background: var(--primary-disabled-bg-main);
      }
    }

    .RadioFieldWithImage__image {
      opacity: 0.5;
    }

    .RadioFieldWithImage__label {
      color: var(--primary-disabled-fg-main);
    }
  }

  &--kind {
    &-main {
      .RadioFieldWithImage__image {
        background: var(--primary-bg-main);
      }

      &:focus-within {
        .RadioFieldWithImage__input:before {
          border-color: var(--primary-fg-accent);
        }
      }

      &__label {
        margin-top: $space-base;
        color: var(--primary-fg-main);
      }
    }

    &-support {
      .RadioFieldWithImage__image {
        background: var(--primary-bg-support);
      }

      &:focus-within {
        .RadioFieldWithImage__input:before {
          border-color: var(--primary-fg-accent);
        }
      }

      &__label {
        margin-top: $space-base;
        color: var(--primary-fg-main);
      }
    }
  }
}
