.SizingStatusIcon {
  &--status {
    &-fit {
      color: var(--primary-fg-positive);
    }

    &-error {
      color: var(--primary-fg-negative);
    }
  }
}
