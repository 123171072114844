.ProductDetailsContentLayout {
  $stacking: content, columnRight;
  $columnWidth: 300px;

  position: relative;
  height: 100vh; // browser fallback
  height: calc(var(--vh, 1vh) * 100);

  &__content {
    z-index: z($stacking, content);

    & > * {
      height: 100%;
    }
  }

  &__columnRight {
    position: absolute;
    z-index: z($stacking, columnRight);
    width: $columnWidth;
    height: calc(100% - #{$content-header-height});
    right: $space-l;
    bottom: 0;

    display: flex;
    flex-direction: column;
    gap: $space-xxl;
    padding: $space-l 0;
    transition: right $transition-fast $ease-in-out-quad;
  }

  &--alignment-right-center {
    .ProductDetailsContentLayout__columnRight {
      justify-content: center;
    }
  }

  &--alignment-right-bottom {
    .ProductDetailsContentLayout__columnRight {
      justify-content: flex-end;
    }
  }

  &--isAsideOpen {
    .ProductDetailsContentLayout__columnRight {
      transition: right 0.1 $ease-in-out-quad;
      right: -($columnWidth + $space-l);
    }
  }
}
