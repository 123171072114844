.ProductComparisonItemAdder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: $product-tile-height;
  border: $border-width-l dashed var(--primary-fg-support);

  &__children {
    padding: 0 $space-l;

    & > * + * {
      margin-top: $space-s;
    }
  }
}
