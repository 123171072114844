.InformationList {
  margin: 0;
  font-family: $font-family-mono;

  &__key {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    margin: 0;
    @include scut-truncate;

    &:after {
      content: ":";
    }
  }

  &__value {
    display: inline-block;
    vertical-align: top;
    width: 75%;
    margin: 0;
    padding-left: 1em;
  }

  &--variant {
    &-primary {
      color: var(--primary-fg-main);
    }
    &-standout {
      background-color: var(--standout-bg-main);
      color: var(--standout-fg-accent);
    }
  }
}
