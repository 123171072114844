.ProductDetailsModal {
  $stacking: content, image, gradient, footer, header, back, close;
  $image-width: 30%;

  position: relative;
  height: calc(100vh - #{$space-xxxl}); // Browser fallback
  height: calc(var(--vh, 1vh) * 100 - #{$space-xxxl});
  width: calc(100% - #{$space-xxxxl});
  box-shadow: $modal-box-shadow;

  &__loadingIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: $image-width;
    z-index: z($stacking, image);
    background: var(--primary-bg-main);
    box-shadow: $modal-box-shadow;
    overflow: hidden;

    & > .Image {
      margin: 0 -2 * $space-l 0 -4 * $space-l;
      width: calc(100% + #{6 * $space-l});
    }
  }

  &__zoom {
    position: absolute;
    bottom: $space-l;
    left: 50%;
    transform: translateX(-50%);
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: $image-width;
    z-index: z($stacking, content);
    background: var(--primary-bg-main);
  }

  &__scrollContainer {
    position: absolute;
    top: $space-xxxl;
    bottom: $space-xxl;
    left: $space-xxxl;
    right: $space-base;
    padding: 0 $space-xxxl;
    overflow-y: auto;

    &::after {
      display: none;
      content: "";
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: $space-xxl;
      background: linear-gradient(to bottom, var(--primary-bg-gradient-transparent-to-main)) no-repeat;
      pointer-events: none;
      z-index: z($stacking, gradient);
    }
  }

  &__header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: $space-l;
    margin-bottom: $space-xxl;
    background: var(--primary-bg-main);
    z-index: z($stacking, header);
  }

  &__footer {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: $space-l;
    margin-top: $space-l;
    text-align: center;
    z-index: z($stacking, footer);
  }

  &__back {
    display: none;
    position: absolute;
    bottom: $space-l;
    left: $space-xxl;
    z-index: z($stacking, back);
  }

  &__close {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    padding: $space-s;
    z-index: z($stacking, close);
  }

  &--isVerticallyScrollable {
    .ProductDetailsModal__scrollContainer {
      &::after {
        display: block;
      }
    }
  }

  &--layout-full-content-sticky {
    .ProductDetailsModal__image {
      display: none;
    }

    .ProductDetailsModal__content {
      left: 0;
    }

    .ProductDetailsModal__header {
      padding-left: $space-l;
    }

    .ProductDetailsModal__back {
      display: block;
    }
  }

  &--layout-full-content-static {
    .ProductDetailsModal__image {
      display: none;
    }

    .ProductDetailsModal__content {
      width: 100%;
      height: 100%;
      position: static;
      top: initial;
      right: initial;
      bottom: initial;
      left: initial;
    }

    .ProductDetailsModal__scrollContainer {
      overflow: hidden;
      width: 100%;
      height: 100%;

      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: min-content 1fr min-content;
      grid-template-areas:
        "header"
        "children"
        "footer";

      padding: $space-xxxl $space-xxxl $space-xxxl $space-xxxxl;
    }

    .ProductDetailsModal__header {
      grid-area: header;

      position: static;
      padding: 0 $space-l;
      margin-bottom: $space-xxl;
    }

    .ProductDetailsModal__children {
      grid-area: children;

      overflow-y: auto;
    }

    .ProductDetailsModal__footer {
      grid-area: footer;
    }

    .ProductDetailsModal__back {
      display: block;
    }
  }

  &--layout-full-image {
    .ProductDetailsModal__image {
      width: 100%;

      & > .Image {
        margin: 0 auto;
        width: 100%;
        max-width: 800px; // Design decision
      }
    }

    .ProductDetailsModal__zoom {
      display: none;
    }

    .ProductDetailsModal__content {
      display: none;
    }

    .ProductDetailsModal__back {
      display: block;
    }
  }
}
