body.colorscheme-mono.colormode-light {
  // NOTE: Below these are colors directly exported from the design system. Please do not edit manually.

  // FIGMA IMPORT LOCAL VARIABLES START
  // Core Variables
  --color-core-aqua-alpha-50-legacy: #2cdbb175;
  --color-core-aqua-bright-alpha-50-legacy: #53f2b175;
  --color-core-aqua-bright-legacy: #53f2b1;
  --color-core-aqua-dark-alpha-10-legacy: #19bfa316;
  --color-core-aqua-dark-legacy: #19bfa3;
  --color-core-aqua-legacy: #2cdbb1;
  --color-core-black-alpha-0: #00000000;
  --color-core-black-alpha-10: #00000016;
  --color-core-black-alpha-1: #00000002;
  --color-core-black-alpha-20: #0000002d;
  --color-core-black-alpha-30: #00000044;
  --color-core-black-alpha-40: #0000005b;
  --color-core-black-alpha-50: #00000075;
  --color-core-black-alpha-5: #0000000c;
  --color-core-black-alpha-60: #0000008c;
  --color-core-black-alpha-70: #000000a3;
  --color-core-black-alpha-80: #000000ba;
  --color-core-black-alpha-90: #000000d1;
  --color-core-black-alpha-95: #000000e8;
  --color-core-black: #000000;
  --color-core-blue-050: #ecf3f8;
  --color-core-blue-100: #dde9f3;
  --color-core-blue-200: #bbd3e7;
  --color-core-blue-300: #99bdda;
  --color-core-blue-400: #77a7ce;
  --color-core-blue-500: #5591c2;
  --color-core-blue-600: #3e7aab;
  --color-core-blue-700: #326289;
  --color-core-blue-800: #254967;
  --color-core-blue-900: #193144;
  --color-core-blue-950: #0c1822;
  --color-core-gray-050-alpha-50: #f2f3f375;
  --color-core-gray-050: #f2f3f3;
  --color-core-gray-10-alpha-50-legacy: #f6f6f675;
  --color-core-gray-10-legacy: #f6f6f6;
  --color-core-gray-100: #e8e9ea;
  --color-core-gray-15-legacy: #e6e6e6;
  --color-core-gray-20-legacy: #d1d7d9;
  --color-core-gray-200: #d1d3d5;
  --color-core-gray-30-alpha-20-legacy: #c2c6c92d;
  --color-core-gray-30-alpha-50-legacy: #c2c6c975;
  --color-core-gray-30-legacy: #c2c6c9;
  --color-core-gray-300-alpha-20: #babdc02d;
  --color-core-gray-300-alpha-50: #babdc075;
  --color-core-gray-300: #babdc0;
  --color-core-gray-40-alpha-20-legacy: #acb1b62d;
  --color-core-gray-40-alpha-50-legacy: #acb1b675;
  --color-core-gray-40-legacy: #acb1b6;
  --color-core-gray-400-alpha-20: #a3a7ab2d;
  --color-core-gray-400-alpha-50: #a3a7ab75;
  --color-core-gray-400: #a3a7ab;
  --color-core-gray-50-legacy: #81868b;
  --color-core-gray-500-alpha-50: #81868b75;
  --color-core-gray-500: #81868b;
  --color-core-gray-60-legacy: #444444;
  --color-core-gray-600: #757a7e;
  --color-core-gray-70-legacy: #333333;
  --color-core-gray-700-alpha-20: #5e62652d;
  --color-core-gray-700-alpha-30: #5e626544;
  --color-core-gray-700-alpha-70: #5e6265a3;
  --color-core-gray-700-alpha-90: #5e6265d1;
  --color-core-gray-700: #5e6265;
  --color-core-gray-80-legacy: #222222;
  --color-core-gray-800: #46494c;
  --color-core-gray-90-legacy: #111111;
  --color-core-gray-900-alpha-20: #2f31332d;
  --color-core-gray-900-alpha-30: #2f313344;
  --color-core-gray-900-alpha-50: #2f313375;
  --color-core-gray-900-alpha-80: #2f3133ba;
  --color-core-gray-900-alpha-95: #2f3133e8;
  --color-core-gray-900: #2f3133;
  --color-core-gray-950: #171819;
  --color-core-gray-asphalt-alpha-20-legacy: #2b2f322d;
  --color-core-gray-asphalt-alpha-30-legacy: #2b2f3244;
  --color-core-gray-asphalt-alpha-50-legacy: #2b2f3275;
  --color-core-gray-asphalt-alpha-80-legacy: #2b2f32ba;
  --color-core-gray-asphalt-alpha-95-legacy: #2b2f32e8;
  --color-core-gray-asphalt-alternative-bright-legacy: #2a5375;
  --color-core-gray-asphalt-alternative-legacy: #1f2b38;
  --color-core-gray-asphalt-legacy: #2b2f32;
  --color-core-green-050: #eef7ee;
  --color-core-green-100: #e0f0e1;
  --color-core-green-200: #c0e1c3;
  --color-core-green-300: #a1d2a4;
  --color-core-green-400: #82c386;
  --color-core-green-500: #53ad59;
  --color-core-green-600: #4c9d51;
  --color-core-green-700: #3c7e41;
  --color-core-green-800: #2d5e31;
  --color-core-green-900: #1e3f20;
  --color-core-green-950: #0f1f10;
  --color-core-green-bright-legacy: #8ac78e;
  --color-core-green-dark-legacy: #448e49;
  --color-core-green-legacy: #53ad59;
  --color-core-orange-050: #fff4e6;
  --color-core-orange-100: #ffebd1;
  --color-core-orange-200: #ffd7a2;
  --color-core-orange-300: #ffc274;
  --color-core-orange-400: #ffae45;
  --color-core-orange-500: #ff9000;
  --color-core-orange-600: #e88300;
  --color-core-orange-700: #ba6900;
  --color-core-orange-800: #8b4f00;
  --color-core-orange-900: #5d3400;
  --color-core-orange-950: #2e1a00;
  --color-core-orange-dark-legacy: #d67900;
  --color-core-orange-legacy: #ff9000;
  --color-core-red-050: #ffebef;
  --color-core-red-100: #ffdbe1;
  --color-core-red-200: #feb7c4;
  --color-core-red-300: #fe93a6;
  --color-core-red-400: #fe7088;
  --color-core-red-500-alpha-50: #fd3a5c75;
  --color-core-red-500: #fd3a5c;
  --color-core-red-600: #e63554;
  --color-core-red-700: #b82a43;
  --color-core-red-800: #8a2032;
  --color-core-red-900: #5c1521;
  --color-core-red-950: #2e0b11;
  --color-core-red-alpha-50-legacy: #fd3a5c75;
  --color-core-red-dark-legacy: #a9253b;
  --color-core-red-legacy: #fd3a5c;
  --color-core-white-alpha-0: #ffffff00;
  --color-core-white-alpha-10: #ffffff16;
  --color-core-white-alpha-1: #ffffff02;
  --color-core-white-alpha-20: #ffffff2d;
  --color-core-white-alpha-30: #ffffff44;
  --color-core-white-alpha-40: #ffffff5b;
  --color-core-white-alpha-50: #ffffff75;
  --color-core-white-alpha-5: #ffffff0c;
  --color-core-white-alpha-60: #ffffff8c;
  --color-core-white-alpha-70: #ffffffa3;
  --color-core-white-alpha-80: #ffffffba;
  --color-core-white-alpha-90: #ffffffd1;
  --color-core-white-alpha-95: #ffffffe8;
  --color-core-white: #ffffff;

  // Util Variables
  --color-util-black-alpha-05: var(--color-core-black-alpha-5);
  --color-util-black-alpha-10: var(--color-core-black-alpha-10);
  --color-util-black-alpha-20: var(--color-core-black-alpha-20);
  --color-util-black-alpha-30: var(--color-core-black-alpha-30);
  --color-util-black-alpha-40: var(--color-core-black-alpha-40);
  --color-util-black-alpha-50: var(--color-core-black-alpha-50);
  --color-util-black-alpha-60: var(--color-core-black-alpha-60);
  --color-util-black-alpha-70: var(--color-core-black-alpha-70);
  --color-util-black-alpha-80: var(--color-core-black-alpha-80);
  --color-util-black-alpha-90: var(--color-core-black-alpha-90);
  --color-util-black-alpha-95: var(--color-core-black-alpha-95);
  --color-util-black: var(--color-core-black);
  --color-util-transparent-clickable: var(--color-core-white-alpha-1);
  --color-util-transparent: var(--color-core-white-alpha-0);
  --color-util-white-alpha-05: var(--color-core-white-alpha-5);
  --color-util-white-alpha-10: var(--color-core-white-alpha-10);
  --color-util-white-alpha-20: var(--color-core-white-alpha-20);
  --color-util-white-alpha-30: var(--color-core-white-alpha-30);
  --color-util-white-alpha-40: var(--color-core-white-alpha-40);
  --color-util-white-alpha-50: var(--color-core-white-alpha-50);
  --color-util-white-alpha-60: var(--color-core-white-alpha-60);
  --color-util-white-alpha-70: var(--color-core-white-alpha-70);
  --color-util-white-alpha-80: var(--color-core-white-alpha-80);
  --color-util-white-alpha-90: var(--color-core-white-alpha-90);
  --color-util-white-alpha-95: var(--color-core-white-alpha-95);
  --color-util-white: var(--color-core-white);

  // Shadow Variables
  --color-shadow-accent: var(--color-core-black);
  --color-shadow-dense: var(--color-core-black-alpha-50);
  --color-shadow-light: var(--color-core-gray-400-alpha-20);
  --color-shadow-medium: var(--color-core-gray-700-alpha-30);
  --color-shadow-negative: var(--color-core-red-500);

  // Scrollbar Variables
  --color-scrollbar-border: var(--secondary-fg-minor);
  --color-scrollbar-thumb-active: var(--secondary-accent-bg-main);
  --color-scrollbar-thumb: var(--secondary-contrast-bg-main);
  --color-scrollbar-track-active: var(--secondary-bg-minor);
  --color-scrollbar-track: var(--secondary-bg-main);

  // Primary Variant
  --primary-accent-bg-main-alpha-light: var(--color-core-black-alpha-30);
  --primary-accent-bg-main-alpha-medium: var(--color-core-black-alpha-50);
  --primary-accent-bg-main: var(--color-core-black);
  --primary-accent-fg-main: var(--color-core-white);
  --primary-active-bg-main: var(--color-core-gray-200);
  --primary-active-fg-main: var(--color-core-gray-500);
  --primary-bg-main: var(--color-core-white);
  --primary-bg-minor: var(--color-core-gray-100);
  --primary-bg-support-alpha-medium: var(--color-core-gray-050-alpha-50);
  --primary-bg-support: var(--color-core-gray-050);
  --primary-contrast-bg-main-alpha-dense: var(--color-core-gray-900-alpha-80);
  --primary-contrast-bg-main-alpha-heavy: var(--color-core-gray-900-alpha-95);
  --primary-contrast-bg-main-alpha-light: var(--color-core-gray-900-alpha-30);
  --primary-contrast-bg-main-alpha-medium: var(--color-core-gray-900-alpha-50);
  --primary-contrast-bg-main: var(--color-core-gray-900);
  --primary-contrast-bg-minor: var(--color-core-black);
  --primary-contrast-bg-support: var(--color-core-gray-950);
  --primary-contrast-fg-main: var(--color-core-white);
  --primary-disabled-bg-main: var(--color-core-gray-100);
  --primary-disabled-bg-minor: var(--color-core-gray-300);
  --primary-disabled-bg-support: var(--color-core-gray-200);
  --primary-disabled-fg-main: var(--color-core-gray-500);
  --primary-feedback-bg-info: var(--color-core-blue-500);
  --primary-feedback-bg-negative: var(--color-core-red-legacy);
  --primary-feedback-bg-neutral: var(--color-core-gray-500);
  --primary-feedback-bg-positive: var(--color-core-green-500);
  --primary-feedback-bg-warning: var(--color-core-orange-500);
  --primary-feedback-fg-main: var(--color-core-white);
  --primary-fg-accent: var(--color-core-black);
  --primary-fg-active: var(--color-core-gray-500);
  --primary-fg-divider: var(--color-core-gray-200);
  --primary-fg-info: var(--color-core-blue-500);
  --primary-fg-main: var(--color-core-black);
  --primary-fg-minor: var(--color-core-gray-400);
  --primary-fg-negative: var(--color-core-red-500);
  --primary-fg-neutral: var(--color-core-gray-500);
  --primary-fg-positive: var(--color-core-green-500);
  --primary-fg-support: var(--color-core-gray-500);
  --primary-fg-warning: var(--color-core-orange-500);

  // Secondary Variant
  --secondary-accent-bg-main: var(--color-core-black);
  --secondary-accent-fg-main: var(--color-core-white);
  --secondary-active-bg-main: var(--color-core-gray-050);
  --secondary-active-fg-main: var(--color-core-gray-600);
  --secondary-bg-main: var(--color-core-gray-200);
  --secondary-bg-minor: var(--color-core-gray-400);
  --secondary-bg-support: var(--color-core-gray-300);
  --secondary-contrast-bg-main: var(--color-core-gray-800);
  --secondary-contrast-bg-minor: var(--color-core-gray-950);
  --secondary-contrast-bg-support: var(--color-core-gray-900);
  --secondary-contrast-fg-main: var(--color-core-gray-100);
  --secondary-disabled-bg-main: var(--color-core-gray-100);
  --secondary-disabled-bg-minor: var(--color-core-gray-300);
  --secondary-disabled-bg-support: var(--color-core-gray-200);
  --secondary-disabled-fg-main: var(--color-core-gray-500);
  --secondary-feedback-bg-info: var(--color-core-blue-600);
  --secondary-feedback-bg-negative: var(--color-core-red-600);
  --secondary-feedback-bg-neutral: var(--color-core-gray-500);
  --secondary-feedback-bg-positive: var(--color-core-green-600);
  --secondary-feedback-bg-warning: var(--color-core-orange-600);
  --secondary-feedback-fg-main: var(--color-core-gray-100);
  --secondary-fg-accent: var(--color-core-black);
  --secondary-fg-active: var(--color-core-gray-600);
  --secondary-fg-divider: var(--color-core-gray-100);
  --secondary-fg-info: var(--color-core-blue-600);
  --secondary-fg-main: var(--color-core-gray-800);
  --secondary-fg-minor: var(--color-core-gray-500);
  --secondary-fg-negative: var(--color-core-red-600);
  --secondary-fg-neutral: var(--color-core-gray-600);
  --secondary-fg-positive: var(--color-core-green-600);
  --secondary-fg-support: var(--color-core-gray-600);
  --secondary-fg-warning: var(--color-core-orange-600);

  // Tertiary Variant
  --tertiary-accent-bg-main: var(--color-core-black);
  --tertiary-accent-fg-main: var(--color-core-white);
  --tertiary-active-bg-main: var(--color-core-gray-300);
  --tertiary-active-fg-main: var(--color-core-gray-600);
  --tertiary-bg-main: var(--color-core-gray-050);
  --tertiary-bg-minor: var(--color-core-gray-200);
  --tertiary-bg-support: var(--color-core-gray-100);
  --tertiary-contrast-bg-main: var(--color-core-gray-500);
  --tertiary-contrast-bg-minor: var(--color-core-gray-800);
  --tertiary-contrast-bg-support: var(--color-core-gray-700);
  --tertiary-contrast-fg-main: var(--color-core-gray-050);
  --tertiary-disabled-bg-main: var(--color-core-gray-200);
  --tertiary-disabled-bg-minor: var(--color-core-gray-400);
  --tertiary-disabled-bg-support: var(--color-core-gray-300);
  --tertiary-disabled-fg-main: var(--color-core-gray-500);
  --tertiary-feedback-bg-info: var(--color-core-blue-600);
  --tertiary-feedback-bg-negative: var(--color-core-red-600);
  --tertiary-feedback-bg-neutral: var(--color-core-gray-500);
  --tertiary-feedback-bg-positive: var(--color-core-green-600);
  --tertiary-feedback-bg-warning: var(--color-core-orange-600);
  --tertiary-feedback-fg-main: var(--color-core-blue-050);
  --tertiary-fg-accent: var(--color-core-black);
  --tertiary-fg-active: var(--color-core-gray-600);
  --tertiary-fg-divider: var(--color-core-gray-050);
  --tertiary-fg-info: var(--color-core-blue-600);
  --tertiary-fg-main: var(--color-core-gray-700);
  --tertiary-fg-minor: var(--color-core-gray-400);
  --tertiary-fg-negative: var(--color-core-red-600);
  --tertiary-fg-neutral: var(--color-core-gray-500);
  --tertiary-fg-positive: var(--color-core-green-600);
  --tertiary-fg-support: var(--color-core-gray-500);
  --tertiary-fg-warning: var(--color-core-orange-500);

  // Standout Variant
  --standout-accent-bg-main: var(--color-core-gray-800);
  --standout-accent-fg-main: var(--color-core-white);
  --standout-active-bg-main: var(--color-core-gray-950);
  --standout-active-fg-main: var(--color-core-gray-200);
  --standout-bg-main-alpha-dense: var(--color-core-gray-900-alpha-80);
  --standout-bg-main-alpha-heavy: var(--color-core-gray-900-alpha-95);
  --standout-bg-main-alpha-light: var(--color-core-gray-900-alpha-20);
  --standout-bg-main-alpha-medium: var(--color-core-gray-900-alpha-50);
  --standout-bg-main: var(--color-core-gray-900);
  --standout-bg-minor: var(--color-core-gray-700);
  --standout-bg-support: var(--color-core-gray-800);
  --standout-contrast-bg-main: var(--color-core-gray-050);
  --standout-contrast-bg-minor: var(--color-core-gray-300);
  --standout-contrast-bg-support: var(--color-core-gray-200);
  --standout-contrast-fg-main: var(--color-core-gray-900);
  --standout-disabled-bg-main: var(--color-core-gray-800);
  --standout-disabled-bg-minor: var(--color-core-gray-200);
  --standout-disabled-bg-support: var(--color-core-gray-600);
  --standout-disabled-fg-main: var(--color-core-gray-400);
  --standout-feedback-bg-info: var(--color-core-blue-500);
  --standout-feedback-bg-negative: var(--color-core-red-500);
  --standout-feedback-bg-neutral: var(--color-core-gray-600);
  --standout-feedback-bg-positive: var(--color-core-green-500);
  --standout-feedback-bg-warning: var(--color-core-orange-500);
  --standout-feedback-fg-main: var(--color-core-gray-050);
  --standout-fg-accent: var(--color-core-white);
  --standout-fg-active: var(--color-core-gray-400);
  --standout-fg-divider: var(--color-core-gray-600);
  --standout-fg-info: var(--color-core-blue-500);
  --standout-fg-main: var(--color-core-white);
  --standout-fg-minor: var(--color-core-gray-500);
  --standout-fg-negative: var(--color-core-red-500);
  --standout-fg-neutral: var(--color-core-gray-500);
  --standout-fg-positive: var(--color-core-green-500);
  --standout-fg-support: var(--color-core-gray-300);
  --standout-fg-warning: var(--color-core-orange-500);

  // Accent Variant
  --accent-active-bg-main: var(--color-core-gray-950);
  --accent-active-fg-main: var(--color-core-gray-400);
  --accent-bg-main-alpha-medium: var(--color-core-black-alpha-50);
  --accent-bg-main: var(--color-core-black);
  --accent-contrast-bg-main: var(--color-core-white);
  --accent-contrast-fg-main: var(--color-core-black);
  --accent-disabled-bg-main: var(--color-core-gray-200);
  --accent-disabled-fg-main: var(--color-core-gray-500);
  --accent-fg-active: var(--color-core-gray-400);
  --accent-fg-main: var(--color-core-white);
  --accent-fg-minor: var(--color-core-gray-200);
  --accent-fg-support: var(--color-core-gray-100);

  // Notification Variant
  --notification-active-bg-main: var(--color-core-red-600);
  --notification-active-fg-main: var(--color-core-gray-200);
  --notification-bg-main-alpha-medium: var(--color-core-red-500-alpha-50);
  --notification-bg-main: var(--color-core-red-600);
  --notification-contrast-bg-main: var(--color-core-white);
  --notification-contrast-fg-main: var(--color-core-black);
  --notification-disabled-bg-main: var(--color-core-gray-200);
  --notification-disabled-fg-main: var(--color-core-gray-500);
  --notification-fg-active: var(--color-core-gray-400);
  --notification-fg-main: var(--color-core-white);
  --notification-fg-minor: var(--color-core-gray-200);
  --notification-fg-support: var(--color-core-gray-100);

  // Positive Variant
  --positive-active-bg-main: var(--color-core-green-600);
  --positive-active-fg-main: var(--color-core-gray-100);
  --positive-bg-main: var(--color-core-green-500);
  --positive-contrast-bg-main: var(--color-core-white);
  --positive-contrast-fg-main: var(--color-core-green-500);
  --positive-disabled-bg-main: var(--color-core-gray-200);
  --positive-disabled-fg-main: var(--color-core-gray-500);
  --positive-fg-main: var(--color-core-white);
  --positive-fg-minor: var(--color-core-white-alpha-80);
  --positive-fg-support: var(--color-core-white-alpha-90);

  // Negative Variant
  --negative-active-bg-main: var(--color-core-red-600);
  --negative-active-fg-main: var(--color-core-gray-100);
  --negative-bg-main: var(--color-core-red-500);
  --negative-contrast-bg-main: var(--color-core-white);
  --negative-contrast-fg-main: var(--color-core-red-500);
  --negative-disabled-bg-main: var(--color-core-gray-200);
  --negative-disabled-fg-main: var(--color-core-gray-500);
  --negative-fg-main: var(--color-core-white);
  --negative-fg-minor: var(--color-core-white-alpha-80);
  --negative-fg-support: var(--color-core-white-alpha-90);

  // Warning Variant
  --warning-active-bg-main: var(--color-core-orange-600);
  --warning-active-fg-main: var(--color-core-gray-100);
  --warning-bg-main: var(--color-core-orange-500);
  --warning-contrast-bg-main: var(--color-core-white);
  --warning-contrast-fg-main: var(--color-core-orange-500);
  --warning-disabled-bg-main: var(--color-core-gray-200);
  --warning-disabled-fg-main: var(--color-core-gray-500);
  --warning-fg-main: var(--color-core-white);
  --warning-fg-minor: var(--color-core-white-alpha-80);
  --warning-fg-support: var(--color-core-white-alpha-90);

  // Info Variant
  --info-active-bg-main: var(--color-core-blue-600);
  --info-active-fg-main: var(--color-core-gray-200);
  --info-bg-main: var(--color-core-blue-500);
  --info-contrast-bg-main: var(--color-core-white);
  --info-contrast-fg-main: var(--color-core-blue-500);
  --info-disabled-bg-main: var(--color-core-gray-200);
  --info-disabled-fg-main: var(--color-core-gray-500);
  --info-fg-main: var(--color-core-white);
  --info-fg-minor: var(--color-core-white-alpha-80);
  --info-fg-support: var(--color-core-white-alpha-90);

  // Neutral Variant
  --neutral-active-bg-main: var(--color-core-gray-950);
  --neutral-active-fg-main: var(--color-core-gray-100);
  --neutral-bg-main: var(--color-core-gray-900);
  --neutral-contrast-bg-main: var(--color-core-white);
  --neutral-contrast-fg-main: var(--color-core-gray-900);
  --neutral-disabled-bg-main: var(--color-core-gray-200);
  --neutral-disabled-fg-main: var(--color-core-gray-500);
  --neutral-fg-main: var(--color-core-white);
  --neutral-fg-minor: var(--color-core-white-alpha-80);
  --neutral-fg-support: var(--color-core-white-alpha-90);
  // FIGMA IMPORT LOCAL VARIABLES END

  // Gradients
  // NOTE: Do not replace when syncing export from FIGMA! FIGMA is currently not able to store gradients in locale variables.
  --primary-bg-gradient-minor-to-support: var(--primary-bg-minor), var(--primary-bg-support);
  --primary-bg-gradient-support-to-main: var(--primary-bg-support), var(--primary-bg-main);
  --primary-bg-gradient-support-to-transparent: var(--primary-bg-support), var(--color-util-transparent);
  --primary-bg-gradient-transparent-to-main: var(--color-util-transparent), var(--primary-bg-main);
  --primary-bg-gradient-transparent-to-minor: var(--color-util-transparent), var(--primary-bg-minor);
  --primary-contrast-bg-gradient-support-to-main: var(--primary-contrast-bg-support), var(--primary-contrast-bg-main);
}
