.FilterModalValues {
  &__title {
    margin-bottom: $space-xxs;
  }

  &__content {
    display: grid;
    gap: $space-base;

    & > .CheckboxListItem,
    & > .RadioListItem {
      width: $filter-item-width-media-s;

      @include media(m) {
        width: $filter-item-width-media-m;
      }

      @include media(xl) {
        width: $filter-item-width;
      }
    }

    & > .RangeField {
      margin-top: $space-base; // Add a little top spacing for the slider labels
      width: 2 * $filter-item-width-media-s;

      @include media(m) {
        width: 2 * $filter-item-width-media-m;
      }

      @include media(xl) {
        width: 2 * $filter-item-width;
      }
    }
  }

  &--columns {
    &-1 {
      .FilterModalValues__content {
        grid-template-columns: repeat(1, auto);
      }
    }

    &-2 {
      .FilterModalValues__content {
        grid-template-columns: repeat(2, auto);
      }
    }

    &-3 {
      .FilterModalValues__content {
        grid-template-columns: repeat(3, auto);
      }
    }

    &-5 {
      .FilterModalValues__content {
        grid-template-columns: repeat(5, auto);
      }
    }
  }

  &--justify-content {
    &-normal {
      .FilterModalValues__content {
        justify-content: normal;
      }
    }

    &-start {
      .FilterModalValues__content {
        justify-content: start;
      }
    }
  }
}
