.SpecsList {
  &__headline {
    color: var(--primary-fg-accent);
    border-bottom: $border-width-base var(--primary-fg-minor) solid;
    padding-bottom: $space-xs;
    text-transform: uppercase;
    margin-top: 0;
  }

  & > * + * {
    margin-top: 1em;
  }
}
