.FlexColumnFormLayout {
  $right-column-width: $field-width-s;

  display: flex;

  &__column {
    &:first-child {
      margin-right: $space-xxxl;
    }

    &:last-child {
      margin-right: 0;
      width: $right-column-width;
    }
  }
}
