.ForkSuspension {
  $offset-m: -25px;
  $offset-l: -54px;

  display: grid;
  height: 314px;
  width: 256px;
  overflow-y: hidden;
  background: var(--primary-bg-main);
  border: $border-width-base solid var(--primary-fg-minor);

  &__divider {
    fill: none;
    stroke: var(--primary-fg-minor);
    stroke-width: 1px;
    stroke-dasharray: 4, 4;
    opacity: 0;
    transition: all 0.3s ease;
  }

  &__hidden {
    display: none;
  }

  &__fork {
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linecap: square;
  }

  &__axis,
  &__nut,
  &__fork,
  &__forkhead,
  &__stabilizor,
  &__shaft {
    fill: var(--primary-bg-minor);
  }

  &__stabilizor {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &__suspension {
    fill: var(--primary-contrast-bg-main);
    transition: all 0.3s ease-in-out;
  }

  &__forkhead,
  &__divider-start,
  &__shaft {
    transition: all 0.3s ease-in-out;
  }

  &__divider-start,
  &__divider-end {
    animation: fade-in 0.3s;
    animation-delay: 0.7s;
    animation-fill-mode: forwards;
  }

  &__with-suspension,
  &__without-suspension {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  &__fork-without-suspension {
    fill: var(--primary-bg-minor);
  }

  // Animations
  &--suspension {
    &-enabled {
      .ForkSuspension__with-suspension {
        animation:
          fade-in 0.3s,
          slide-in-down 0.3s;
        animation-fill-mode: forwards;
      }

      .ForkSuspension__without-suspension {
        animation:
          fade-out 0.3s,
          slide-out-down 0.3s;
        animation-fill-mode: forwards;
      }
    }

    &-disabled {
      .ForkSuspension__without-suspension {
        animation:
          fade-in 0.3s,
          slide-in-down 0.3s;
        animation-fill-mode: forwards;
      }

      .ForkSuspension__with-suspension {
        animation:
          fade-out 0.3s,
          slide-out-down 0.3s;
        animation-fill-mode: forwards;
      }
    }
  }

  &--init-suspension {
    &-s {
      .ForkSuspension__forkhead,
      .ForkSuspension__shaft {
        animation: bounce-in-s 1s;
      }

      .ForkSuspension__stabilizor {
        opacity: 0;
      }
    }

    &-m {
      .ForkSuspension__forkhead,
      .ForkSuspension__shaft {
        animation: bounce-in-m 1s;
      }

      .ForkSuspension__stabilizor {
        opacity: 0;
      }
    }

    &-l {
      .ForkSuspension__forkhead,
      .ForkSuspension__shaft {
        animation: bounce-in-l 1s;
      }
    }
  }

  &--stabilization {
    &-enabled {
      .ForkSuspension__stabilizor {
        opacity: 1;
      }
    }

    &-disabled {
      .ForkSuspension__stabilizor {
        opacity: 0;
      }
    }
  }

  &--travel {
    &-m {
      .ForkSuspension__divider-end,
      .ForkSuspension__forkhead,
      .ForkSuspension__shaft {
        transform: translateY(-25px);
      }
    }

    &-l {
      .ForkSuspension__divider-end,
      .ForkSuspension__forkhead,
      .ForkSuspension__shaft {
        transform: translateY(-54px);
      }
    }

    &-s {
      .ForkSuspension__forkhead,
      .ForkSuspension__shaft {
        transform: translateY(0);
      }
    }
  }

  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    to {
      opacity: 0;
    }
  }

  @keyframes slide-in-down {
    from {
      transform: translateY(-100%);
    }
  }

  @keyframes slide-out-down {
    to {
      transform: translateY(100%);
    }
  }

  $bounce-offsets-map: (
    "s": "0px",
    "m": $offset-m,
    "l": $offset-l
  );

  @each $size, $offset in $bounce-offsets-map {
    @keyframes bounce-in-#{$size} {
      0% {
        transform: translateY(calc(#{$offset} - 1%));
      }
      5% {
        transform: translateY(calc(#{$offset} + 1%));
      }
      25% {
        transform: translateY(calc(#{$offset} - 0.7%));
      }
      50% {
        transform: translateY(calc(#{$offset} + 0.5%));
      }
      75% {
        transform: translateY(calc(#{$offset} - 0.25%));
      }
      100% {
        transform: translateY(#{$offset});
      }
    }
  }
}
