.SingleDescribedInputList {
  & > * + * {
    margin-top: $space-sl;
    padding-top: $space-sl;
  }

  &--separator > * + * {
    margin-top: $space-xxl;
    padding-top: $space-xxl;
    border-top: $border-width-base solid var(--primary-fg-divider);
  }
}
