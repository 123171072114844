.CustomerWorldNavigation {
  $customer-world-navigation-width: 580px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: $customer-world-navigation-width;
  padding: $space-xl $space-xxxl $space-xl + calc($field-height / 2);
  background: var(--standout-bg-main-alpha-heavy);
  color: var(--standout-fg-main);
  border-top-left-radius: $border-radius-l;
  border-top-right-radius: $border-radius-l;

  > * {
    flex-basis: 33%;
  }
}
