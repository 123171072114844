.VideoConferenceBox {
  $border-radius-whereby: 10px;
  $container-width: 400px;
  $webview-width: 400px;
  $webview-height: 330px;
  $toolbar-height: 40px;

  padding-top: $space-xl;
  width: $container-width;
  background: var(--standout-bg-main) url(../../resources/images/drag-handle-hint-subtle.svg) center 15px no-repeat;
  box-shadow: 0 0 50px var(--color-shadow-dense);
  border-radius: $border-radius-l;
  z-index: z($stacking-global, video-conference-box);
  position: absolute;
  top: 100px;
  right: 100px;

  &__customerUrl {
    text-align: center;
    color: var(--standout-fg-main);
    font-family: $font-family-mono;
    font-size: $font-size-xs;
    border-top: $border-width-base solid var(--standout-fg-main);
    margin: 0 $space-xs;
  }

  &__webview {
    height: $webview-height;
    width: $webview-width;
    margin: 0 auto;
    border: none;
  }

  &__toolbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: $toolbar-height;
  }

  &__volume,
  &__refresh {
    & > * {
      font-weight: $font-weight-medium !important;
    }
  }

  &__volume > * {
    padding-left: $space-base;
  }

  &__refresh > * {
    padding-right: $space-base;
  }

  &__endCall > * {
    border-radius: $border-radius-whereby;
    transform: translateY(50%);
  }
}
