.TableCell {
  @include scut-truncate;

  $horizontal-padding: $space-l;

  max-width: 20em;
  font-size: $font-size-xs;
  color: var(--primary-fg-main);

  @include media(l) {
    font-size: $font-size-s;
  }

  @include media(xl) {
    font-size: $font-size-base;
  }

  &__icon {
    display: inline-block;
    color: var(--primary-fg-accent);
    margin-right: $space-xs;
  }

  &--text-align {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }
  }

  &--vertical-padding {
    &-default {
      padding: $space-xs $horizontal-padding;

      @include media(l) {
        padding: $space-s $horizontal-padding;
      }

      @include media(xl) {
        padding: $space-base $horizontal-padding;
      }
    }

    &-s {
      padding: 0 $horizontal-padding;

      @include media(l) {
        padding: $space-xxs $horizontal-padding;
      }

      @include media(xl) {
        padding: $space-xs $horizontal-padding;
      }
    }

    &-none {
      padding: 0 $horizontal-padding;
    }
  }
}
