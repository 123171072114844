.ForkSuspensionFilterLayout {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-template-rows: repeat(2, min-content);
  column-gap: $space-base;
  row-gap: $space-xl;
  grid-template-areas:
    "filters divider illustration"
    "all  divider illustration";

  &__illustration {
    grid-area: illustration;
  }

  &__filters {
    display: grid;
    grid-auto-flow: column;
    gap: $space-base;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, $filter-item-width-media-s);

    @include media(m) {
      grid-template-columns: repeat(2, $filter-item-width-media-m);
    }

    @include media(xl) {
      grid-template-columns: repeat(2, $filter-item-width);
    }
  }

  &__filter-all {
    grid-area: all;
    width: $filter-item-width-media-s;

    @include media(m) {
      width: $filter-item-width-media-m;
    }

    @include media(xl) {
      width: $filter-item-width;
    }
  }

  &__divider {
    height: 100%;
    width: 1px;
    background-color: var(--primary-fg-minor);
    border: none;
    grid-row: 1 / -1;
    margin: 0 $space-xxs;
  }
}
