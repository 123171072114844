.InstructionCard {
  $padding: $space-sl;
  $border-radius: $border-radius-xl;
  $headline-bottom-margin: $space-xs;
  $footer-top-margin: $space-l;

  display: flex;
  flex-direction: column;

  border-radius: $border-radius;
  padding: $padding;
  width: 100%;

  background: var(--tertiary-bg-main);
  color: var(--tertiary-fg-main);

  &__headline {
    margin-bottom: $headline-bottom-margin;
  }

  &__instruction {
    flex-grow: 1;
  }

  &__footer {
    display: block;
    margin: $footer-top-margin auto 0 auto;
    color: var(--tertiary-fg-support);
  }
}
