.TabBarPageLayout {
  $stacking: mainContent, asideContentOverlay, asideContentOverlaySlot, asideContent, tabBarOverlay, tabbar;

  position: relative;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  &__tabBar {
    position: absolute;
    z-index: z($stacking, tabbar);
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__mainContent {
    position: absolute;
    z-index: z($stacking, mainContent);
    top: 0;
    left: 0;
    right: 0;
    bottom: $tab-bar-height-xs;

    @include media(m) {
      bottom: $tab-bar-height-s;
    }

    @include media(l) {
      bottom: $tab-bar-height;
    }

    & > * {
      height: 100%;
    }

    // Fix special case with MainContentLayout
    .MainContentLayout__content {
      min-height: calc(100vh - #{$tab-bar-height}); // Browser fallback
      min-height: calc(var(--vh, 1vh) * 100 - #{$tab-bar-height});
    }
  }

  // NOTE: `width` gets set in TSX file in `AsideContent`
  &__asideContent {
    background: linear-gradient(to top, var(--primary-bg-gradient-support-to-main));
    position: absolute;
    z-index: z($stacking, asideContent);
    top: 0;
    right: 0;
    bottom: 0;

    & > * {
      width: 100%;
      height: 100%;
    }
  }

  // NOTE: `width` & `backgroundColor` gets set in TSX file in `AsideContent`
  &__asideOverlay {
    position: absolute;
    z-index: z($stacking, asideContentOverlay);
    top: 0;
    left: 0;
    bottom: 0;
    // Summing up the `margin-bottom` at the botto of `MainContentLayout` via an util class and the `TabBar` to get the bottom padding
    // This keeps the bottom content of the aside overlay on the same height as in the main view of product details
    padding: $space-l $space-l $space-xl + $tab-bar-height $space-l;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__tabBarOverlay {
    background: var(--primary-bg-main-alpha-heavy);
    position: absolute;
    z-index: z($stacking, tabBarOverlay);
    top: 0;
    left: 0;
    right: 0;
    bottom: $tab-bar-height;
    overflow: hidden;

    & > * {
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
