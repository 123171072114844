.RadioField {
  height: $field-height;
  display: inline-flex;
  align-items: center;
  user-select: none;

  input[type="radio"] {
    @include radio-base(true) {
      appearance: none;
    }
  }

  &:focus-within {
    .RadioField__input:before {
      border-color: var(--primary-fg-accent);
    }
  }

  &__label {
    margin-left: $space-s;
  }

  &--checked {
    input[type="radio"]:after {
      background: var(--primary-accent-bg-main);
    }

    .RadioField__label {
      font-weight: $font-weight-active;
      letter-spacing: $letter-spacing-compensate-font-weight;
    }
  }

  &[aria-disabled="true"] {
    &.RadioField--checked {
      .RadioField__input:after {
        background: var(--primary-disabled-bg-main);
      }
    }

    .RadioField__label {
      color: var(--primary-disabled-fg-main);
    }
  }
}
