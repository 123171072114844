.Availability {
  $indicator-size: 10px;

  display: inline-flex;
  align-items: center;
  font-size: inherit;
  line-height: inherit;

  &::before {
    content: "";
    display: inline-block;
    flex-shrink: 0;
    height: $indicator-size;
    width: $indicator-size;
    border-radius: 50%;
  }

  &__label {
    display: inline-block;
    margin-left: 0.5em;
    color: var(--primary-fg-main);
  }

  &--block {
    display: flex;
  }

  &--kind {
    &-full::before {
      background-color: var(--primary-feedback-bg-positive);
    }

    &-partial::before {
      background-color: var(--primary-feedback-bg-warning);
    }

    &-unknown {
      &::before {
        background-color: var(--primary-feedback-bg-neutral);
      }

      .Availability__label {
        color: var(--primary-fg-minor);
      }
    }

    &-no::before {
      background-color: var(--primary-feedback-bg-negative);
    }

    &-error {
      .Availability__label {
        color: var(--primary-fg-negative);
      }

      &::before {
        background-color: var(--primary-feedback-bg-negative);
      }
    }
  }
}
