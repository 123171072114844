@use "sass:math";

.RangeField2 {
  $stacking-context: rail, track, handle;
  $rc-slider-rail-height: $border-width-l;
  $rc-slider-rail-height-half: math.div($border-width-l, 2);
  $rc-slider-handle-size: $field-height-s;
  $rc-slider-handle-size-half: math.div($rc-slider-handle-size, 2);
  $average-height: 20px;
  $average-height-half: math.div($average-height, 2);

  position: relative;
  width: 100%;
  padding: $rc-slider-handle-size-half $rc-slider-handle-size-half 0;
  z-index: 1; // Creates stacking context for its childs

  &__labels {
    display: flex;
    justify-content: space-between;
    margin: $rc-slider-handle-size-half -1 * $rc-slider-handle-size-half 0;
    gap: $space-l;
  }

  &__minLabel,
  &__maxLabel,
  &__averageLabel {
    font-size: $font-size-s;
    color: var(--primary-fg-support);
    pointer-events: none;
  }

  &__averageLabel {
    margin-top: $space-s;
    max-width: 50%;
    text-align: center;
  }

  &__average {
    position: relative;
  }

  &__averageTrack {
    position: absolute;
    background: var(--primary-bg-support);
    height: $average-height;
    margin-top: -1 * $average-height-half + $rc-slider-rail-height-half;
    border-radius: $average-height-half;
  }

  &__slider {
    .rc-slider {
      position: relative;
      width: 100%;

      &-rail {
        position: absolute;
        height: $rc-slider-rail-height;
        width: 100%;
        background: var(--primary-bg-minor);
        border-radius: $rc-slider-rail-height-half;
        z-index: z($stacking-context, rail);
        touch-action: manipulation;

        &::before,
        &::after {
          position: absolute;
          content: "";
          height: $rc-slider-rail-height;
          width: $rc-slider-handle-size-half;
        }

        &::before {
          left: -1 * $rc-slider-handle-size-half;
          background: var(--primary-accent-bg-main);
        }

        &::after {
          right: -1 * $rc-slider-handle-size-half;
          background: var(--primary-bg-minor);
        }
      }

      &-track {
        position: absolute;
        height: $rc-slider-rail-height;
        background: var(--primary-accent-bg-main);
        z-index: z($stacking-context, track);
        touch-action: manipulation;
      }

      &-handle {
        position: absolute;
        width: $rc-slider-handle-size;
        height: $rc-slider-handle-size;
        margin-top: -1 * $rc-slider-handle-size-half + $rc-slider-rail-height-half;
        border: $border-width-l solid var(--primary-fg-minor);
        border-radius: $rc-slider-handle-size-half;
        box-shadow: $balloon-box-shadow;
        background: var(--primary-bg-main);
        outline: 0;
        cursor: grab;
        touch-action: pan-x;
        z-index: z($stacking-context, handle);
        transition: border-color $transition-fast;

        &:active {
          cursor: grabbing;
          border-color: var(--primary-fg-accent);
        }
      }
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;

    &__minLabel,
    &__maxLabel,
    &__averageLabel {
      color: var(--primary-disabled-fg-main);
    }

    .rc-slider {
      &-rail {
        background: var(--primary-disabled-bg-minor);

        &::before {
          background: var(--primary-disabled-bg-main);
        }

        &::after {
          background: var(--primary-disabled-bg-minor);
        }
      }

      &-track {
        background: var(--primary-disabled-bg-main);
      }

      &-handle {
        background: var(--primary-disabled-bg-main);
        border: $border-width-l solid var(--primary-disabled-fg-main);

        &:active {
          border-color: var(--primary-disabled-fg-main);
        }
      }
    }

    &.RangeField2--range {
      .rc-slider {
        &-rail {
          background: var(--primary-disabled-bg-main);

          &::before {
            background: var(--primary-disabled-bg-main);
          }

          &::after {
            background: var(--primary-disabled-bg-main);
          }
        }

        &-track {
          background: var(--primary-disabled-bg-minor);
        }
      }
    }
  }

  &--range {
    &.RangeField2--range .RangeField2__slider .rc-slider-rail::before {
      background: var(--primary-bg-minor);
    }
  }
}
