.SearchListItem {
  display: flex;
  background: var(--primary-bg-main);
  border-bottom: 3px solid var(--primary-fg-minor);

  &__filter {
    position: relative;
    flex-grow: 1;
  }

  &__field {
    display: block;
    width: 100%;
    appearance: none;
    height: $list-item-height;
    border: 0;
    font-weight: $font-weight-medium;
    color: var(--primary-fg-main);
    padding-left: 2 * $space-s + $icon-size-base;
    padding-right: $space-s;
    outline: 0;

    &::placeholder {
      color: var(--primary-fg-minor);
      font-weight: $font-weight-regular;
    }
  }

  &__icon {
    color: var(--primary-fg-support);
    position: absolute;
    left: $space-s;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-right: 1em;
    pointer-events: none;
  }

  &__remove {
    border-left: 1px solid var(--primary-fg-divider);
    background: var(--primary-bg-main);
    color: var(--primary-fg-support);
    font-size: $font-size-xl;
    width: $list-item-height;
    outline: 0;
    flex-shrink: 0;

    & > .Icon {
      display: block;
      margin: auto;
    }
  }

  &--active {
    border-bottom-color: var(--primary-fg-accent);

    .SearchListItem__icon {
      color: var(--primary-fg-accent);
    }
  }
}
