.Balloon {
  $triangle-height: 8px;
  $triangle-width: 24px;
  $triangle-highlight-height: 20px;
  $triangle-highlight-width: 40px;
  $triangle-primary-height: 15px;
  $triangle-primary-width: 40px;
  $max-size-small: 320px;
  $extra-horizontal-padding-default: $space-xl;
  $extra-horizontal-padding-l: $space-xl;
  $close-icon-size: $space-xl;

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: fit-content;
    position: relative;
    border-radius: $border-radius-base;
    line-height: $line-height-s;

    & > strong {
      font-weight: $font-weight-bold;
    }

    &:after {
      content: "";
      position: absolute;
    }
  }

  &__close {
    height: $close-icon-size;
    width: $close-icon-size;
    outline: none;
    padding: $space-xs;
  }

  &__children {
    padding: $space-s;

    &--max-width {
      &-default {
        max-width: 100%;
      }

      &-s {
        max-width: $max-size-small;
      }
    }
  }

  &--arrow-position {
    &-bottom {
      padding-bottom: $triangle-height; // to get a hit box including the triangle

      .Balloon__wrapper:after {
        @include scut-triangle(down, $triangle-width $triangle-height, var(--primary-bg-main));

        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(-0.5px);
      }
    }

    &-top {
      padding-top: $triangle-height;

      .Balloon__wrapper:after {
        @include scut-triangle(up, $triangle-width $triangle-height, var(--primary-bg-main));

        bottom: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(0.5px);
      }
    }

    &-right {
      padding-right: $triangle-height;

      .Balloon__wrapper:after {
        @include scut-triangle(right, $triangle-height $triangle-width, var(--primary-bg-main));

        left: 100%;
        top: 50%;
        transform: translateY(-50%) translateX(-0.5px);
      }
    }

    &-left {
      padding-left: $triangle-height;

      .Balloon__wrapper:after {
        @include scut-triangle(left, $triangle-height $triangle-width, var(--primary-bg-main));

        right: 100%;
        top: 50%;
        transform: translateY(-50%) translateX(0.5px);
      }
    }
  }

  &--size {
    &-default {
      .Balloon__children {
        padding: $space-s;
      }

      &.Balloon--extraHorizontalPadding {
        .Balloon__children {
          padding-left: $extra-horizontal-padding-default;
          padding-right: $extra-horizontal-padding-default;
        }
      }

      &.Balloon--closeable {
        .Balloon__children {
          padding-right: 0;
        }

        &.Balloon--extraHorizontalPadding {
          .Balloon__children {
            padding-left: $extra-horizontal-padding-default;
            padding-right: calc($extra-horizontal-padding-default - $close-icon-size);
          }
        }
      }
    }

    &-l {
      .Balloon__children {
        padding: $space-base;
      }

      &.Balloon--extraHorizontalPadding {
        .Balloon__children {
          padding-left: $extra-horizontal-padding-l;
          padding-right: $extra-horizontal-padding-l;
        }
      }

      &.Balloon--closeable {
        .Balloon__children {
          padding-right: 0;
        }

        &.Balloon--extraHorizontalPadding {
          .Balloon__children {
            padding-left: $extra-horizontal-padding-l;
            padding-right: calc($extra-horizontal-padding-l - $close-icon-size);
          }
        }
      }
    }
  }

  &--variant-primary {
    .Balloon__wrapper {
      background: var(--primary-bg-main);
      color: var(--primary-fg-main);
      box-shadow: $balloon-box-shadow;
    }

    .Balloon__close {
      color: var(--primary-fg-support);
    }

    &.Balloon--arrow-position {
      &-bottom {
        padding-bottom: $triangle-height; // to get a hit box including the triangle

        .Balloon__wrapper:after {
          @include scut-triangle(down, $triangle-width $triangle-height, var(--primary-bg-main));

          top: 100%;
          left: 50%;
          transform: translateX(-50%) translateY(-0.5px);
        }
      }

      &-top {
        padding-top: $triangle-height;

        .Balloon__wrapper:after {
          @include scut-triangle(up, $triangle-width $triangle-height, var(--primary-bg-main));

          bottom: 100%;
          left: 50%;
          transform: translateX(-50%) translateY(0.5px);
        }
      }

      &-right {
        padding-right: $triangle-height;

        .Balloon__wrapper:after {
          @include scut-triangle(right, $triangle-height $triangle-width, var(--primary-bg-main));

          left: 100%;
          top: 50%;
          transform: translateY(-50%) translateX(-0.5px);
        }
      }

      &-left {
        padding-left: $triangle-height;

        .Balloon__wrapper:after {
          @include scut-triangle(left, $triangle-height $triangle-width, var(--primary-bg-main));

          right: 100%;
          top: 50%;
          transform: translateY(-50%) translateX(0.5px);
        }
      }
    }
  }

  &--variant-accent {
    .Balloon__wrapper {
      background: var(--accent-bg-main);
      color: var(--accent-fg-main);
      box-shadow: none;
    }

    .Balloon__close > .Icon {
      color: var(--accent-fg-main);
    }

    &.Balloon--arrow-position {
      &-top {
        padding-top: $triangle-primary-height;

        .Balloon__wrapper:after {
          @include scut-triangle(up, $triangle-primary-width $triangle-primary-height, var(--accent-bg-main));

          transform: translateX(-50%) translateY(0.5px);
        }
      }

      &-right {
        .Balloon__wrapper:after {
          @include scut-triangle(right, $triangle-primary-height $triangle-primary-width, var(--accent-bg-main));

          padding-right: $triangle-primary-height;
          transform: translateY(-50%) translateX(-0.5px);
        }
      }

      &-bottom {
        .Balloon__wrapper:after {
          @include scut-triangle(down, $triangle-primary-width $triangle-primary-height, var(--accent-bg-main));

          padding-bottom: $triangle-primary-height;
          transform: translateX(-50%) translateY(-0.5px);
        }
      }

      &-left {
        .Balloon__wrapper:after {
          @include scut-triangle(left, $triangle-primary-height $triangle-primary-width, var(--accent-bg-main));

          padding-left: $triangle-primary-height;
          transform: translateY(-50%) translateX(0.5px);
        }
      }
    }
  }
}
