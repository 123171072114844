.ToolLayout {
  $stacking: loading, webview, crashed, navBar, overlayButton;
  // NavbarTop needs a own height value, because the exitbutton must fit
  $navbartop-height: $browser-navbartop-height;
  $navbar-height: $browser-navbar-height;

  position: relative;
  height: 100%;

  &__exitButton {
    position: fixed;
    left: 0;
    top: 0;
    z-index: z($stacking, overlayButton);
    transition: opacity $transition-fast ease-out;
    opacity: 0;
    pointer-events: none;
  }

  &__progress {
    position: fixed;
    left: 0;
    right: 0;
    bottom: $navbar-height;
    z-index: z($stacking, loading);
    pointer-events: none;
  }

  &__navBar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: $navbar-height;
    z-index: z($stacking, navBar);
  }

  &__navBarTop {
    background-color: var(--primary-contrast-bg-main);
    color: var(--primary-contrast-fg-main);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: $field-height;
    z-index: z($stacking, navBar);
  }

  &--showExitButton .ToolLayout__exitButton {
    opacity: 1;
    pointer-events: all;
  }

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    z-index: z($stacking, loading);
    animation: loading-webview $transition-slow linear infinite alternate;

    @keyframes loading-webview {
      0% {
        background-color: var(--primary-bg-main);
      }
      100% {
        background-color: var(--primary-bg-minor);
      }
    }
  }

  &__webview {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    z-index: z($stacking, webview);
  }

  &__crashed {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    z-index: z($stacking, crashed);
  }

  &--navBar {
    .ToolLayout__webview {
      padding-bottom: $navbar-height;
    }

    .ToolLayout__crashed {
      padding-bottom: $navbar-height;
    }
  }

  &--navBarTop {
    .ToolLayout__webview {
      padding-top: $navbartop-height;
    }

    .ToolLayout__crashed {
      padding-top: $navbartop-height;
    }
  }
}
