.DistributedContent {
  // Design decision - depends on the illustration
  $category-illustration-height: 300px;
  $category-illustration-offset: 160px;

  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__children {
    width: 100%;
    flex-grow: 1;
  }

  &__footer {
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: $space-base $space-base $space-l;
    margin-top: $space-l;
    background: var(--primary-bg-main);
    box-shadow: 0 0 20px var(--color-shadow-light);

    @include media(xl) {
      position: relative;
      padding: 0;
      margin: 0;
      width: 100%;
      background: var(--color-util-transparent);
      box-shadow: none;
    }
  }

  &__image {
    width: 100%;
    margin-top: -$category-illustration-offset;
    margin-left: -4 * $space-xxxl;
    pointer-events: none;
    height: $category-illustration-height;
  }
}
