.IconLink {
  $space-between-icon-and-text: 0.5em;

  display: inline-flex;
  align-items: center;
  outline: none;
  line-height: $line-height-s;
  text-decoration: none;

  &--font-weight {
    &-regular {
      font-weight: $font-weight-regular;
    }

    &-medium {
      font-weight: $font-weight-medium;
    }
  }

  &--size {
    &-base {
      font-size: $font-size-base;
    }

    &-l {
      font-size: $font-size-xl;
    }

    &-s {
      font-size: $font-size-xs;
    }
  }

  &--variant {
    &-primary {
      &.IconLink--kind {
        &-default {
          color: var(--primary-fg-main);
        }

        &-support {
          color: var(--primary-fg-support);
        }
      }

      &:active,
      &--touched {
        color: var(--primary-fg-active);
      }
    }

    &-standout {
      color: var(--standout-fg-main);

      &.IconLink--kind-support {
        color: var(--standout-fg-support);
      }

      &:active,
      &--touched {
        color: var(--standout-fg-active);
      }
    }
  }

  &--icon-position {
    &-left {
      flex-direction: row;

      & > .Icon {
        margin-right: $space-between-icon-and-text;
      }
    }

    &-right {
      flex-direction: row-reverse;

      & > .Icon {
        margin-right: initial;
        margin-left: $space-between-icon-and-text;
      }
    }
  }
}
