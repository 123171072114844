.TileIconButton {
  $size: 49px;

  height: $size;
  width: $size;
  border-bottom: $border-width-base var(--primary-fg-minor) solid;
  border-left: $border-width-base var(--primary-fg-minor) solid;
  color: var(--primary-fg-main);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}
