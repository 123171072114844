.BookmarkLayout {
  $bookmark-layout-column-width: 315px;

  display: flex;
  padding: $space-l $space-base;

  &__aside {
    width: $bookmark-layout-column-width;
    margin-left: $space-xxxl;

    > * + * {
      margin-top: $space-base;
    }
  }
}
