.CheckboxField {
  display: inline-flex;
  align-items: center;
  user-select: none;

  &__input {
    width: 0;
    opacity: 0;
  }

  &__icon {
    @include checkbox-base;
  }

  &__label {
    margin-left: $space-s;
  }

  &:focus-within {
    .CheckboxField__icon {
      border-color: var(--primary-fg-accent);
    }
  }

  &[aria-disabled="true"] {
    .CheckboxField__icon {
      background: var(--primary-disabled-bg-support);
      border-color: var(--primary-disabled-fg-main);
    }

    &:focus-within {
      .CheckboxField__icon {
        background: var(--primary-disabled-bg-main);
        border-color: var(--primary-disabled-fg-main);
      }
    }
  }

  &[aria-checked="true"] {
    .CheckboxField__label {
      font-weight: $font-weight-active;
      letter-spacing: $letter-spacing-compensate-font-weight;
    }

    .CheckboxField__icon {
      background: var(--primary-bg-main);
      color: var(--primary-fg-accent);
    }

    &:focus-within {
      .CheckboxField__icon {
        background: var(--primary-accent-bg-main);
        color: var(--primary-accent-fg-main);
      }
    }

    &[aria-disabled="true"] {
      .CheckboxField__icon {
        background: var(--primary-disabled-bg-support);
        border-color: var(--primary-disabled-fg-main);
      }

      .CheckboxField__icon,
      .CheckboxField__label {
        color: var(--primary-disabled-fg-main);
      }

      &:focus-within {
        .CheckboxField__icon {
          background: var(--primary-disabled-bg-main);
          color: var(--primary-disabled-fg-main);
          border-color: var(--primary-disabled-fg-main);
        }
      }
    }
  }

  &--withLabel {
    // No additional height without a label
    height: $field-height;
  }

  &--multiline {
    // For multiline label
    min-height: $field-height;
    height: auto;
  }

  &--asContextButton {
    padding: 0 $space-s;
    border-radius: $border-radius-base;
    width: 235px; // Design decision
    background: var(--primary-bg-main);
    color: var(--primary-fg-main);

    .CheckboxField__label {
      @include scut-truncate;
    }
  }
}
