.Headline {
  @include typography-headline-base;

  margin: 0;

  &__decoration {
    display: none;
  }

  &--kind {
    &-base {
      @include typography-headline-base;
    }

    &-xs {
      @include typography-headline-xs;
    }

    &-s {
      @include typography-headline-s;
    }

    &-m {
      @include typography-headline-m;
    }

    &-l {
      @include typography-headline-l;
    }

    &-hero {
      @include typography-headline-hero;
    }

    &-minor {
      @include typography-headline-minor;
    }

    &-sub {
      @include typography-subheadline;
    }
  }

  &--variant {
    &-inherit {
      color: inherit;

      &.Headline--kind-minor,
      &.Headline--kind-sub {
        color: var(--primary-fg-support);
      }
    }

    &-primary {
      color: var(--primary-fg-main);

      &.Headline--kind-minor,
      &.Headline--kind-sub {
        color: var(--primary-fg-support);
      }
    }

    &-standout {
      color: var(--standout-fg-main);

      &.Headline--kind-minor,
      &.Headline--kind-sub {
        color: var(--standout-fg-support);
      }
    }
  }

  &--thin {
    font-weight: $font-weight-thin; // every headline also can be thin
  }

  &--fitText {
    // In case of fittext there is an inner div which needs to be truncated
    // if min font size is reached.
    & > div > div {
      @include scut-truncate;
    }
  }
}
