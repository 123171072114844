.PdfPage {
  @include scut-ratio-box(calc(1 / 1.4142)); // DIN A4 format ratio

  & {
    position: relative;
    background: var(--primary-bg-main);
  }

  &__image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
  }

  &--loaded &__image {
    opacity: 1;
    transition: opacity $transition-fast linear;
  }
}
