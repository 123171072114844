.SpecsListLayout {
  display: grid;
  grid-column-gap: $space-base;
  grid-row-gap: $space-xl;
  grid-template-columns: repeat(4, 1fr);

  @include media(l) {
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: $space-base;
  }

  &--narrow {
    grid-template-columns: repeat(3, 1fr);
  }
}
