@include scut-reset-button;
@include scut-reset-border-box;
@include scut-reset-pointer;
@include scut-reset-form;

html {
  @include scrollbar {
    min-height: 100%;
  }
}

body {
  font-family: $font-family-base;
  font-weight: $font-weight-regular;
  font-size: $font-size-base;

  background: var(--primary-bg-main);
  color: var(--primary-fg-main);

  line-height: $line-height-base;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: var(--color-util-transparent);

  * {
    -webkit-tap-highlight-color: var(--color-util-transparent);
    font-variant-ligatures: none; // Remove ligature of DIN 2014
    user-select: none;
  }

  input {
    // This needs to be here to avoid issues with Safari, when "user-select: none;" is enabled
    // Source: https://stackoverflow.com/questions/32851413/input-field-ios-safari-bug-cant-type-in-any-text
    user-select: text;
  }
}
