.ProductComparisonRow {
  display: grid;
  grid-template-columns: $product-comparison-columns;
  grid-column-gap: $space-xxs;
  padding-right: $space-sl;
  padding-left: $space-sl;

  &__item {
    background: var(--primary-bg-main);
  }

  &__label,
  &__item {
    font-size: $font-size-xs;
    padding: $space-xs $space-sl;
  }

  &__label {
    font-weight: $font-weight-bold;
    text-align: right;
  }

  &:nth-child(odd) > .ProductComparisonRow__item {
    background: var(--primary-bg-support);
  }
}
