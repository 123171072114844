.TabContent {
  display: flex;
  flex-direction: column;
  padding: 0 $space-xxxl;

  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: $space-xxl;
    margin-bottom: $space-xxxl;

    & > * {
      display: inline-block;
      vertical-align: baseline;
      line-height: 1;
    }
  }

  &__content {
    display: flex;
    flex-grow: 1;
  }

  &--tabLinksAlignment {
    &-left {
      .TabContent__header {
        justify-content: left;
      }
    }

    &-center {
      .TabContent__header {
        justify-content: center;
      }
    }
  }
}
