.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-text-center {
  text-align: center;
}

.u-text-transform-none {
  text-transform: none;
}

.u-text-uppercase {
  text-transform: uppercase;
}

.u-text-medium {
  font-weight: $font-weight-medium;
}

.u-text-bold {
  font-weight: $font-weight-bold;
}

.u-color-primary-fg-main {
  color: var(--primary-fg-main);
}

.u-color-primary-fg-minor {
  color: var(--primary-fg-minor);
}

.u-color-primary-contrast-fg-main {
  color: var(--primary-contrast-fg-main);
}

.u-color-primary-fg-accent {
  color: var(--primary-fg-accent);
}
