@use "sass:math";

.Placeholder {
  $checkerboard: var(--primary-contrast-bg-main-alpha-light);

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;

  background: var(--primary-bg-main);
  background-image:
    linear-gradient(45deg, $checkerboard 25%, transparent 25%),
    linear-gradient(-45deg, $checkerboard 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, $checkerboard 75%),
    linear-gradient(-45deg, transparent 75%, $checkerboard 75%);
  background-size: $space-base $space-base;
  background-position:
    0 0,
    0 math.div($space-base, 2),
    math.div($space-base, 2) math.div($space-base, -2),
    -1 * math.div($space-base, 2) 0px;
  background-origin: content-box;
  border: $border-width-l solid var(--primary-fg-minor);

  &__label {
    @include scut-truncate;
    font-size: $font-size-xs;
    color: var(--primary-fg-main);
    padding: $space-base;
    max-width: 100%;
    overflow: hidden;
  }

  &--inline {
    display: inline-flex;
  }
}
