.BookmarkSlider {
  $slider-padding-x: 170px;
  $slider-arrow-size: 50px;

  position: relative;
  padding: 0 $slider-padding-x;
  text-align: center;

  &__prev,
  &__next {
    position: absolute;
    top: (calc($bookmark-height / 2)) - (calc($icon-size-l / 2));
    line-height: 0;
    outline: 0;
  }

  &__prev {
    left: -1 * $slider-padding-x;
  }

  &__next {
    right: -1 * $slider-padding-x;
  }
}
