.ConnectionStatusCheckItem {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: $icon-size-l;

  & + & {
    padding-top: $space-l;

    &:before {
      content: " ";
      display: block;
      position: absolute;
      top: $border-width-l;
      left: calc($icon-size-l / 2 - $border-width-l / 2); // Centering below &__icon
      height: $space-l - $space-xxs;
      width: $border-width-l;
      background-image: linear-gradient(currentColor 50%, var(--color-util-transparent) 0%);
      background-position: right;
      background-size: 3px 6px;
      background-repeat: repeat-y;
    }
  }

  &__icon {
    margin-right: $space-xxl;
  }
}
