.FormLayoutColumn {
  $button-gap: $labeled-field-label-height + scut-rem($space-base);

  & > * + * {
    margin-top: $space-base;
  }

  // There is a special case in the design in the button spacing
  & > .Button {
    margin-top: $labeled-field-label-height + scut-rem($space-base);
  }

  // There is a special case in the design with the button spacing
  // in an error case
  & > .LabeledField--error + .Button {
    margin-top: $button-gap - $labeled-field-error-height;
  }
}
