.CustomerWorldLayout {
  $stacking: main, exit, navigation, progress, navigationToggle, overlay;

  $progress-height: 10px;
  $pause-size: 180px;
  $pause-icon-size: 60px;

  $pi: 3.14159265359;

  $resume-progress-size: 80px;
  $resume-progress-stroke: 2px;
  $resume-radius: calc($resume-progress-size / 2) - $resume-progress-stroke;
  $resume-circumference: 2 * $pi * $resume-radius;

  $resume-box-width: 750px;

  position: relative;
  width: 100;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background: var(--primary-bg-main);

  &__exit {
    position: absolute;
    top: 0;
    left: 0;
    z-index: z($stacking, exit);
  }

  &__main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z($stacking, main);
    background: var(--color-util-black-alpha-50);
  }

  &__slide,
  &__slideCurrent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__children {
    &,
    & > * {
      height: 100%;
    }
  }

  &__slide {
    pointer-events: none;
    opacity: 0;
  }

  &__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $progress-height;
    transform: translateY(100%);
    transition: transform $transition-base ease-in-out;
    z-index: z($stacking, progress);
    background: var(--color-util-black-alpha-50);
  }

  &__progressBar {
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    transform-origin: left center;
    background: var(--primary-accent-bg-main);
  }

  &--progress .CustomerWorldLayout__progress {
    transform: none;
  }

  &__paused {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: $pause-size;
    width: $pause-size;
    top: 50%;
    left: 50%;
    margin-left: calc(-1 * $pause-size / 2);
    margin-top: calc(-1 * $pause-size / 2);
    border-radius: 50%;
    z-index: z($stacking, overlay);
    pointer-events: none;
    opacity: 0;
    transform: scale(0.5);
    transition:
      transform $transition-base ease-out,
      opacity $transition-base ease-out;

    & > .Icon {
      font-size: 0.7 * $pause-size;
    }
  }

  &__navigation {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) translateY(100%);
    transition: transform $transition-base;
    z-index: z($stacking, navigation);
  }

  &__navigationToggle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $field-height;
    width: $field-height;
    margin: 0 auto;
    border-radius: 50%;
    outline: 0;
    transform: translateY(calc($field-height / 2) - calc($progress-height / 2));
    transition:
      background $transition-base,
      box-shadow $transition-base,
      transform $transition-base;
    z-index: z($stacking, navigationToggle);
    background: var(--primary-accent-bg-main);
    color: var(--primary-accent-fg-main);
    box-shadow: 0 0 $space-xxl var(--color-shadow-dense);

    & > .Icon {
      transform: translateY(calc(-1 * $field-height / 6)) rotate(0deg);
      transition: transform $transition-base;
    }
  }

  &__resume {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z($stacking, navigationToggle);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: z($stacking, overlay);
  }

  &__resumeBox {
    display: flex;
    align-items: center;
    width: $resume-box-width;
    padding: $space-xxl;
    opacity: 0;
    transform: scale(0.8);
    transition:
      transform $transition-base ease-out,
      opacity $transition-base ease-out;
    font-size: $font-size-xl;
    border-radius: $border-radius-l;

    background: var(--primary-contrast-bg-main-alpha-heavy);
    color: var(--primary-contrast-fg-main);
  }

  &__resumeProgress {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $resume-progress-size;
    height: $resume-progress-size;
    margin-right: $space-xl;
    flex-shrink: 0;

    & > .Icon {
      font-size: 0.4 * $resume-progress-size;
    }
  }

  &__resumeProgressRing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    stroke: var(--primary-fg-accent);
  }

  &__resumeProgressCircle {
    fill: none;
    stroke-dasharray: $resume-circumference;
    stroke-dashoffset: $resume-circumference;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  &__resumeTitle {
    display: block;
    font-weight: $font-weight-bold;
    font-size: $font-size-xxxl;
  }

  &--navigationOpen &__navigation {
    transform: translateX(-50%) translateY(0);
    color: var(--primary-contrast-fg-main);
  }

  &--navigationOpen &__navigationToggle {
    background: var(--color-util-transparent);
    transform: translateY(0);
    box-shadow: none;

    & > .Icon {
      transform: translateY(0) rotate(180deg);
    }
  }

  &--pause &__paused {
    animation: CustomerWorldLayout-paused-appear 3s ease-out;
  }

  &--willResume &__resume {
    pointer-events: all;
  }

  &--willResume &__resumeBox {
    opacity: 1;
    transform: scale(1);
  }

  &--willResume &__resumeProgressCircle {
    animation-name: CustomerWorldLayout-resume-progress;
    animation-timing-function: linear;
  }

  @keyframes CustomerWorldLayout-paused-appear {
    20%,
    80% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  @keyframes CustomerWorldLayout-resume-progress {
    from {
      stroke-dashoffset: $resume-circumference;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
}
