.ItemList {
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 0 20px var(--color-shadow-light);

  & > * + * {
    border-top: 1px solid var(--primary-fg-divider);
  }
}
