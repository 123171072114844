.IconButton {
  $size: 49px;
  $dot-inner-circle: 8px;

  position: relative;
  height: $size;
  width: $size;
  font-size: $font-size-base;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &__dot {
    position: absolute;
    top: $dot-inner-circle;
    right: $dot-inner-circle;
    line-height: 0;
  }

  &__label {
    margin-left: $space-xs;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
  }

  &--kind {
    &-inherit {
      color: inherit;

      &:active,
      &.IconButton--touched {
        color: var(--primary-fg-active);
      }
    }

    &-support {
      color: var(--primary-fg-support);

      &:active,
      &.IconButton--touched {
        color: var(--primary-fg-active);
      }
    }
  }

  &--label {
    width: auto;
    padding: 0 $space-s;
  }

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }
}
