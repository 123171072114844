.ProductComparisonContent {
  position: relative;
  width: 100%;
  color: var(--primary-fg-main);

  // This hack is necessary because MainContentLayout does not support full height non-scrollable content.
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }

  &__header {
    flex-shrink: 0;
  }

  &__maxItemsMessage {
    width: 100%;
    text-align: center;
    color: var(--primary-fg-support);
    margin-bottom: $space-xs;
  }

  &__tableHeader {
    width: 100%;
    display: grid;
    grid-template-columns: $product-comparison-columns;
    grid-column-gap: $space-xxs;
    padding-right: $space-sl + $scrollbar-size;
    padding-left: $space-sl;
  }

  &__buttonsColumn {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: $space-base;
    // Minus tableHeader grid-column-gap
    padding-right: $space-sl - $space-xxs;
  }

  &__tableContent {
    flex-grow: 1;
    overflow-y: scroll;
    padding-bottom: $space-xxxl;
  }

  &__panel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    // Disable pointer-events to enable scrolling of the content behind
    pointer-events: none;

    & > * {
      // Enable pointer-events again on the panel
      pointer-events: initial;
    }
  }
}
