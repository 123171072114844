.SettingsContent {
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: $space-xxxl;

    & > * + * {
      margin-top: $space-s;
    }
  }

  &__content {
    flex-grow: 1;

    & > * + * {
      margin-top: $space-xxl;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: $space-l;
  }
}
