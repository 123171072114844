.CurrentPageBreadcrumb {
  @include typography-headline-base;

  display: inline-block;
  padding-bottom: $space-xs;
  padding-top: $space-l;
  padding-right: $page-layout-padding;
  border-bottom: $border-width-l solid var(--primary-fg-minor);
  color: var(--primary-fg-main);
}
