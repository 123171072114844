.Text {
  & > * + * {
    margin-top: $space-base;
  }

  & > * + .Headline {
    margin-top: 2em;
  }

  & > .Headline,
  & > .TextHeader {
    margin-bottom: 1em;
  }
}
