@use "sass:math";

.PdfViewer {
  $info-height: 50px;
  $page-box-shadow: 4px 4px 15px 0px var(--color-shadow);

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: var(--primary-bg-main);

  &__info {
    position: absolute;
    bottom: $space-base;
    left: 50%;
    transform: translateX(-50%);
  }

  &__pages {
    padding: $space-base;
    height: 100%;
    flex-shrink: 1;
    overflow-x: auto;
    padding-bottom: 2 * $space-base + $info-height;

    & > * + * {
      margin-top: $space-base;
    }
  }

  &__loading {
    @include scut-ratio-box(calc(1 / 1.4142)); // DIN A4 format ratio

    & {
      display: flex;
      justify-content: center;
      box-shadow: $page-box-shadow;
    }

    & > .LoadingIndicator {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__page {
    box-shadow: $page-box-shadow;
    line-height: 0;
  }
}
