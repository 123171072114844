.BookmarkOverviewLayout {
  $x-padding: 160px;
  $column-spacing: 120px;

  position: relative;
  padding: 0 $x-padding;

  &__container {
    display: flex;
    margin-top: $space-xxxl;
  }

  &__unsavedItem,
  &__savedItems,
  &__infoImage {
    margin-top: $space-base;
  }

  &__unsavedItem {
    display: flex;
    flex-direction: column;
    width: $bookmark-width;

    > * + * {
      margin: $space-l 0;
    }
  }

  &__saved {
    margin-left: $column-spacing;
  }

  &__savedItems {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-row-gap: $space-xl;
    grid-column-gap: $space-xl;
  }
}
