.ToastLayout {
  $stacking: content, toasts;

  position: relative;
  height: 100%;

  &__content {
    position: relative;
    z-index: z($stacking, content);
    height: 100%;
    width: 100%;
  }

  &__toasts {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: z($stacking, toasts);
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    margin: 0;
    padding: 0;
    list-style: none;
    transition: transform $transition-base ease-in-out;
  }

  &__toast {
    position: absolute;
    top: 0;

    &[data-index="0"] > * {
      transform: translateY(1 * $space-xs);
    }
    &[data-index="1"] > * {
      transform: translateY(2 * $space-xs);
    }
    &[data-index="2"] > * {
      transform: translateY(3 * $space-xs);
    }

    &[data-rev-index="0"] {
      pointer-events: initial;

      & > .ToastLayout__toastInner > * {
        opacity: 1;
      }
    }
    &[data-rev-index="1"] > .ToastLayout__toastInner > * {
      opacity: 0.7;
    }
    &[data-rev-index="2"] > .ToastLayout__toastInner > * {
      opacity: 0.6;
    }
  }

  &__toastInner {
    display: inline-block;
    background: var(--primary-bg-minor);
    transition: transform $transition-fast ease;

    & > * {
      transition: opacity $transition-fast linear;
    }
  }

  &--align {
    &-center {
      .ToastLayout__toasts {
        transform: translateX(0);
      }
    }

    &-sidebar-left {
      .ToastLayout__toasts {
        transform: translateX(0.5 * $sidebar-width);
      }
    }

    &-sidebar-right {
      .ToastLayout__toasts {
        transform: translateX(-0.5 * $sidebar-width);
      }
    }
  }
}
