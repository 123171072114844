.ToggleField {
  $knob-size: 44px;
  $knob-size-s: 34px;
  $knob-size-xs: 24px;

  $height: $field-height;
  $height-s: $field-height-s;
  $height-xs: $field-height-xs;

  $width: 120px;
  $width-s: 90px;
  $width-xs: 60px;

  $knob-margin: calc(($height - $knob-size) / 2);
  $knob-margin-s: calc(($height-s - $knob-size-s) / 2);
  $knob-margin-xs: calc(($height-xs - $knob-size-xs) / 2);

  --bg-checked: var(--primary-accent-bg-main);
  --fg-checked: var(--primary-accent-fg-main);

  position: relative;
  overflow: hidden;
  cursor: pointer;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    display: inline;
    pointer-events: none;
    transition: transform $transition-fast ease-in-out;
    font-weight: $font-weight-bold;
    color: var(--primary-contrast-fg-main);
  }

  &__knob {
    @include scut-circle($knob-size, var(--primary-bg-main));
    margin: $knob-margin;
  }

  &__labelYes,
  &__labelNo {
    position: absolute;
    top: 0;
    margin: 0 $knob-margin;
    width: 100%;
    text-align: center;
  }

  &__labelYes {
    right: 100%;
  }

  &__labelNo {
    left: 100%;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary-contrast-bg-main-alpha-light);
    border-radius: calc($height / 2);
    pointer-events: none;
  }

  &__input {
    opacity: 0;

    &:checked ~ .ToggleField__background {
      background: var(--bg-checked);
    }

    &:checked ~ .ToggleField__inner {
      color: var(--fg-checked);
    }
  }

  &--alwaysOn {
    .ToggleField__background {
      background: var(--bg-checked);
      color: var(--fg-checked);
    }
  }

  &--size {
    &-base {
      height: $height;
      width: $width;

      .ToggleField__input {
        height: $height;
        width: $width;

        &:checked ~ .ToggleField__inner {
          transform: translateX($width - $knob-size - 2 * $knob-margin);
        }
      }

      .ToggleField__inner {
        font-size: $font-size-l;
      }

      .ToggleField__knob {
        line-height: $height;
      }
    }

    &-s {
      height: $height-s;
      width: $width-s;

      .ToggleField__input {
        height: $height-s;
        width: $width-s;

        &:checked ~ .ToggleField__inner {
          transform: translateX($width-s - $knob-size-s - 2 * $knob-margin-s);
        }
      }

      .ToggleField__inner {
        font-size: $font-size-base;
      }

      .ToggleField__knob {
        line-height: $height-s;
        @include scut-circle($knob-size-s, var(--primary-bg-main));
      }
    }

    &-xs {
      height: $height-xs;
      width: $width-xs;

      .ToggleField__input {
        height: $height-xs;
        width: $width-xs;

        &:checked ~ .ToggleField__inner {
          transform: translateX($width-xs - $knob-size-xs - 2 * $knob-margin-xs);
        }
      }

      .ToggleField__inner {
        font-size: $font-size-xs;
      }

      .ToggleField__knob {
        line-height: $height-xs;
        @include scut-circle($knob-size-xs, var(--primary-bg-main));
      }
    }
  }

  &--disabled {
    .ToggleField__knob {
      background: var(--primary-disabled-bg-main);
    }

    .ToggleField__background,
    .ToggleField__input:checked ~ .ToggleField__background {
      background: var(--primary-disabled-bg-support);
    }

    .ToggleField__labelYes,
    .ToggleField__labelNo {
      color: var(--primary-disabled-fg-main);
    }
  }
}
