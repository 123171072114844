.ConnectionSettingsItem {
  padding: $space-l 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $space-l;

    &-left {
      justify-self: start;
    }

    &-right {
      justify-self: end;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: $space-l;
    }
  }

  &__status *,
  &__buttons * {
    flex-shrink: 0;
  }

  &__children {
    max-width: $width-base;
  }
}
