.InputField {
  $input-field-height: $field-height - 2 * $border-width-base; // Compensate border size
  $input-field-height-s: $field-height-s - 2 * $border-width-base;
  $button-padding: 0.6em;
  $button-space-between-icon-and-text: 0.5em;

  position: relative;
  display: flex;
  border: $border-width-base solid var(--primary-fg-minor);
  border-radius: $border-radius-base;
  background: var(--primary-bg-main);
  font-size: $font-size-base;

  &:focus-within {
    border-color: var(--primary-fg-accent);
    // Use additional box-shadow to avoid size-changes
    box-shadow: $field-box-shadow;
  }

  &__input {
    outline: none;
    border: none;
    background: var(--color-util-transparent);
    padding: 0 $space-s;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;

    &::-webkit-input-placeholder {
      color: var(--primary-fg-minor);
    }

    &:focus {
      font-weight: $font-weight-bold;
      letter-spacing: $letter-spacing-compensate-font-weight;
    }

    &[type="password"]:focus {
      font-weight: initial;
      letter-spacing: initial;
    }
  }

  &__clearButton,
  &__errorButton,
  &__togglePasswordButton {
    // Button defaults
    display: none; // Disable all by default
    align-items: center;
    outline: none;
    color: var(--primary-fg-main);
    font-size: $icon-size-base;
    padding: 0 $button-padding;
    touch-action: manipulation;
  }

  &__togglePasswordButton {
    position: relative;
    color: var(--primary-fg-minor);
    font-weight: $font-weight-medium;
    font-size: $font-size-base;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: $space-xs;
      bottom: $space-xs;
      right: 0;
      border-right: $border-width-base solid var(--primary-fg-minor);
    }
  }

  &__clearButton {
    color: var(--primary-fg-minor);
  }

  &__errorButton {
    color: var(--primary-fg-negative);
  }

  &__buttonLabel {
    font-size: $font-size-base;
    padding-right: $button-space-between-icon-and-text;
    pointer-events: none;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: $field-height;
    line-height: $field-height - 2 * $border-width-l;
    text-align: center;
    color: var(--primary-fg-minor);
  }

  &__suffix {
    line-height: $field-height - 2 * $space-xs;
    margin: $space-xs;
    margin-left: 0;
    padding-left: $space-xs;
    border-left: 1px solid var(--primary-fg-minor);
  }

  &--size {
    &-default {
      height: $field-height;

      .InputField__input {
        padding: 0 $space-s;
        height: $input-field-height;
      }

      .InputField__errorButton {
        height: $input-field-height;
      }
    }

    &-s {
      padding: 0 $space-xs;
      height: $input-field-height-s;
      vertical-align: middle;
      font-size: $font-size-s;

      .InputField__input {
        padding: 0 $space-xxs;
        vertical-align: middle;
        height: $input-field-height-s;
      }

      .InputField__errorButton {
        height: $input-field-height-s;
      }
    }
  }

  &--icon {
    .InputField__input {
      padding-left: $space-xxl;
    }
  }

  &--withButton {
    padding-right: 0;
  }

  &--hasValue {
    .InputField__clearButton {
      display: inline-flex; // Same as the <Button />-Component
    }
  }

  &--error {
    &.InputField--withoutErrorButton {
      border-color: var(--primary-fg-negative);
      box-shadow: $field-box-shadow-error;
    }

    .InputField__errorButton {
      display: inline-flex;
    }

    .InputField__clearButton {
      display: none;
    }

    &:focus-within {
      border-color: var(--primary-fg-negative);
      box-shadow: $field-box-shadow-error;

      .InputField__errorButton {
        display: none;
      }

      &.InputField--hasValue {
        .InputField__clearButton {
          display: inline-flex;
        }
      }
    }
  }

  &--password {
    &.InputField--hasValue {
      .InputField__togglePasswordButton {
        display: inline-flex;
      }
    }
  }

  &--disabled {
    color: var(--primary-disabled-fg-main);
    border: $border-width-base solid var(--primary-disabled-bg-main);
    pointer-events: none;

    .InputField__clearButton {
      display: none;
    }

    .InputField__input {
      color: var(--primary-disabled-fg-main);
    }
  }

  &--presentation {
    background-color: var(--primary-disabled-bg-support);
    border: $border-width-base solid var(--primary-disabled-bg-main);
    pointer-events: none;

    &::-webkit-input-placeholder {
      color: var(--primary-fg-support);
    }

    .InputField__clearButton {
      display: none;
    }

    .InputField__input {
      color: var(--primary-fg-main);
    }
  }

  &--monospace {
    .InputField__input {
      font-family: $font-family-mono;
    }
  }
}
