.SidebarNavigationGroup {
  padding: 0 $space-l;

  &__list {
    list-style: none;
    padding: 0;
    margin: $space-base (-1 * $space-l) 0;
  }

  &__item {
    border-bottom: 1px solid var(--primary-fg-divider);
  }
}
