$dot-inner-circle: 8px;
$dot-outer-circle: 20px;

.Dot {
  position: relative;
  width: $dot-inner-circle;
  height: $dot-inner-circle;
  border-radius: 50%;
  background: var(--accent-bg-main);

  &--pulse {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -$dot-outer-circle;
      left: -$dot-outer-circle;
      right: -$dot-outer-circle;
      bottom: -$dot-outer-circle;
      margin: auto;
      width: $dot-inner-circle;
      height: $dot-inner-circle;
      border-radius: 50%;
      animation: pulsate 2.5s ease infinite;
      background: var(--accent-bg-main-alpha-medium);
    }
  }
}

// backface-visibility and translateZ(0) are required to fix blurry pulsating
// Source: https://stackoverflow.com/a/15472358
@keyframes pulsate {
  0%,
  100% {
    backface-visibility: hidden;
    transform: translateZ(0) scale(1);
  }
  50% {
    backface-visibility: hidden;
    transform: translateZ(0) scale(calc($dot-outer-circle / $dot-inner-circle));
  }
}
