.BrowserNavBar {
  position: relative;
  display: flex;
  padding: 0 $space-base;
  justify-content: flex-end;
  height: $browser-navbar-height;
  background-color: var(--standout-bg-main);
  color: var(--standout-fg-main);

  &--hasKeyboardHandle::before {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    content: "";
    display: block;
    height: 100%;
    width: $keyboard-handle-button-width;
    animation: BrowserNavBarHandleFadeOut steps(1) $transition-slow;
    background: var(--standout-accent-bg-main);
  }
}

@keyframes BrowserNavBarHandleFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
