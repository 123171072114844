.VariantAvailabilityError {
  text-align: left;

  &__icon {
    margin-right: $space-xs;
  }

  &__headline {
    display: flex;
    align-items: center;
    margin-bottom: $space-s;
  }

  &--kind {
    &-full .VariantAvailabilityError__icon {
      color: var(--primary-fg-positive);
    }

    &-partial .VariantAvailabilityError__icon {
      color: var(--primary-fg-warning);
    }

    &-unknown .VariantAvailabilityError__icon {
      color: var(--primary-fg-neutral);
    }

    &-no .VariantAvailabilityError__icon {
      color: var(--primary-fg-neutral);
    }

    &-error .VariantAvailabilityError__icon {
      color: var(--primary-fg-negative);
    }
  }
}
