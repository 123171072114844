.Panel {
  position: relative;
  width: 100%;

  &__close,
  &__content {
    background: var(--standout-bg-main);
    color: var(--standout-fg-main);
  }

  &__close {
    display: inline-block;

    // Removes the thin see-through of background on the real device
    bottom: -1px;
    position: relative;

    & > .IconLink {
      padding: $space-s $space-xl 0;
    }
  }

  &__content {
  }
}
