.SwipeableContent {
  $stacking: content, navigation;

  position: relative;
  width: 100%;
  overflow: hidden;

  &__content {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: z($stacking, content);
    padding-left: $space-xxxxl;
    padding-right: $space-xxxxl;

    @include media(xl) {
      padding-left: $space-xxxl;
      padding-right: $space-xxxl;
    }
  }

  &__children {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  &__prev,
  &__next {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: z($stacking, navigation);
  }

  &__next {
    right: 0;
  }

  &__prev {
    left: 0;
  }
}
