.WarningOverlayLayout {
  $icon-size: 250px;

  position: absolute;
  top: 0;

  min-height: 100vh; // browser fallback
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;

  z-index: z($stacking-global, warning-overlay);
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right top, var(--primary-bg-gradient-minor-to-support));

  &__logo {
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    text-align: center;
    padding: 0 $space-xxxl;
  }

  &__headline {
    margin-bottom: $space-xxl;
  }

  &__icon > .Icon {
    font-size: $icon-size;
  }

  &__description {
    margin: $space-xxl 0;
  }
}
