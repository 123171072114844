.Logo {
  display: block;

  &--fill {
    width: 100%;
    height: auto;
  }

  &--clickable {
    cursor: pointer;
  }
}
