.PageWithSidebarLayout {
  display: flex;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);

  &__main {
    flex-grow: 1;
    overflow: hidden;
  }

  &__aside {
    width: $sidebar-width;
    flex-shrink: 0;
  }

  &--withoutAside {
    // If there is no aside compensate the sidebar with the padding
    padding-right: $sidebar-width;
  }
}
