.Note {
  display: flex;
  background: var(--tertiary-bg-main);
  color: var(--tertiary-fg-main);

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__footer {
    display: flex;
  }

  &--kind {
    &-default {
      .Note__icon {
        color: var(--tertiary-fg-main);
      }
    }

    &-warning {
      .Note__icon {
        color: var(--tertiary-fg-warning);
      }
    }

    &-negative {
      .Note__icon {
        color: var(--tertiary-fg-negative);
      }
    }
  }

  &--spacing {
    &-s {
      padding: $space-s;
      gap: $space-s;

      .Note__content {
        gap: $space-s;
      }
    }

    &-base {
      padding: $space-base;
      gap: $space-base;

      .Note__content {
        gap: $space-base;
      }
    }

    &-l {
      padding: $space-l;
      gap: $space-l;

      .Note__content {
        gap: $space-l;
      }
    }
  }

  &--verticalAlignment {
    &-start {
      align-items: flex-start;

      .Note__icon {
        // Visually offset the icon downwards a little bit, to vertically align it with the text
        padding-top: 0.25em;
      }
    }

    &-center {
      align-items: center;
    }
  }

  &--rounding {
    &-base {
      border-radius: $border-radius-base;
    }

    &-l {
      border-radius: $border-radius-l;
    }
  }

  &--withShadow {
    box-shadow: 0 10px 30px var(--color-shadow-medium);
  }
}
