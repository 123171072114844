.KeyboardPanel {
  $stacking: disabled, current, close;

  position: relative;
  background-color: var(--standout-bg-main);
  padding: $space-l;
  height: $keyboard-panel-height;

  &__close {
    position: absolute;
    top: $space-l;
    left: $space-l;
    z-index: z($stacking, close);
  }

  &__pin,
  &__letters,
  &__numpad {
    left: 0;
    right: 0;
    display: flex;
    position: absolute;
    justify-content: center;
    opacity: 0;
    transform: translateY(10px);
    transition-property: transform, opacity;
    transition-duration: $keyboard-delay-timeout;
    transition-timing-function: ease-out;
    z-index: z($stacking, disabled);
    pointer-events: none;
  }

  &--kind {
    &-pin {
      .KeyboardPanel__pin {
        transform: translateY(0);
        pointer-events: initial;
        opacity: 1;
        z-index: z($stacking, current);
      }
    }

    &-letters {
      .KeyboardPanel__letters {
        transform: translateY(0);
        pointer-events: initial;
        opacity: 1;
        z-index: z($stacking, current);
      }
    }

    &-numpad {
      .KeyboardPanel__numpad {
        transform: translateY(0);
        pointer-events: initial;
        opacity: 1;
        z-index: z($stacking, current);
      }
    }
  }
}
