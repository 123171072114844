.ContextMenu {
  display: inline-block;
  border: $border-width-base solid var(--primary-fg-minor);
  border-radius: $border-radius-base;
  padding: 0;
  margin: $space-xxs 0;
  list-style: none;
  overflow: hidden;
  max-width: 30em; // Design decision

  &__item {
    &:first-item {
      border-top-left-radius: $border-radius-base;
      border-top-right-radius: $border-radius-base;
    }

    &:last-item {
      border-bottom-left-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;
    }

    & + & {
      border-top: $border-width-base solid var(--primary-fg-minor);
    }
  }
}
