.Modal {
  $stacking: scroll, header;

  $header-height: 50px;

  $height-full: 100%;

  $width-auto: auto;
  $width-full: 100%;
  $width-base: 780px;
  $width-l: 980px;
  $width-xl: 1030px;
  $width-s: 720px;

  position: relative;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - #{2 * $space-base});
  max-height: calc(100% - #{2 * $space-base});
  background-color: var(--primary-bg-main);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  box-shadow: $modal-box-shadow;
  overflow: hidden;

  @include media(m) {
    max-width: calc(100% - #{2 * $space-l});
    max-height: calc(100% - #{2 * $space-l});
  }

  &__children {
    width: 100%;
    padding: $space-xxxl;

    // Higher specificity to overwrite margin in headline and paragraph
    &#{&} > * + * {
      margin-top: $space-xl;
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    min-height: $header-height;
    z-index: z($stacking, header);
  }

  &__back {
    display: flex;
    padding-left: $space-xxxl;
  }

  &__actions {
    display: flex;
  }

  &__actionButtons {
    display: flex;
    align-items: center;
  }

  &__close {
    margin-left: $space-xs;
    background-color: var(--primary-bg-main);
    border-left: 1px solid var(--primary-fg-minor);
    border-bottom: 1px solid var(--primary-fg-minor);
  }

  &__footer {
    position: sticky;
    bottom: 0;
    background: var(--primary-bg-support);
    color: var(--primary-fg-support);
    text-align: center;
    padding: $space-l;
  }

  .Modal__scroll {
    position: relative;
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    z-index: z($stacking, scroll);

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: calc(100% - #{$scrollbar-size});
      height: $space-xl;
      opacity: 0;
      transition: opacity $transition-fast ease-in-out;
      pointer-events: none;
    }

    &::before {
      top: 0;
      background: linear-gradient(to top, var(--primary-bg-gradient-transparent-to-main)) no-repeat;
    }

    &::after {
      bottom: 0;
      background: linear-gradient(to bottom, var(--primary-bg-gradient-transparent-to-minor)) no-repeat;
    }
  }

  &--width {
    &-base {
      width: $width-base;
    }

    &-autoSize {
      display: inline-block;
      width: $width-auto;
    }

    &-l {
      width: $width-l;
    }

    &-xl {
      width: $width-xl;
    }

    &-s {
      width: $width-s;
    }

    &-full {
      width: $width-full;
      height: $height-full;
    }
  }

  &--header {
    .Modal__scroll {
      margin-top: -$header-height;

      &::before {
        top: $header-height;
      }
    }
  }

  &--header#{&}--scroll {
    .Modal__children {
      margin-top: $header-height;
      padding-top: $space-xxl;
    }
  }

  &--scroll {
    .Modal__children {
      overflow: auto;
    }
  }

  &--canScrollDown {
    .Modal__scroll {
      &::after {
        opacity: 1;
      }
    }
  }

  &--canScrollUp {
    .Modal__scroll {
      &::before {
        opacity: 1;
      }
    }
  }

  &--layout-noPadding {
    &#{&} .Modal__children {
      padding: 0;
    }
  }
}
