.SingleLabeledToggleField {
  display: flex;
  align-items: center;

  &--checked {
    .SingleLabeledToggleField__label {
      font-weight: $font-weight-active;
    }
  }

  &--align {
    &-default {
      justify-content: space-between;
    }

    &-left {
      justify-content: flex-start;
    }

    &-right {
      justify-content: flex-end;
    }
  }

  &--label-size {
    &-base {
      .SingleLabeledToggleField__label {
        font-size: $font-size-base;
        padding-right: $space-base;
      }
    }

    &-s {
      .SingleLabeledToggleField__label {
        font-size: $font-size-s;
        padding-right: $space-xs;
      }
    }
  }
}
