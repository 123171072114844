.KeyboardLayout {
  $timeout: $keyboard-delay-timeout;

  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  &__content {
    position: relative;
    height: 100vh; // Browser fallback
    height: calc(var(--vh, 1vh) * 100);
    transition: padding $timeout;
  }

  &__app {
    height: 100vh; // Browser fallback
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    transition: transform $timeout;
  }

  &__keyboard {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    transition: transform $timeout ease-out;
    z-index: z($stacking-global, keyboard);
  }

  &--enableContentScroll {
    .KeyboardLayout__app {
      overflow-y: auto;
      height: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
