.PinField {
  position: relative;
  display: inline-block;

  &__input {
    position: absolute;
    height: $field-height;
    width: 100%;
    text-indent: -9999px; // So that focusing the input with cursor/touch will result in being at the end of the input.
    opacity: 0;
    cursor: default;
  }

  .PinFieldDigit + .PinFieldDigit {
    margin-left: $space-s;
  }
}
