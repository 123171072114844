@use "sass:math";
@use "variables";

.NumberedStep {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-disabled-bg-support);
  color: var(--secondary-disabled-fg-main);
  border-radius: 50%;
  font-weight: $font-weight-bold;
  height: $size-s;
  width: $size-s;
  font-size: 0;

  @include media(m) {
    height: $size;
    width: $size;
    font-size: $font-size-base;
  }

  &--kind-active {
    background: var(--secondary-accent-bg-main);
    color: var(--secondary-accent-fg-main);
    height: $size-s-active;
    width: $size-s-active;
    font-size: $font-size-s;

    @include media(m) {
      height: $size;
      width: $size;
      font-size: $font-size-base;
    }

    &:after {
      content: "";
      position: absolute;
      top: -1 * math.div(($size-s-active-radius - $size-s-active), 2);
      left: -1 * math.div(($size-s-active-radius - $size-s-active), 2);
      right: -1 * math.div(($size-s-active-radius - $size-s-active), 2);
      bottom: -1 * math.div(($size-s-active-radius - $size-s-active), 2);
      border: $border-width-l solid var(--secondary-accent-bg-main);
      border-radius: 50%;

      @include media(m) {
        top: -1 * math.div(($size-active-radius - $size-active), 2);
        left: -1 * math.div(($size-active-radius - $size-active), 2);
        right: -1 * math.div(($size-active-radius - $size-active), 2);
        bottom: -1 * math.div(($size-active-radius - $size-active), 2);
      }
    }
  }

  &--kind-complete {
    background: var(--secondary-accent-bg-main);
    color: var(--secondary-accent-fg-main);
  }

  &--pointer {
    cursor: pointer;
  }
}
