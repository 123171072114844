.FrameSizeSettingsTable {
  &__description {
    margin-bottom: $space-l;
  }

  .TableHeaderCell {
    background: none;

    &:first-child {
      border-bottom: none;
    }
  }

  .TableCell {
    padding: $space-xs $space-s;
    font-size: $font-size-xs;
    color: var(--primary-fg-support);

    > * {
      margin: $space-xxs;
    }
  }

  .Table__headerRow > :last-child::after,
  .Table__headerRow > :first-child::before {
    background: none;
  }
}
