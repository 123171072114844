.FilterModal {
  $padding-shared-media-s: $space-l;
  $padding-shared-media-m: $space-xxl;

  display: inline-block;
  background-color: var(--primary-bg-main);
  box-shadow: $modal-box-shadow;
  max-width: 100%;

  &__aside {
    display: flex;
    // A max-width depending on the children-width is set from the `tsx`
  }

  &__meta {
    padding: $padding-shared-media-s;
    flex-grow: 1;
    max-width: 100%;

    @include media(m) {
      padding: $padding-shared-media-m;
    }
  }

  &__sub,
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
  }

  &__title {
    @include typography-headline-m;

    margin: 0;
  }

  &__description {
    @include typography-paragraph-base;

    color: var(--primary-fg-support);
  }

  &__closeButton {
    flex-shrink: 0;
  }

  &__children {
    display: flex;
    flex-direction: column;
    gap: $space-xl;

    padding: 0 $padding-shared-media-s $padding-shared-media-s;

    @include media(m) {
      padding: 0 $padding-shared-media-m $padding-shared-media-m;
    }
  }

  &__sub {
    @include typography-headline-minor;

    line-height: $line-height-s;
    color: var(--primary-fg-support);
  }

  &__titleContainer {
    display: flex;
    gap: $space-s;
    align-items: center;
  }

  &__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
  }

  &--limitHeight {
    .FilterModal__children {
      overflow-y: auto;

      max-height: 50vh; // Browser Fallback
      max-height: calc(var(--vh, 1vh) * 50);

      @include media(l) {
        max-height: 65vh; // Browser Fallback
        max-height: calc(var(--vh, 1vh) * 65);
      }
    }
  }

  &--alignRight {
    margin-left: $space-xxl; // in order not to cut off box-shadow blur
  }
}
