.SplitContentLayout {
  display: flex;
  width: 100%;

  &__column {
    width: 50%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &--separator {
    .SplitContentLayout__column:last-child {
      border-left: $border-width-base solid var(--primary-fg-divider);
    }
  }

  &--size {
    &-xs {
      .SplitContentLayout__column {
        &:first-child {
          padding-right: $space-xs;
        }
        &:last-child {
          padding-left: $space-xs;
        }
      }
    }

    &-s {
      .SplitContentLayout__column {
        &:first-child {
          padding-right: $space-xl;
        }
        &:last-child {
          padding-left: $space-xl;
        }
      }
    }

    &-m {
      .SplitContentLayout__column {
        &:first-child {
          padding-right: $space-xxxl;
        }
        &:last-child {
          padding-left: $space-xxxl;
        }
      }
    }

    &-l {
      .SplitContentLayout__column {
        &:first-child {
          padding-right: $space-xxxxl;
        }
        &:last-child {
          padding-left: $space-xxxxl;
        }
      }
    }
  }

  &--verticalAlign-center {
    .SplitContentLayout__column {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &--verticalAlign-bottom {
    .SplitContentLayout__column {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  &--dynamicWidth {
    .SplitContentLayout__column {
      width: auto;
      flex-shrink: unset;
    }
  }

  &--overflow {
    height: 100%;

    .SplitContentLayout__column {
      overflow: auto;
    }
  }

  &--outerPadding {
    &-none {
      padding: 0;
    }

    &-xxl {
      padding: $space-xxl;
    }
  }

  &--background {
    &-primary-contrast-gradient-support-to-main.SplitContentLayout--backgroundGradientDirection-left {
      background: linear-gradient(to left, var(--primary-contrast-bg-gradient-support-to-main));
      color: var(--primary-contrast-fg-main);
    }

    &-primary-contrast-gradient-support-to-main.SplitContentLayout--backgroundGradientDirection-right {
      background: linear-gradient(to right, var(--primary-contrast-bg-gradient-support-to-main));
      color: var(--primary-contrast-fg-main);
    }
  }
}
