.Video {
  &--fill {
    width: 100%;
    height: 100%;
  }

  &--fill &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--rounded &__video {
    border-radius: $border-radius-l;
  }
}
