.AnnotationBox {
  display: flex;
  align-items: center;
  padding: $space-sl;
  border-radius: $border-radius-base;
  font-weight: $font-weight-bold;
  background: var(--primary-bg-main);
  color: var(--primary-fg-support);

  & > .Icon,
  & > .Badge {
    margin-right: 1em;
    flex-shrink: 0;
  }

  &--alignment {
    &-center {
      justify-content: center;
    }

    &-left {
      justify-content: left;
    }
  }

  &--noBackground {
    padding: 0;
    margin-top: -$space-l;
    margin-bottom: $space-base;
    background: var(--color-util-transparent);
    color: var(--primary-fg-main);
  }
}
