.ErrorPageLayout {
  display: flex;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  background: linear-gradient(to right top, var(--primary-bg-gradient-minor-to-support));
  border: 25px solid var(--tertiary-fg-negative);
  overflow-y: auto;

  &__content {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__left,
  &__right {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $space-xl $space-xxl;

    @include media(xl) {
      padding: $space-xxxxl $space-xxxxl $space-xl;
    }
  }

  &__left {
    width: 100%;
    overflow: auto;
  }

  &__right {
    width: 50%;
    padding-left: $space-xl;
    overflow: auto;
  }

  &__main {
    flex-grow: 1;
  }

  &__footer {
    margin-top: $space-xl;
    margin-bottom: $space-xs;
  }

  &--aside &__left {
    width: 50%;
    padding-right: $space-xl;

    &:before {
      display: block;
      content: "";
      position: absolute;
      right: 0;
      top: $space-xl;
      bottom: $space-xl;
      width: $border-width-base;
      background: var(--tertiary-fg-minor);

      @include media(xl) {
        top: $space-xxxxl;
      }
    }
  }
}
