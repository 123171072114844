.VariantAnnotation {
  $height-media-s: 46px;
  $height-media-l: 60px;

  $padding-media-s: $space-s;
  $padding-media-l: $space-base;

  $font-size-media-s: $font-size-xs;
  $font-size-media-l: $font-size-s;

  position: relative;
  display: flex;
  align-items: center;

  height: $height-media-s;
  width: 100%;
  padding: $padding-media-s;

  font-size: $font-size-media-s;
  background-color: var(--tertiary-bg-main);
  border-radius: $border-radius-xxl;
  text-align: left;
  outline: 0;

  @include media(l) {
    height: $height-media-l;
    padding: $padding-media-l;
    font-size: $font-size-media-l;
  }

  &__label,
  &__availability {
    line-height: $line-height-xs;
  }

  &__label {
    @include scut-truncate();

    color: var(--tertiary-fg-main);
    flex: 0 0 35%;
    font-weight: $font-weight-bold;
    margin: 0;
  }

  &__availability {
    flex: 0 0 55%;
    display: flex;
    align-items: center;
    font-size: $font-size-media-s;

    @include media(l) {
      font-size: $font-size-media-l;
    }
  }

  &__icon {
    position: absolute;
    right: $padding-media-s;
    color: var(--tertiary-fg-main);

    @include media(l) {
      right: $padding-media-l;
    }
  }

  &--error &__icon {
    color: var(--tertiary-fg-negative);
  }
}
