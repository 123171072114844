.ConnectionStatusListItem {
  $item-height: scut-rem(86);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: $item-height;

  & + & {
    border-top: $border-width-base solid var(--primary-fg-minor);
  }

  &__signal {
    flex-shrink: 0;
    flex-basis: 15%;
  }

  &__ssid {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    padding-right: $space-s;
    font-size: $font-size-xl;
    white-space: nowrap;
    overflow: hidden;
  }

  &__ssidLabel {
    line-height: $line-height-xs;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--connected {
    .ConnectionStatusListItem__ssidLabel {
      font-weight: $font-weight-medium;
    }
  }

  &__ssidConnection {
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    color: var(--primary-fg-support);
  }
}
