.NextCustomerWorldCallToAction {
  // design decision
  $padding: 65px;

  $animation-size: 160px;
  $animation-dot-diameter: 25px;

  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: $padding;
  cursor: pointer;

  &__headline {
    @include typography-headline-base;
    @include typography-subheadline;

    color: var(--standout-fg-support);
  }

  &__label {
    color: var(--standout-fg-main);
    font-size: 48px; // design decision
    font-weight: $font-weight-bold;
    line-height: $line-height-xs;
  }

  &__animation {
    background-image: url("../../resources/images/pulsing-arrow-right.png");

    height: $animation-size;
    width: $animation-size;

    // design decision
    margin-top: $space-xs;
    margin-left: calc(-1 * #{$animation-dot-diameter});
    margin-bottom: calc(-1 * 0.25 * #{$animation-dot-diameter});
  }
}
