.ProductDetailsContentCta {
  $height: 550px;
  $padding-vertical: $space-l;
  $stacking: bg, content, cta;

  height: $height;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: $space-base;

  border-radius: $border-radius-xl;
  padding: $space-l;
  box-shadow: $product-details-content-box-shadow;

  color: var(--standout-fg-main);
  background-color: var(--standout-bg-main);

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z($stacking, bg);

    img,
    video {
      border-radius: $border-radius-xl;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-height: 70%;
    z-index: z($stacking, content);
  }

  &__badge {
    margin-bottom: $space-base;
  }

  &__logo {
    margin-bottom: $space-base;
  }

  &__headline {
    margin-bottom: $space-xs;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__cta {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    z-index: z($stacking, cta);
  }

  &__ctaLabel {
    font-weight: $font-weight-bold;
  }
}
