.NotificationBubble {
  $bubble-min-width: 35px;
  $bubble-max-width: 55px;
  $bubble-height: 30px;
  $triangle-width: 13px;
  $triangle-height: 7px;

  background-color: var(--notif-bg-main);
  border-radius: $border-radius-xs;
  color: var(--notif-fg-main);
  display: inline-block;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  height: $bubble-height;
  line-height: $bubble-height;
  max-width: $bubble-max-width;
  min-width: $bubble-min-width;
  position: relative;
  text-align: center;

  &__count {
    margin-top: -0.05em; // vertical visual balance
    padding: 0 0.3em;
  }

  &:after {
    @include scut-triangle(top-left, $triangle-width $triangle-height, var(--notif-bg-main));
    bottom: -$triangle-height;
    content: " ";
    left: $border-radius-xs * 2;
    position: absolute;
  }
}
