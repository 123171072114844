.RadioGroupWithOther {
  display: inline-flex;
  flex-direction: row;
  width: auto;

  &__input {
    display: block;
    overflow: hidden;
    flex-shrink: 1;
    flex-grow: 1;
    width: 130px; // Design decision
  }

  &--block {
    display: flex;
  }

  &--vertical &__input {
    width: auto;
  }
}
