.ReturnButton {
  $stacking: edge, mask;

  position: relative;
  width: $keyboard-return-button-width;
  height: $keyboard-button-height;
  outline: none;
  background: var(--primary-bg-main);
  color: var(--primary-fg-main);
  border-top-right-radius: $keyboard-button-border-radius;
  border-top-left-radius: $keyboard-button-border-radius;
  border-bottom-left-radius: $keyboard-button-border-radius;
  text-align: center;
  font-size: $keyboard-button-font-size-s;
  font-weight: $font-weight-medium;

  &__extension {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    background: inherit;
    color: inherit;
    height: $keyboard-button-height + $keyboard-button-gap;
    width: $keyboard-button-width;
    border-bottom-left-radius: $keyboard-button-border-radius;
    border-bottom-right-radius: $keyboard-button-border-radius;

    &:before {
      display: block;
      content: "";
      position: absolute;
      background: var(--primary-contrast-bg-main);
      width: $keyboard-button-border-radius;
      height: $keyboard-button-border-radius;
      border-top-right-radius: $keyboard-button-border-radius;
      z-index: z($stacking, mask);
      top: 0;
      left: -1 * $keyboard-button-border-radius;
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      background: inherit;
      width: $keyboard-button-border-radius;
      height: $keyboard-button-border-radius;
      z-index: z($stacking, edge);
      top: 0;
      left: -1 * $keyboard-button-border-radius;
    }
  }

  &:active,
  &--touched {
    background-color: var(--primary-active-bg-main);
    color: var(--primary-active-fg-main);
  }
}
