.HelpModal {
  $stacking: background, content, close;

  position: relative;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  color: var(--primary-contrast-fg-main);

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    bottom: 0;
    background: var(--primary-contrast-bg-main);
    z-index: z($stacking, background);
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    // Design issue: inconsistent spacing!
    padding: $content-header-height-l (3 * $space-xl) $page-layout-padding;
    z-index: z($stacking, content);
  }

  &__close {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: $space-l;
    height: $content-header-height;
    z-index: z($stacking, close);
  }

  &--full {
    &:before {
      width: 100%;
    }

    .HelpModal__content {
      width: 100%;
      padding-left: $space-xxxxl;
      padding-right: $space-xxxxl;
      padding-bottom: $space-xxxxl;
    }
  }
}
