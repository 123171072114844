// NOTE: Keep these in sync with src/client/commons/config/shared.ts#breakpoints
$media-queries: (
  "s-only": "(max-width: 1023px)",
  "m": "(min-width: 1024px)",
  "m-only": "(min-width: 1024px) and (max-width: 1365px)",
  "l": "(min-width: 1366px)",
  "l-only": "(min-width: 1366px) and (max-width: 1919px)",
  "xl": "(min-width: 1920px)"
);

// Central Media Query Mixin
@mixin media($query) {
  @if map-has-key($media-queries, $query) {
    @media #{map-get($media-queries, $query)} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$query}`. "
      + "Please make sure it is defined in `$media-queries` map.";
  }
}
