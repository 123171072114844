.AnnotatedButton {
  position: relative;
  display: inline-block;

  &__annotation {
    margin-bottom: 0.2em;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    white-space: nowrap;
    text-align: center;
    color: var(--primary-fg-support);
  }

  &--inline &__annotation {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
  }
}
