.FilterModalModelYearValues {
  display: flex;
  flex-direction: column;
  gap: $space-l;

  @include media(l) {
    flex-direction: row;
  }

  &__default {
    @include media(l) {
      padding-right: $space-l;
      border-right: solid $border-width-l var(--primary-fg-divider);
    }

    & > .Headline {
      margin-bottom: $space-xxs;
    }

    & > .RadioListItem {
      width: $filter-item-width;
    }
  }

  &__children {
    display: grid;
    gap: $space-base;

    & > .CheckboxListItem {
      width: $filter-item-width;
    }
  }

  &--columns {
    &-1 {
      .FilterModalModelYearValues__children {
        grid-template-columns: 1fr;
      }
    }

    &-2 {
      .FilterModalModelYearValues__children {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &-3 {
      .FilterModalModelYearValues__children {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
