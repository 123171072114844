.KeyboardButton {
  $height: $keyboard-button-height;
  $min-width: $keyboard-button-width;

  @include scut-truncate();
  display: block;
  width: 100%;
  height: $height;
  outline: none;
  padding: 0;

  border-radius: $keyboard-button-border-radius;
  font-size: $keyboard-button-font-size-base;
  font-weight: $font-weight-medium;
  text-align: center;

  & > .Icon {
    position: relative;
    top: -0.08em; // Optimize vertical center
  }

  &--variant {
    &-primary {
      background: var(--primary-bg-main);
      color: var(--primary-fg-main);
      border: $border-width-base solid var(--primary-bg-main);

      &:disabled {
        background: var(--primary-disabled-bg-main);
        color: var(--primary-disabled-fg-main);
        border: $border-width-base solid var(--primary-disabled-fg-main);
      }

      &.KeyboardButton--active,
      &.KeyboardButton--locked {
        background: var(--primary-contrast-bg-main);
        color: var(--primary-contrast-fg-main);
        border: $border-width-base solid var(--primary-contrast-fg-main);
      }

      &:active,
      &--touched {
        background-color: var(--primary-active-bg-main);
        color: var(--primary-active-fg-main);
        border: $border-width-base solid var(--primary-active-bg-main);
      }

      &.KeyboardButton--locked {
        border-width: $border-width-xl;
      }
    }

    &-accent {
      background: var(--accent-bg-main);
      color: var(--accent-fg-main);
      border: $border-width-base solid var(--primary-fg-accent);

      &:disabled {
        background: var(--accent-disabled-bg-main);
        color: var(--accent-disabled-fg-main);
        border: $border-width-base solid var(--accent-disabled-fg-main);
      }

      &:active,
      &--touched {
        background-color: var(--accent-active-bg-main);
        color: var(--accent-active-fg-main);
        border: $border-width-base solid var(--accent-active-bg-main);
      }

      &.KeyboardButton--active,
      &.KeyboardButton--locked {
        background: var(--color-util-transparent);
        color: var(--accent-contrast-fg-main);
        border: $border-width-base solid var(--accent-contrast-fg-main);
      }

      &.KeyboardButton--locked {
        border-width: $border-width-xl;
      }
    }
  }

  &--multiSymbol {
    font-size: $keyboard-button-font-size-s;

    & > .Icon {
      font-size: $icon-size-base;
    }
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}
