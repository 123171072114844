.HelpPanel {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  padding: $content-header-height-l $space-xl $page-layout-padding;
  background: var(--standout-bg-main);
  color: var(--standout-fg-main);
  overflow-y: auto;

  &__close {
    position: absolute;
    // Bigger click area
    padding: $space-base $space-l $space-xl $space-xl;
    top: 0;
    right: 0;
  }

  &__logo {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__footer {
    padding-top: $space-xxl;
  }
}
