.TimeControl {
  $stacking: sliders, toggleButtons;
  $daysOfWeek: 7;
  $sliderContainerHeight: 400px;

  &__toggleContainer,
  &__sliderContainer {
    display: flex;

    > * {
      display: flex;
      width: calc(100% / #{$daysOfWeek});
      justify-content: center;
    }
  }

  &__toggleContainer {
    margin-bottom: $space-xxxl;
    position: relative;
    z-index: z($stacking, toggleButtons);
  }

  &__sliderContainer {
    height: $sliderContainerHeight;
    position: relative;
    z-index: z($stacking, sliders);
  }

  &__startupConnector {
    border-top: $border-width-l dashed var(--primary-fg-minor);
    width: 100%;
    position: absolute;
  }
}
