.RadioGroup {
  display: flex;

  &__item {
    display: flex;
  }

  &--direction {
    &-horizontal {
      flex-direction: row;
      gap: $space-l;
    }

    &-vertical {
      flex-direction: column;
    }
  }

  &--gridCols {
    display: grid;
    grid-gap: $space-xl;
  }

  &--align-center {
    .RadioGroup__item {
      justify-content: center;
    }
  }
}
