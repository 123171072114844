.CenteredPageLayout {
  display: flex;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  background: linear-gradient(to right top, var(--primary-bg-gradient-minor-to-support));
  align-items: center;

  &__main {
    padding: $page-layout-padding;
  }
}
