.PinFieldDigit {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: $field-height;
  width: $field-height;
  line-height: $field-height - 2 * $border-width-base;
  border-radius: 50%;
  border: $border-width-base solid var(--primary-fg-minor);
  outline: 0;
  font-size: $font-size-xxxl;
  text-align: center;
  pointer-events: none;
  color: var(--primary-fg-main);
  background-color: var(--primary-bg-main);
  vertical-align: middle;
  margin: $border-width-base; // Reserve space for box-shadow

  & > .Icon {
    font-size: $font-size-xl;
  }

  &--focus {
    border-color: var(--primary-fg-accent);
    box-shadow: 0 0 0 ($border-width-l - $border-width-base) var(--primary-fg-accent);
  }

  &--error {
    border-color: var(--primary-fg-negative);
    box-shadow: 0 0 0 ($border-width-l - $border-width-base) var(--primary-fg-negative);
  }
}
