.TextAnimation {
  $ta-reveal-gradient-length: 130%;
  $ta-reveal-gradient-end-position: 432%; // Note: Needs to be tested and figured out manually if you change the gradient.length

  // Note: prefix new keyframes with ca = component-animation to make them unique across the app!
  @keyframes ta-reveal-in-out {
    0% {
      background-position: $ta-reveal-gradient-end-position 100%;
    }
    50% {
      background-position: 0% 100%;
    }
    100% {
      background-position: $ta-reveal-gradient-end-position 100%;
    }
  }

  @keyframes ta-reveal-in {
    to {
      background-position: 0% 100%;
    }
  }

  @keyframes ta-reveal-out {
    to {
      background-position: $ta-reveal-gradient-end-position 100%;
    }
  }

  @keyframes ta-fade-in {
    to {
      opacity: 1;
    }
  }

  @keyframes ta-fade-out {
    to {
      opacity: 0;
    }
  }

  @keyframes ta-fade-in-out {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @mixin text-background-linear-gradient($color: hotpink) {
    background-size: $ta-reveal-gradient-length 100%;
    background-repeat: no-repeat;
    color: transparent;
    background-clip: text;
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(90deg, $color 0%, $color 80%, var(--color-util-transparent) 100%);
  }

  &--animation {
    &-hero-reveal-in-out {
      @include typography-headline-hero;
      @include text-background-linear-gradient(var(--primary-fg-main));

      animation-name: ta-reveal-in-out;
    }

    &-hero-reveal-in {
      @include typography-headline-hero;
      @include text-background-linear-gradient(var(--primary-fg-main));

      animation-name: ta-reveal-in;
      background-position: $ta-reveal-gradient-end-position 100%;
    }

    &-hero-reveal-out {
      @include typography-headline-hero;
      @include text-background-linear-gradient(var(--primary-fg-main));

      animation-name: ta-reveal-out;
    }

    &-sub-reveal-in-out {
      @include typography-subheadline;
      @include text-background-linear-gradient(var(--primary-fg-support));

      animation-name: ta-reveal-in-out;
    }

    &-sub-reveal-in {
      @include typography-subheadline;
      @include text-background-linear-gradient(var(--primary-fg-support));

      animation-name: ta-reveal-in;
      background-position: $ta-reveal-gradient-end-position 100%;
    }

    &-sub-reveal-out {
      @include typography-subheadline;
      @include text-background-linear-gradient(var(--primary-fg-support));

      animation-name: ta-reveal-out;
    }

    &-sub-fade-in {
      @include typography-subheadline;

      opacity: 0;
      animation-name: ta-fade-in;
    }

    &-sub-fade-out {
      @include typography-subheadline;

      opacity: 1;
      animation-name: ta-fade-out;
    }

    &-sub-fade-in-out {
      @include typography-subheadline;

      opacity: 0;
      animation-name: ta-fade-in-out;
    }

    &-invert-reveal-in-out {
      @include text-background-linear-gradient(var(--standout-fg-main));

      animation-name: ta-reveal-in-out;
    }

    &-invert-reveal-in {
      @include text-background-linear-gradient(var(--standout-fg-main));

      animation-name: ta-reveal-in;
      background-position: $ta-reveal-gradient-end-position 100%;
    }

    &-invert-reveal-out {
      @include text-background-linear-gradient(var(--standout-fg-main));

      animation-name: ta-reveal-out;
    }
  }
}
