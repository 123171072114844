@use "sass:math";
@use "variables";

.NumberedSteps {
  $space-active: math.div(($size-active-radius - $size-active), 2);
  $space-active-s: math.div(($size-s-active-radius - $size-s-active), 2);

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__line,
  &__activeLine,
  &__completeLine {
    flex-grow: 1;
    height: $border-width-l;
    border: 0;
    margin: 0;
  }

  &__line {
    background: var(--secondary-bg-main);
  }

  .NumberedStep--variant-active + &__line {
    margin-left: $space-active-s;
    margin-right: -$space-active-s;

    @include media(m) {
      margin-left: $space-active;
      margin-right: -$space-active;
    }
  }

  &__activeLine,
  &__completeLine {
    background: var(--secondary-accent-bg-main);
  }

  &__activeLine {
    margin-left: -$space-active-s;
    margin-right: $space-active-s;

    @include media(m) {
      margin-left: -$space-active;
      margin-right: $space-active;
    }
  }
}
