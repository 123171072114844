.ButtonWithDescription {
  $button-width: 400px; // Design decision.
  $description-max-width: 600px; // Design decision.

  display: flex;
  align-items: center;

  &__button {
    width: $button-width;
    margin-right: $space-l;
    flex-shrink: 0;
  }

  &__description {
    max-width: $description-max-width;
    font-weight: $font-weight-medium;
    color: var(--primary-fg-support);

    & > strong {
      color: var(--primary-fg-main);
    }
  }
}
