.NewsSlide {
  $content-padding: 100px 120px 60px;
  $buttons-padding: 60px 120px 60px;
  $source-image-size: 350px;
  $news-font-size: scut-rem(28);
  $news-headline-line-height: 1.1; // We need 1.1 instead of 1.0 to avoid cut off characters

  display: flex;
  position: relative;
  height: 100%;

  &,
  &__sourceImage,
  &__content {
    background-color: var(--primary-bg-main);
  }

  &__image {
    width: 50%;
    overflow: hidden;
  }

  &__sourceImage {
    position: absolute;
    left: 0;
    bottom: $space-xxl;
    width: $source-image-size;
    transform: translateX(-100%);
  }

  &__content {
    position: relative;
    width: 50%;
    height: 100%;
    padding: $content-padding;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  &__subline,
  &__headline {
    font-weight: $font-weight-bold;
    max-width: 100%;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__subline {
    @include scut-truncate;
    letter-spacing: $letter-spacing-wide;
    color: var(--primary-fg-support);
    line-height: $line-height-s;
    margin-bottom: $space-s;
    opacity: 0;
  }

  &__headline {
    max-height: 280px;
    letter-spacing: $letter-spacing-thin;
    line-height: $news-headline-line-height;
  }

  @for $i from 0 through 10 {
    &__headline-line-#{$i} {
      position: relative;
      opacity: 0;
    }
  }

  &__text {
    height: 100%;
    margin: $space-xl 0 $space-xxl;
    font-weight: $font-weight-medium;
    font-size: $news-font-size;
    overflow: hidden;
    opacity: 0;
    flex-grow: 0;
    flex-shrink: 1;

    p {
      margin: 0 0 1em;
    }

    // Style inline markdown elements
    strong {
      font-weight: $font-weight-bold;
    }

    em {
      font-style: italic;
    }
  }

  &__buttons {
    opacity: 0;
    justify-self: flex-end;
  }

  &--readMore &__text {
    display: block;
    overflow: initial;
    flex-grow: 1;
    height: auto;
  }

  &--readMore &__buttons {
    position: relative;
    padding-top: $space-xxl;
    margin-top: $space-xxxl;

    &::before {
      content: "";
      display: block;
      width: $space-xxxl;
      height: $space-xxs;
      background: var(--primary-bg-support);
      position: absolute;
      top: 0;
    }
  }

  // Define headline font-size depending on word count
  &--headlineD {
    .NewsSlide__headline {
      font-size: 60px;
    }
  }
  &--headlineM {
    .NewsSlide__headline {
      font-size: 68px;
    }
  }
  &--headlineL {
    .NewsSlide__headline {
      font-size: 80px;
    }
  }
  &--headlineXL {
    .NewsSlide__headline {
      font-size: 90px;
    }
  }
  &--headlineXXL {
    .NewsSlide__headline {
      font-size: 100px;
    }
  }

  &--visible &__image {
    > .Image {
      animation: NewsSlide-slidein-short-horizontal $transition-crawl $ease-out-expo forwards;
    }
  }

  &--visible &__sourceImage {
    animation: NewsSlide-slidein-horizontal $transition-crawl $ease-out-expo forwards;
  }

  &--visible &__subline {
    animation: NewsSlide-fadein-slidein-vertical $transition-crawl $ease-out-expo forwards;
  }

  &--visible &__headline {
    animation: NewsSlide-slidein-vertical $transition-crawl $ease-out-expo forwards;
  }

  @for $i from 0 through 10 {
    &--visible &__headline-line-#{$i} {
      animation: NewsSlide-fadein $transition-slow forwards;
      animation-delay: $transition-fast * ($i + 1);
    }
  }

  &--visible &__text {
    animation: NewsSlide-fadein-slidein-vertical $transition-crawl $ease-out-expo forwards;
    animation-delay: $transition-slow;
  }

  &--visible &__buttons {
    animation: NewsSlide-fadein $transition-base forwards;
    animation-delay: $transition-slow + $transition-base;
  }

  @keyframes NewsSlide-slidein-vertical {
    0% {
      transform: translateY($space-xxl);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes NewsSlide-fadein-slidein-vertical {
    0% {
      opacity: 0;
      transform: translateY($space-xxl);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes NewsSlide-fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes NewsSlide-slidein-short-horizontal {
    0% {
      transform: translateX(-10%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes NewsSlide-slidein-horizontal {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0);
    }
  }
}
