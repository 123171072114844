.SelectMenuItem {
  @include scut-truncate;

  outline: 0;
  width: 100%;
  height: $field-height-xs;
  line-height: $field-height-xs;
  font-size: $font-size-xs;
  text-align: left;
  padding: 0 $space-s;

  @include media(l) {
    height: $field-height-s;
    line-height: $field-height-s;
    font-size: $font-size-s;
  }

  @include media(xl) {
    height: $field-height;
    font-size: $font-size-base;
    line-height: $field-height;
  }

  &--selected {
    font-weight: $font-weight-medium;
    letter-spacing: $letter-spacing-compensate-font-weight;
  }
}
