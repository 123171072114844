.TabBar {
  background: var(--primary-bg-main);
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1px;

  &__item {
    flex-grow: 1;
    width: 0%;
  }
}
