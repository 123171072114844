.FullKeyboardLayout {
  &__symbols {
    display: none; // Disabled by default
  }

  &__default {
    display: flex;
    flex-direction: row;
    gap: $keyboard-button-gap; // The gap between the quertz and the numpad keyboard
  }

  &--showAllSymbols {
    .FullKeyboardLayout__symbols {
      display: initial;
    }

    .FullKeyboardLayout__default {
      display: none;
    }
  }
}
