.LetterKeyboardLayout {
  $a-button-size: 77px;

  $odd-column: $a-button-size - $keyboard-button-width - $keyboard-button-gap;
  $even-column: $keyboard-button-width - $odd-column - $keyboard-button-gap;

  display: grid;
  grid-template-columns: repeat(13, $odd-column $even-column);
  grid-auto-rows: $keyboard-button-height;
  grid-column-gap: $keyboard-button-gap;
  grid-row-gap: $keyboard-button-gap;
  justify-content: start;

  & > * {
    // Default case: Each button uses two cells
    grid-column-end: span 2;
  }

  &__backspaceButton {
    grid-column-start: 23;
    grid-column-end: span 4;
    grid-row-start: 1;
    grid-row-end: span 1;
  }

  &__returnButton {
    grid-column-start: 24;
    grid-column-end: span 3;
    grid-row-start: 2;
    grid-row-end: span 1;
  }

  &__shiftButton {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 3;
    grid-row-end: span 1;
  }

  &__allSymbolsButton {
    grid-column-start: 1;
    grid-column-end: span 4;
    grid-row-start: 4;
    grid-row-end: span 1;
  }

  &__symbolsButton {
    grid-column-start: 5;
    grid-column-end: span 2;
    grid-row-start: 4;
    grid-row-end: span 1;
  }

  &__spaceButton {
    grid-column-start: 7;
    grid-column-end: span 14;
    grid-row-start: 4;
    grid-row-end: span 1;
  }

  &__leftButton {
    grid-column-start: 23;
    grid-column-end: span 2;
    grid-row-start: 4;
    grid-row-end: span 1;
  }

  &__rightButton {
    grid-column-start: 25;
    grid-column-end: span 2;
    grid-row-start: 4;
    grid-row-end: span 1;
  }

  // The first key ("a"-key) in the second row
  & > :nth-child(12) {
    grid-column-end: span 3;
  }

  // The 3rd row the last key (before enter)
  // Keeps the cell under return empty
  & > :nth-child(33) {
    grid-column-end: span 4;
    width: $keyboard-button-width;
  }
}
