.InformationLayout {
  display: flex;

  &__icon {
    font-size: $icon-size-xl;
    margin-right: $space-l;
    flex-shrink: 0;
    flex-grow: 0;
    width: $icon-size-xl;
    margin-top: -22px; // Compensate for Icon padding
  }

  &__content {
    flex-shrink: 1;

    &#{&} > * + * {
      margin-top: $space-l;
    }
  }

  &--kind {
    &-default {
      color: var(--primary-fg-main);

      .InformationLayout__icon {
        color: var(--primary-fg-minor);
      }
    }

    &-accent {
      .InformationLayout__icon {
        color: var(--primary-fg-accent);
      }
    }

    &-minor {
      padding: $space-xl 0;

      border-bottom: 1px solid var(--primary-fg-minor);
      border-top: 1px solid var(--primary-fg-minor);

      .InformationLayout__content > * {
        color: var(--primary-fg-minor);
      }

      .InformationLayout__icon {
        color: var(--primary-fg-minor);
      }
    }
  }
}
