.BookmarkSliderItem {
  display: inline-flex;
  flex-direction: column;
  padding: $space-l $space-base;

  &__footer {
    margin-top: $space-l;

    > * + * {
      margin-top: $space-base;
    }
  }
}
