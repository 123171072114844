.LoadingIndicator {
  $loading-indicator-size-base: 105px;
  $loading-indicator-size-s: $icon-size-base;
  $loading-indicator-timing: 1s;
  $loading-indicator-easing: cubic-bezier(0.25, 0.75, 0.8, 0.55);

  display: inline-flex;
  line-height: 1;
  align-items: center;

  &--size {
    &-base {
      font-size: $loading-indicator-size-base;
    }

    &-s {
      font-size: $loading-indicator-size-s;
    }
  }

  &--label {
    & > .Icon {
      margin-right: 0.5em;
    }
  }

  &__label {
    font-size: $font-size-xs;

    @include media(l) {
      font-size: $font-size-s;
    }

    @include media(xl) {
      font-size: $font-size-base;
    }
  }

  &__progress {
    position: absolute;
    width: $loading-indicator-size-base;
    line-height: $line-height-base;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    letter-spacing: 0.15rem;
    text-align: center;
  }

  & > .Icon {
    animation: LoadingIndicator-spin $loading-indicator-timing $loading-indicator-easing infinite;
    font-size: inherit;
  }

  &--variant {
    &-primary {
      .LoadingIndicator__label {
        color: var(--primary-fg-support);
      }

      .LoadingIndicator__progress {
        color: var(--primary-fg-main);
      }

      & > .Icon {
        fill: var(--primary-fg-main);
      }
    }

    &-standout {
      .LoadingIndicator__label {
        color: var(--standout-fg-support);
      }

      .LoadingIndicator__progress {
        color: var(--standout-fg-main);
      }

      & > .Icon {
        fill: var(--standout-fg-main);
      }
    }
  }
}

@keyframes LoadingIndicator-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
