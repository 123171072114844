.Badge {
  $badge-padding: 1em;
  $badge-padding-s: 0.5em;
  $triangle-size: 0.67em 0.35em;

  @include typography-subheadline;
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: $border-radius-base;
  line-height: $line-height-l;

  &::after {
    display: none;
    position: absolute;
    top: 100%;
    left: $badge-padding;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 $triangle-size 0;
  }

  & > .Icon {
    font-size: 1em;
    margin-right: $badge-padding;
  }

  &--variant {
    &-primary {
      background: var(--primary-bg-main);
      color: var(--primary-fg-main);

      &::after {
        border-color: var(--color-util-transparent) var(--primary-bg-main) var(--color-util-transparent)
          var(--color-util-transparent);
      }
    }

    &-neutral {
      background: var(--neutral-bg-main);
      color: var(--neutral-fg-main);

      &::after {
        border-color: var(--color-util-transparent) var(--neutral-bg-main) var(--color-util-transparent)
          var(--color-util-transparent);
      }
    }

    &-negative {
      background: var(--negative-bg-main);
      color: var(--negative-fg-main);

      &::after {
        border-color: var(--color-util-transparent) var(--negative-bg-main) var(--color-util-transparent)
          var(--color-util-transparent);
      }
    }

    &-warning {
      background: var(--warning-bg-main);
      color: var(--warning-fg-main);

      &::after {
        border-color: var(--color-util-transparent) var(--warning-bg-main) var(--color-util-transparent)
          var(--color-util-transparent);
      }
    }

    &-positive {
      background: var(--positive-bg-main);
      color: var(--positive-fg-main);

      &::after {
        border-color: var(--color-util-transparent) var(--positive-bg-main) var(--color-util-transparent)
          var(--color-util-transparent);
      }
    }

    &-accent {
      background: var(--accent-bg-main);
      color: var(--accent-fg-main);

      &::after {
        border-color: var(--color-util-transparent) var(--accent-bg-main) var(--color-util-transparent)
          var(--color-util-transparent);
      }
    }
  }

  &--size {
    &-default {
      padding: 0 $badge-padding;
    }

    &-s {
      font-size: $font-size-xs;
      padding: 0 $badge-padding-s;

      & > .Icon {
        margin-right: $badge-padding-s;
      }
    }

    &-inherit {
      font-size: 1em;
      padding: 0 $badge-padding-s;

      & > .Icon {
        margin-right: $badge-padding-s;
      }
    }
  }

  &--triangle::after {
    display: block;
  }
}
