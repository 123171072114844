.RangeField {
  $stacking-context: rail, track, label, handle;
  $rc-slider-height: $field-height;
  $rc-slider-height-half: calc($rc-slider-height / 2);
  $rc-slider-handle-space: 3px;
  $rc-slider-handle-size: $rc-slider-height - 2 * $rc-slider-handle-space;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: $rc-slider-height 1fr;
  width: 100%;
  z-index: 1; // Creates stacking context for its children

  @keyframes fadein {
    0% {
      opacity: 0;
      visibility: visible;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    99% {
      opacity: 0;
    }
    100% {
      visibility: hidden;
      transform: scale(0);
      opacity: 0;
    }
  }

  &__slider {
    padding: 0 $rc-slider-height-half;
    grid-column: 1 / 3;
    grid-row: 1 / 1;
  }

  &__balloon {
    position: absolute;
    left: 50%;
    top: -$rc-slider-handle-space;
    transform: translate(-50%, -100%);
    width: max-content;
  }

  &--tooltip {
    &-on {
      .RangeField__balloon {
        opacity: 1;
        visibility: visible;
      }
    }

    &-auto {
      .RangeField__balloon {
        opacity: 0;
        visibility: hidden;
        transition: all 300ms ease-in-out;
      }

      .rc-slider > [dragging="true"] {
        .RangeField__balloon {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &--label-position {
    &-inside {
      .RangeField {
        grid-template-rows: $rc-slider-height;
      }

      .RangeField__minLabel {
        padding-left: $space-base;
        grid-row: 1 / 1;
      }

      .RangeField__maxLabel {
        padding-right: $space-base;
        grid-row: 1 / 1;
      }
    }

    &-outside {
      grid-template-rows: $rc-slider-height max-content;

      .RangeField__minLabel,
      .RangeField__maxLabel {
        font-weight: $font-weight-regular;
        color: var(--primary-fg-support);
      }

      .RangeField__minLabel {
        grid-row: 2 / 3;
      }

      .RangeField__maxLabel {
        grid-row: 2 / 3;
      }
    }
  }

  &__minLabel,
  &__maxLabel {
    font-weight: $font-weight-bold;
    pointer-events: none;
    z-index: z($stacking-context, label);
    color: var(--primary-accent-fg-main);
    line-height: $rc-slider-height;
  }

  &__minLabel {
    grid-column: 1 / 2;
  }

  &__maxLabel {
    grid-column: 2 / 3;
    justify-self: end;
  }

  .rc-slider {
    position: relative;
    width: 100%;

    &-rail {
      position: absolute;
      height: $rc-slider-height;
      left: -$rc-slider-height-half;
      width: calc(100% + #{$rc-slider-height});
      background: var(--primary-accent-bg-main);
      border-radius: $rc-slider-height-half;
      z-index: z($stacking-context, rail);
      touch-action: manipulation;
    }

    &-track {
      position: absolute;
      height: $rc-slider-height;
      background: var(--color-util-transparent);
      z-index: z($stacking-context, track);
      touch-action: manipulation;
    }

    &-handle {
      position: absolute;
      width: $rc-slider-handle-size;
      height: $rc-slider-handle-size;
      margin-top: $rc-slider-handle-space;
      border: $rc-slider-handle-space solid var(--color-util-transparent);
      border-radius: 50%;
      background: #fff;
      outline: 0;
      cursor: grab;
      touch-action: pan-x;
      z-index: z($stacking-context, handle);

      &:active {
        cursor: grabbing;
      }
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &--range {
    .RangeField__minLabel,
    .RangeField__maxLabel {
      color: var(--primary-fg-support);
    }

    .rc-slider {
      &-rail {
        background: var(--primary-bg-minor);
      }

      &-track {
        background: var(--primary-accent-bg-main);
      }

      &-handle {
        width: $rc-slider-height;
        height: $rc-slider-height;
        margin-top: 0;

        border: $rc-slider-handle-space solid var(--primary-fg-accent);

        &-2 {
          border: $rc-slider-handle-space solid var(--primary-fg-accent);
        }
      }
    }
  }

  &--disabled {
    .RangeField__minLabel,
    .RangeField__maxLabel {
      color: var(--primary-disabled-fg-main);
    }

    .rc-slider {
      &-rail {
        background: var(--primary-disabled-bg-main);
      }

      &-handle {
        background: var(--primary-disabled-bg-minor);
      }
    }

    &.RangeField--range {
      .rc-slider {
        &-rail {
          background: var(--primary-disabled-bg-support);
        }

        &-track {
          background: var(--primary-disabled-bg-main);
        }

        &-handle {
          background: var(--primary-disabled-bg-minor);
          border: $rc-slider-handle-space solid var(--primary-disabled-bg-main);
        }

        &-2 {
          border: $rc-slider-handle-space solid var(--primary-disabled-bg-main);
        }
      }
    }
  }
}
