.Paragraph {
  @include typography-paragraph-base;

  margin: 0;

  strong {
    font-weight: $font-weight-bold;
  }

  &--kind-support {
    color: var(--secondary-fg-support);
  }

  &--size {
    &-base {
      font-size: $font-size-base;
    }

    &-l {
      font-size: $font-size-xl;
    }

    &-s {
      font-size: $font-size-s;
    }

    &-xs {
      font-size: $font-size-xs;
    }
  }

  &--text-align {
    &-inherit {
      text-align: inherit;
    }

    &-left {
      text-align: left;
    }
  }
}
