.Button {
  $height: $field-height;
  $height-s: $field-height-s;
  $height-xs: $field-height-s;
  $min-width: 130px;
  $padding: 1.6em;
  $padding-vertical: 0.3em;
  $padding-icon: 1.6em;
  $padding-text: 0.6em;
  $space-between-icon-and-text: 0.5em;

  position: relative;
  display: inline-flex;
  align-items: center;
  min-width: $min-width;
  outline: none;
  border-radius: 9999px; // half-circle edge
  padding: $padding-vertical $padding;
  font-weight: $font-weight-medium;
  text-align: left;
  line-height: $line-height-s;
  overflow: hidden;

  & > .Icon,
  & > .LoadingIndicator {
    position: relative;
    margin-right: $space-between-icon-and-text;
  }

  &__label {
    position: relative;
    text-align: center;
    flex-grow: 1;
  }

  &--size {
    &-default {
      min-height: $height;
      font-size: $font-size-base;
    }

    &-s {
      min-height: $height-s;
      font-size: $font-size-base;
    }
  }

  &--block {
    display: flex;
    width: 100%;

    & > .Button__label {
      @include scut-truncate;
    }
  }

  &--variant {
    &-primary {
      &.Button--kind {
        &-outlined {
          background: var(--color-util-transparent);
          color: var(--primary-fg-main);
          border: $border-width-base solid var(--primary-fg-main);

          &:active,
          &.Button--touched {
            color: var(--primary-active-fg-main);
            border: $border-width-base solid var(--primary-active-fg-main);
          }

          &.Button--disabled {
            color: var(--primary-disabled-fg-main);
            border: $border-width-base solid var(--primary-disabled-fg-main);

            &:active {
              color: var(--primary-active-fg-main);
            }
          }
        }

        &-solid {
          background: var(--primary-bg-main);
          color: var(--primary-fg-main);
          border: $border-width-base solid var(--color-util-transparent);

          &:active,
          &.Button--touched {
            background: var(--primary-active-bg-main);
            color: var(--primary-active-fg-main);
          }

          &.Button--disabled {
            background: var(--primary-disabled-bg-main);
            color: var(--primary-disabled-fg-main);

            &:active {
              color: var(--primary-active-fg-main);
            }
          }
        }
      }
    }

    &-tertiary {
      &.Button--kind {
        &-outlined {
          background: var(--tertiary-bg-main);
          color: var(--tertiary-fg-main);
          border: $border-width-base solid var(--tertiary-fg-minor);

          &:active,
          &.Button--touched {
            color: var(--tertiary-active-fg-main);
            border: $border-width-base solid var(--tertiary-active-fg-main);
          }

          &.Button--disabled {
            color: var(--tertiary-disabled-fg-main);
            border: $border-width-base solid var(--tertiary-disabled-fg-main);

            &:active {
              color: var(--tertiary-active-fg-main);
            }
          }
        }

        &-solid {
          background: var(--tertiary-bg-main);
          color: var(--tertiary-fg-main);
          border: $border-width-base solid var(--color-util-transparent);

          &:active,
          &.Button--touched {
            background: var(--tertiary-active-bg-main);
            color: var(--tertiary-active-fg-main);
          }

          &.Button--disabled {
            background: var(--tertiary-disabled-bg-main);
            color: var(--tertiary-disabled-fg-main);

            &:active {
              color: var(--tertiary-active-fg-main);
            }
          }
        }
      }
    }

    &-standout {
      &.Button--kind {
        &-outlined {
          background: var(--color-util-transparent);
          color: var(--standout-fg-main);
          border: $border-width-base solid var(--standout-fg-main);

          &:active,
          &.Button--touched {
            color: var(--standout-active-fg-main);
            border: $border-width-base solid var(--standout-active-fg-main);
          }

          &.Button--disabled {
            color: var(--standout-disabled-fg-main);
            border: $border-width-base solid var(--standout-disabled-fg-main);

            &:active {
              color: var(--standout-active-fg-main);
            }
          }
        }

        &-solid {
          background: var(--standout-bg-main);
          color: var(--standout-fg-main);
          border: $border-width-base solid var(--color-util-transparent);

          &:active,
          &.Button--touched {
            background: var(--standout-active-bg-main);
            color: var(--standout-active-fg-main);
          }

          &.Button--disabled {
            background: var(--standout-disabled-bg-main);
            color: var(--standout-disabled-fg-main);

            &:active {
              color: var(--standout-active-fg-main);
            }
          }
        }
      }
    }

    &-accent {
      &.Button--kind {
        &-outlined {
          background: var(--color-util-transparent);
          color: var(--accent-contrast-fg-main);
          border: $border-width-base solid var(--accent-contrast-fg-main);

          &:active,
          &.Button--touched {
            color: var(--accent-active-fg-main);
            border: $border-width-base solid var(--accent-active-fg-main);
          }

          &.Button--disabled {
            color: var(--accent-disabled-fg-main);
            border: $border-width-base solid var(--accent-disabled-fg-main);

            &:active {
              color: var(--accent-active-fg-main);
            }
          }
        }

        &-solid {
          background: var(--accent-bg-main);
          color: var(--accent-fg-main);
          border: $border-width-base solid var(--color-util-transparent);

          &:active,
          &.Button--touched {
            background: var(--accent-active-bg-main);
            color: var(--accent-active-fg-main);
          }

          &.Button--disabled {
            background: var(--accent-disabled-bg-main);
            color: var(--accent-disabled-fg-main);

            &:active {
              color: var(--accent-active-fg-main);
            }
          }
        }
      }
    }

    &-negative {
      &.Button--kind {
        &-outlined {
          background: var(--color-util-transparent);
          color: var(--negative-contrast-fg-main);
          border: $border-width-base solid var(--negative-contrast-fg-main);

          &:active,
          &.Button--touched {
            color: var(--negative-active-fg-main);
            border: $border-width-base solid var(--negative-active-fg-main);
          }

          &.Button--disabled {
            color: var(--negative-disabled-fg-main);
            border: $border-width-base solid var(--negative-disabled-fg-main);

            &:active {
              color: var(--negative-active-fg-main);
            }
          }
        }

        &-solid {
          background: var(--negative-bg-main);
          color: var(--negative-fg-main);
          border: $border-width-base solid var(--color-util-transparent);

          &:active,
          &.Button--touched {
            background: var(--negative-active-bg-main);
            color: var(--negative-active-fg-main);
          }

          &.Button--disabled {
            background: var(--negative-disabled-bg-main);
            color: var(--negative-disabled-fg-main);

            &:active {
              color: var(--negative-active-fg-main);
            }
          }
        }
      }
    }
  }

  &--icon {
    padding-right: $padding;
    padding-left: $padding;
  }

  &--iconRight {
    flex-direction: row-reverse;

    & > .Icon,
    & > .LoadingIndicator {
      margin-right: initial;
      margin-left: $space-between-icon-and-text;
    }
  }

  &--rectangular {
    border-radius: 0;
  }

  &--singleLine {
    white-space: nowrap;
  }

  &--buzzing {
    animation: button-buzz 0.15s ease-in-out 0s 5;
  }
}

@keyframes button-buzz {
  50% {
    transform: translateX(3px) rotate(1deg);
  }
  100% {
    transform: translateX(-3px) rotate(-1deg);
  }
}

@keyframes progress {
  0% {
    right: 100%;
  }
  100% {
    right: 0;
  }
}
