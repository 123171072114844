.Icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;

  // We need this to fix a weird cut-off in Chrome/Electron
  // Source: https://stackoverflow.com/a/46344280
  overflow: visible;

  &--block {
    display: block;
  }

  &--size {
    &-base {
      font-size: $icon-size-base;
    }

    &-l {
      font-size: $icon-size-l;
    }

    &-xl {
      font-size: $icon-size-xl;
    }
  }

  &--filter {
    font-size: $icon-size-filter;
  }

  &--spin {
    animation: spin infinite 2s cubic-bezier(0.25, 0.5, 0.75, 0.5);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
