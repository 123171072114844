.ContextMenuButton {
  display: inline-flex;
  align-items: center;
  border-radius: $border-radius-base;
  padding: 0 $space-s;
  height: $field-height;
  font-size: $font-size-base;
  outline: 0;
  background: var(--accent-bg-main);
  color: var(--accent-fg-main);

  &__label {
    @include scut-truncate;
  }

  & > .Icon {
    flex-shrink: 0;
    margin-left: $space-base;
  }

  &:disabled {
    background: var(--accent-disabled-bg-main);
    color: var(--accent-disabled-fg-main);
    cursor: not-allowed;
    pointer-events: none;
  }

  &:active,
  &--touched {
    background: var(--accent-active-bg-main);
    color: var(--accent-active-fg-main);
  }
}
