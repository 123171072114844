.BrandListItem {
  @include list-item;

  .BrandListItem__label {
    margin-left: 0;
  }

  .BrandListItem__icon {
    margin-right: $space-s;
  }

  &--active {
    color: var(--primary-accent-fg-main);
    background: var(--primary-accent-bg-main);
    font-weight: $font-weight-medium;
  }

  &--disabled {
    background: var(--primary-disabled-bg-support);
    color: var(--primary-disabled-fg-main);
  }
}
