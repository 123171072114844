.LicenseContent {
  $max-width: 800px;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &__header {
    text-align: center;
    margin-bottom: $space-xl;
  }

  &__status {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: $space-l;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    text-align: left;
    max-width: $max-width;

    & > * {
      width: fit-content;
    }

    & > * + * {
      margin-top: $space-l;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    background-color: var(--primary-contrast-bg-main);
    color: var(--primary-contrast-fg-main);
    padding: $space-xxxl;
    width: 100%;

    & > * {
      max-width: $max-width;
    }
  }
}
