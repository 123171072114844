.AvailabilityLayout {
  $stacking: content, aside;

  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: minmax(0, 0.6fr) minmax(0, 0.4fr); // Why minmax(0, ...) ? Source: https://stackoverflow.com/a/52861514
  background-color: var(--primary-bg-main);

  // Enable click-though for onContentClick on TabBarOverlay
  pointer-events: none;

  &__filters,
  &__variants,
  &__variantInformation {
    // Re-Enable interaction
    pointer-events: initial;
  }

  &__variants,
  &__variantsScrollContainer {
    @include hide-scrollbar;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: $space-l;
    gap: $space-l;
    z-index: z($stacking, content);

    @include media(l) {
      padding-top: $space-xl;
      gap: $space-xl;
    }

    @include media(xl) {
      padding-top: $space-xxl;
      gap: $space-xxl;
    }
  }

  &__headline {
    display: none;
    text-align: center;
    padding: 0 $space-xxl;

    @include media(l) {
      display: block;
    }

    // TODO: overwriting the Headline component like this is an antipattern.
    & > .Headline {
      @include typography-headline-base;

      @include media(l) {
        @include typography-headline-s;

        // Overwrite font-weight from mixin
        font-weight: $font-weight-bold;
      }

      @include media(xl) {
        @include typography-headline-m;
      }
    }
  }

  &__filters {
    display: grid;
    justify-content: stretch;
    column-gap: $space-base;
    row-gap: $space-xxs;
    padding: 0 $space-xxl;

    @include media(m) {
      justify-content: center;
    }

    @include media(l) {
      column-gap: $space-l;
    }

    @include media(xl) {
      gap: $space-xl;
    }
  }

  &__variants {
    position: relative;
    overflow: auto;
    flex-grow: 1;
  }

  &__variantsScrollContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    padding: 0 $space-l;
  }

  &__scrollIndicator {
    position: absolute;
    pointer-events: none;

    &--vertical {
      width: 100%;
      height: $space-xxl;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, var(--primary-bg-gradient-transparent-to-main));
    }

    &--horizontal {
      height: 100%;
      width: $space-xxl;
      top: 0;
      right: 0;
      background: linear-gradient(to right, var(--primary-bg-gradient-transparent-to-main));
    }
  }

  &__variantInformation {
    position: relative;
    // Separate the space with a shadow and offset the shadow by the width of the scrollbar
    box-shadow: 0 0 30px 0 var(--color-shadow-medium);
    z-index: z($stacking, aside);

    & > * {
      position: absolute;
      top: $space-l;
      left: $space-l;
      right: $space-l;
      bottom: 0;
    }

    @include media(l) {
      & > * {
        left: $space-xl;
        right: $space-xl;
      }
    }

    @include media(xl) {
      & > * {
        top: $space-xxl;
        left: $space-xxxl;
        right: $space-xxxl;
      }
    }
  }

  &--filtersCount {
    $col-width-narrow: minmax(0, 0.35fr);
    $col-width-mid: minmax(0, 0.5fr);
    $col-width-wide: minmax(0, 0.65fr);

    &-1,
    &-2,
    &-3 {
      .AvailabilityLayout__filters {
        @include media(m) {
          grid-template-columns: $col-width-wide;
        }
      }
    }

    &-1 {
      .AvailabilityLayout__filters {
        @include media(l) {
          grid-template-columns: $col-width-mid;
        }

        @include media(xl) {
          grid-template-columns: $col-width-narrow;
        }
      }
    }

    &-2 {
      .AvailabilityLayout__filters {
        @include media(l) {
          grid-template-columns: repeat(2, $col-width-mid);
        }

        @include media(xl) {
          grid-template-columns: repeat(2, $col-width-narrow);
        }
      }
    }

    &-3 {
      .AvailabilityLayout__filters {
        @include media(xl) {
          grid-template-columns: repeat(3, $col-width-narrow);
        }
      }
    }
  }

  &--isHorizontallyScrollable {
    .AvailabilityLayout__variantsScrollContainer {
      justify-content: flex-start;
    }
  }

  &--isScrolledToHorizontalEnd {
    .AvailabilityLayout__scrollIndicator--horizontal {
      opacity: 0;
      transition: $transition-base opacity $ease-in-out-quad;
    }
  }

  &--isScrolledToVerticalEnd {
    .AvailabilityLayout__scrollIndicator--vertical {
      opacity: 0;
      transition: $transition-base opacity $ease-in-out-quad;
    }
  }

  &--noSeparationShadow {
    .AvailabilityLayout__variantInformation {
      box-shadow: none;
    }
  }

  &--noTopSpacing {
    .AvailabilityLayout__variants {
      padding-top: 0;
    }

    .AvailabilityLayout__variantInformation {
      padding-top: 0;
    }

    &.AvailabilityLayout--hasFilters {
      .AvailabilityLayout__filters {
        margin-top: 0;
      }
    }

    &.AvailabilityLayout--hasHeadline {
      .AvailabilityLayout__headline {
        margin-top: 0;
      }
    }
  }
}
