.KeyboardHandleButton {
  $space-between-icon-and-text: 0.5em;

  display: inline-flex;
  align-items: center;
  height: $keyboard-handle-button-height;
  width: $keyboard-handle-button-width;
  padding: 0 $space-base;
  border: 0;
  outline: none;
  background: var(--accent-bg-main);
  color: var(--accent-fg-main);
  font-size: $font-size-base;
  font-weight: $font-weight-medium;

  &__icon {
    line-height: 0;
    margin-right: $space-between-icon-and-text;

    & > .Icon {
      font-size: 2em;
    }
  }

  &__label {
    @include scut-truncate;
  }

  &:active,
  &--touched {
    background: var(--accent-active-bg-main);
  }
}
