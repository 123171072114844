.CheckboxListItemWithIcon {
  $h-padding: $space-sl;

  max-width: $icon-size-filter + ($h-padding * 2);
  padding: $space-xs $h-padding $space-base;

  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  text-align: center;

  background: var(--primary-bg-main);
  color: var(--primary-fg-main);
  outline: 0; // Hide focus outline

  &__label {
    @include scut-truncate;
    width: 100%; // The width prop is needed to make the truncation work

    margin-bottom: $space-s;
  }

  &__checkbox {
    @include checkbox-base;
  }

  &--active {
    font-weight: $font-weight-medium;

    .CheckboxListItemWithIcon__checkbox {
      background: var(--primary-accent-bg-main);
      border-color: var(--primary-fg-accent);
      color: var(--primary-accent-fg-main);
    }
  }

  &--outline {
    border: $border-width-base solid var(--primary-fg-minor);
  }
}
