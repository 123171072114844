.MultiColumnFormLayout {
  $column-width: $field-width;

  display: flex;

  &__column {
    margin-right: $space-xxxl;
    width: $column-width;

    &:last-child {
      margin-right: 0;
    }
  }
}
