// IMPORTANT IF YOU EDIT THIS FILE
// All variables which should be overwritten by a theme must have a `!default` at the end!

// Typography
// ---------------------------------------------------

$font-family-base: "DIN 2014", sans-serif !default;
$font-family-mono: "Ubuntu Mono", monospace !default;

// Do not make this a default variable because scut already defined it as default!
$scut-em-base: 18px;

$font-size-xxs: scut-rem(12) !default;
$font-size-xs: scut-rem(14) !default;
$font-size-s: scut-rem(16) !default;
$font-size-base: scut-rem($scut-em-base) !default;
$font-size-l: scut-rem(20) !default;
$font-size-xl: scut-rem(24) !default;
$font-size-xxxl: scut-rem(32) !default;
$font-size-xxxxl: scut-rem(42) !default;
$font-size-xxxxxl: scut-rem(62) !default;

$line-height-xs: 1 !default;
$line-height-s: 1.2 !default;
$line-height-base: 1.5 !default;
$line-height-l: 1.7 !default;

$font-weight-thin: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

// Semantic font weight for active, selected or checked labels
$font-weight-active: $font-weight-bold !default;

$letter-spacing-thin: scut-em(-0.035px) !default;
$letter-spacing-wide: 0.14em !default;
// Compensate change between the font weights
$letter-spacing-compensate-font-weight: -0.005em !default;

// Z-Index
// (http://www.smashingmagazine.com/2014/06/12/sassy-z-index-management-for-complex-layouts/)
// ---------------------------------------------------

$stacking-global:
  timecontrol-popup, overlay, portal, keyboard, video-conference-box, warning-overlay, cursor-highlight, tool-transition;

// Layout & Grid
// ---------------------------------------------------

$space-xxxs: 2px;
$space-xxs: 5px;
$space-xs: 10px;
$space-s: 15px;
$space-base: 20px;
$space-sl: 25px; // TODO: naming smell
$space-l: 30px;
$space-xl: 40px;
$space-xxl: 50px;
$space-xxxl: 80px;
$space-xxxxl: 160px;

$page-layout-padding: $space-xxl;
$content-header-height: 90px;
$content-header-height-l: $content-header-height + 50px;
$content-footer-height: 50px;
$header-height-small: $content-header-height + 30px; // = 120px
$header-height-large: $content-header-height + 70px; // = 160px

$width-base: 30rem;

// Transitions
// ---------------------------------------------------

$transition-fast: 0.2s;
$transition-base: 0.4s;
$transition-slow: 0.8s;
$transition-crawl: 1.5s;

$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);

// Borders
// ---------------------------------------------------

$border-width-base: 1px;
$border-width-l: 2px;
$border-width-xl: 3px;

$border-radius-xs: 3px;
$border-radius-base: 5px;
$border-radius-l: 10px;
$border-radius-xl: 15px;
$border-radius-xxl: 20px;

// Keyboard
// ---------------------------------------------------

// This needs to be in sync with the keyboard height in the electron keyboard window.
$keyboard-panel-height: 350px;

$keyboard-button-gap: $space-xs;
$keyboard-button-height: 54px;
$keyboard-button-width: 60px;
$keyboard-return-button-width: 113px;
$keyboard-button-border-radius: $border-radius-base;
$keyboard-button-font-size-s: $font-size-base;
$keyboard-button-font-size-base: $font-size-xl;

// Fields
// ---------------------------------------------------

$field-width: 400px;
$field-width-s: 280px;

$field-height: 50px;
$field-height-s: 40px;
$field-height-xs: 30px;

$labeled-field-label-height: $font-size-base * $line-height-base + scut-rem($space-xs);
$labeled-field-error-height: scut-rem(20px) + scut-rem($space-xxs);

// Icons
// ---------------------------------------------------

$icon-size-base: 22px;
$icon-size-l: 40px;
$icon-size-xl: 165px;
$icon-size-filter: 2 * $icon-size-l;

// Misc
// ---------------------------------------------------

$scrollbar-size: 10px;
$tab-bar-height: 60px;
$tab-bar-height-s: 45px;
$tab-bar-height-xs: 40px;
$sidebar-width: 420px;
$product-tile-height: 240px;
$product-tile-height-s: 200px;
$bookmark-height: 300px;
$bookmark-width: 240px;
$product-comparison-columns: 15% minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
$brand-logo-width: 260px;
$input-icon-size: 28px;
$number-indicator-size: 20px;
$timecontrol-handle-size: 40px;
$timecontrol-handle-border-width: $border-width-l;
$tile-height: 240px;
$tile-height-l: 260px;
$tile-height-xl: 300px;
$main-navigation-icon-height: 135px;
$perspective-default: 600px;
$balloon-box-shadow: 0 0 40px var(--color-shadow-medium);
$modal-box-shadow: 0 0 50px var(--color-shadow-medium);
$modal-background-blur: 10px;
$product-details-content-box-shadow: 0 0 50px var(--color-shadow-medium);
$filter-item-width: 280px;
$filter-item-width-media-m: 240px;
$filter-item-width-media-s: 200px;
$filter-price-input-width: 214px;
$field-box-shadow: inset 0px 0px 0px 1px var(--color-shadow-accent);
$field-name-active-text-shadow: 0px 0px 1px var(--color-shadow-accent);
$field-box-shadow-error: inset 0px 0px 0px 1px var(--color-shadow-negative);

// List Items
// Used for: Filter-, Detail-, Category-, Brand-, Checkbox-, RadioListItem
$list-item-height: 57px;
$list-item-line-height: 1.1; // Design decision to narrow space between label and description, but not overlap

// This has to be synced with the app config
$keyboard-delay-timeout: 0.15s;
$browser-navbar-height: 49px;
$browser-navbartop-height: 50px;

// This has to be synced with the handle size in electron
$keyboard-handle-button-height: $browser-navbar-height;
$keyboard-handle-button-width: 200px;
