.Toast {
  $toast-height: 80px;
  $text-max-width: 30em;
  $stacking: timeout, close;

  position: relative;
  display: inline-flex;
  align-items: center;
  height: $toast-height;
  padding-left: $space-xxl;
  border-left: $border-width-l solid currentColor;
  background: var(--primary-bg-main);
  box-shadow: 0 5px 20px var(--color-shadow-medium);

  &__icon {
    margin-right: $space-base;

    & > .Icon {
      display: block;
    }
  }

  &__text {
    line-height: $line-height-xs;
    max-width: $text-max-width;
  }

  &__title {
    @include scut-truncate;
    display: block;
    font-weight: $font-weight-bold;
  }

  &__description {
    @include scut-truncate;
    display: block;
    color: var(--primary-fg-support);
    font-size: $font-size-xs;
  }

  &__action {
    margin-left: $space-base;
  }

  &__close {
    position: relative;
    z-index: z($stacking, close);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: $space-xxl;
    outline: none;
    width: $toast-height;
    height: $toast-height;
    background-color: var(--color-util-transparent);
    color: var(--primary-fg-main);
    border-left: $border-width-base solid var(--primary-fg-support);
  }

  .Toast__timeout {
    position: absolute;
    right: 0;
    top: 0;
    width: $toast-height;
    height: $toast-height;
    background: var(--primary-bg-support);
    transform: scaleX(0);
    transform-origin: 0 0;
    transition-property: transform;
    transition-timing-function: linear;
    z-index: z($stacking, timeout);
  }

  &--kind {
    &-default {
      color: var(--primary-fg-main);
    }

    &-positive {
      color: var(--primary-fg-positive);
    }

    &-negative {
      color: var(--primary-fg-negative);
    }
  }

  &--permanent {
    padding-right: $space-xxl;
  }
}
