.MultiColumnItemList {
  $column-width: 300px;

  display: grid;
  grid-template-columns: repeat(auto-fit, $column-width);
  justify-content: center;

  &__column {
    // Add vertical spacing for the content of the columns
    & > * + * {
      margin-top: $space-l;
      margin-bottom: $space-xl;
    }
  }

  &--gap {
    &-base {
      gap: $space-base;
    }

    &-xxl {
      gap: $space-xxl;
    }
  }
}
