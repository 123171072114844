.TimeControlSliderHandle {
  $handle-size: $timecontrol-handle-size;
  $shadow-size: 20px;
  $time-label-offset: 5px; // prevents overlapping text when two handles are close

  position: relative;
  width: $handle-size;
  height: $handle-size;
  box-shadow: 0 0 $shadow-size var(--color-shadow-medium);
  border-radius: 50%;
  outline: none;
  background: var(--primary-bg-main);
  border: $timecontrol-handle-border-width solid var(--color-util-transparent);

  &__timeLabel {
    font-size: $font-size-xl;
    font-weight: $font-weight-regular;
    line-height: $line-height-xs;
    position: absolute;
    margin: 0;
    right: 100%;
    pointer-events: none;
    bottom: calc(100% + #{$time-label-offset});
  }

  &__popup {
    position: absolute;
  }

  &:active,
  &:focus {
    border: $timecontrol-handle-border-width solid var(--primary-fg-accent);
  }

  &--disabled {
    background: var(--primary-disabled-bg-minor);
  }
}
