.HighlightedFieldset {
  display: flex;

  &__fieldset {
    align-self: flex-start;
    flex-shrink: 0;
    width: 520px; // Design decision
    padding: $space-xl;
    border: $border-width-l solid var(--primary-fg-accent);
    border-radius: $border-radius-l;

    & > * + * {
      margin-top: $space-base;
    }
  }

  &__aside {
    display: flex;
    flex-direction: column;
    width: $field-width;
  }

  &__description {
    padding: $space-l;
  }
}
