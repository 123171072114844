.CallToActionLayout {
  $cta-spacing: $space-xxxl;
  $stacking: children, overlay;

  &__children {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: z($stacking, children);
  }

  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    appearance: none;
    outline: 0;
    // A transparent overlay over a webview will not be clickable on touch (for unknown reasons)
    background: var(--color-util-transparent-clickable);
    z-index: z($stacking, overlay);
  }

  &__text {
    position: absolute;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    padding: $space-s $space-xl;
    font-size: $font-size-xxxxxl;
    font-weight: $font-weight-bold;
    background: var(--primary-accent-bg-main);
    color: var(--primary-accent-fg-main);
  }

  &--topLeft &__text {
    top: $cta-spacing;
    left: $cta-spacing;
  }

  &--topRight &__text {
    top: $cta-spacing;
    right: $cta-spacing;
  }

  &--topCenter &__text {
    top: $cta-spacing;
  }

  &--bottomLeft &__text {
    bottom: $cta-spacing;
    left: $cta-spacing;
  }

  &--bottomRight &__text {
    bottom: $cta-spacing;
    right: $cta-spacing;
  }

  &--bottomCenter &__text {
    bottom: $cta-spacing;
  }
}
