.TabBarLink {
  $transition-time: 1.5s;
  $transition-ease: cubic-bezier(0.15, 0.75, 0.5, 1);

  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  text-decoration: none;
  outline: 0;
  height: $tab-bar-height-xs;
  padding: 0 $space-s;
  justify-content: center;
  font-size: $font-size-xs;

  @include media(m) {
    font-size: $font-size-s;
    height: $tab-bar-height-s;
  }

  @include media(l) {
    font-size: $font-size-base;
    height: $tab-bar-height;
  }

  &__label {
    @include scut-truncate();
  }

  &--fadeIn {
    .TabBarLink__label,
    & > .Icon {
      animation: TabBarLink-fadein $transition-time $transition-ease forwards;
    }
  }

  & > .Icon {
    margin-right: 0.5em;
    flex-shrink: 0;
  }

  &--variant {
    &-standout,
    &-accent,
    &-primary {
      &.TabBarLink--active {
        font-weight: $font-weight-bold;
      }

      &.TabBarLink--disabled {
        pointer-events: none;
      }
    }

    &-standout {
      background: var(--standout-bg-main);
      color: var(--standout-fg-main);

      & > svg {
        fill: var(--standout-fg-accent);
      }

      &.TabBarLink--disabled {
        color: var(--standout-disabled-fg-main);
      }
    }

    &-accent {
      background: var(--accent-bg-main);
      color: var(--accent-fg-main);

      & > svg {
        fill: var(--accent-fg-main);
      }

      &.TabBarLink--disabled {
        color: var(--accent-fg-disabled);
      }
    }

    &-primary {
      background: var(--primary-bg-main);
      color: var(--primary-fg-main);

      & > svg {
        fill: var(--primary-fg-main);
      }

      &.TabBarLink--active {
        font-weight: $font-weight-bold;
      }

      &.TabBarLink--disabled {
        color: var(--primary-disabled-fg-main);
      }
    }
  }

  &--badge {
    position: relative;
    // Roughly the space a small badge needs
    padding-left: 4em;
    padding-right: 1em;

    & > .Badge {
      position: absolute;
      left: $space-s;
      top: 50%;
      transform: translateY(-50%);
      flex-shrink: 0;
    }
  }
}

@keyframes TabBarLink-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
