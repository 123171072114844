.BulletList {
  padding: 0;
  margin: 0;
  list-style: none;
  color: var(--primary-fg-main);

  &__item {
    position: relative;
    padding-left: 1.8em;

    &::before {
      content: "";
      position: absolute;
      top: 0.5em * $line-height-base;
      left: 0;
      height: 0;
      width: 1em;
      border-top: solid 1px var(--primary-fg-main);
    }
  }

  &--font-size {
    &-base {
      font-size: $font-size-base;
    }

    &-xl {
      font-size: $font-size-xl;
    }
  }
}
