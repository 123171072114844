.LanConnectionSearch {
  $image-max-width: 1024px;

  position: relative;

  &__figure {
    position: absolute;
    display: block;
    width: 100%;
    max-width: $image-max-width;
    top: -1 * $space-xxxxl;
    left: 0;
    margin: 0 $space-xl;
  }

  &__image {
    object-fit: contain;
  }
}
