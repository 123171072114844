.InformationPageLayout {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: $space-l;
    height: $content-header-height;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__headerLeft {
    display: flex;
    align-items: center;
    padding-left: $space-l;

    & > .IconLink {
      height: 100%;
      margin-left: -1 * $space-l;
      padding: 0 $space-l;
    }
  }

  &__headerRight {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    padding-right: $page-layout-padding;

    & > .Logo {
      position: relative;
      right: -1 * $page-layout-padding;
      align-self: flex-start;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    padding: 0 $page-layout-padding $page-layout-padding $page-layout-padding;
  }

  &--variant {
    &-primary {
      background: var(--primary-bg-main);
      color: var(--primary-fg-main);
    }

    &-standout {
      background: var(--standout-bg-main);
      color: var(--standout-fg-main);
    }
  }
}
