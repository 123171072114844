.TileGrid {
  // Important: This sizes need to be manually synchronized with the constants in the `ProductFinder.tsx`
  $grid-gutter: $space-xxxs;
  $tile-width-base: 370px;
  $tile-width-l: 420px; // used for 2 column grids, we want a 16:9 grid look, thus items need to be wider
  $tile-width-xl: 494px;
  $tile-width-xxl: 742px;

  @function calc-grid-width($width-base: $tile-width-base, $column-count: 1, $scale-factor: 1) {
    $gap-count: if($column-count == 1, 0, $column-count - 1);
    @return $scale-factor * $column-count * $width-base + $gap-count * $grid-gutter;
  }

  /*
   * $grid-config is a collection in which corresponding grid sizes are assigned to each column count!
   * The grid-width of each grid size also depends on column count
   */
  $grid-config: (
    (
      "4": (
        "default": calc-grid-width($tile-width-base, 4),
        "s": calc-grid-width($tile-width-base, 4)
      ),
      "3": (
        "default": calc-grid-width($tile-width-base, 3),
        "s": calc-grid-width($tile-width-base, 3),
        "l": calc-grid-width($tile-width-xl, 3)
      ),
      "2": (
        "default": calc-grid-width($tile-width-l, 2),
        "s": calc-grid-width($tile-width-base, 2),
        "l": calc-grid-width($tile-width-xxl, 2)
      ),
      "1": (
        "default": calc-grid-width($tile-width-l, 1, 1.4),
        "s": calc-grid-width($tile-width-base, 1),
        "l": calc-grid-width($tile-width-xxl, 1)
      )
    )
  );

  position: relative;
  display: grid;
  width: 100%; // Necessary when used inside flexbox
  grid-auto-rows: max-content;

  &__header {
    grid-column: 1 / grid-end;
    margin-bottom: $space-l;

    & > .Headline {
      width: 85%; // Design decision
    }
  }

  @each $column, $grid-widths in $grid-config {
    &--columns-#{$column} {
      @each $size, $grid-width in $grid-widths {
        &-#{$size} {
          grid-template-columns: repeat(#{$column}, 1fr) [grid-end];
          width: $grid-width;
        }
      }
    }
  }

  &--gap {
    &-default {
      grid-gap: $grid-gutter;
    }

    &-l {
      grid-gap: $space-xs;
    }
  }

  &--fill {
    width: auto; // override any fixed width
    max-width: none;
  }

  &--shadow {
    &:after {
      content: "";
      display: block;
      margin-top: $space-l;
      transform: scale(1.3); // Design decision: shadow must horizontally break out
      width: 100%;
      height: 135px; // Design decision
      grid-column: 1 / grid-end;
      background: url("../../resources/images/shadow-long-realistic.png") center center no-repeat;
      background-size: 100% 100%;
    }
  }

  /** @see [TileGrid: Shadow verschiebt die Kacheln vertical : BCD-6603](https://dcd.myjetbrains.com/youtrack/issue/BCD-6603/TileGrid-Shadow-verschiebt-die-Kacheln-vertical) */
  &--hideShadow {
    &:after {
      opacity: 0;
    }
  }

  // Only span shadow over columns which are filled with tiles

  &--columns-used-1 {
    &:after {
      grid-column: 1;
    }
  }

  &--columns-used-2 {
    &:after {
      grid-column: 1 / span 2;
    }
  }

  &--columns-used-3 {
    &:after {
      grid-column: 1 / span 3;
    }
  }

  &--columns-used-4 {
    &:after {
      grid-column: 1 / span 4;
    }
  }

  &--stickyHeader {
    .TileGrid__header {
      position: sticky;
      top: 0;
      z-index: 1;
      background: var(--primary-bg-main);
      padding: $space-s;
      text-align: center;
      margin-bottom: 0;

      & > .Headline {
        width: 100%;
      }

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 30px;
        background: radial-gradient(farthest-side at center top, var(--primary-bg-gradient-support-to-transparent));
      }
    }
  }
}
