.LabeledToggleField {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__label {
    font-size: $font-size-xs;

    &:first-child {
      padding-right: $space-base;
      font-weight: $font-weight-bold;
    }

    &:last-child {
      padding-left: $space-base;
    }
  }

  &--checked > &__label {
    &:first-child {
      font-weight: $font-weight-regular;
    }

    &:last-child {
      font-weight: $font-weight-bold;
    }
  }
}
