.BrandsSlide {
  $stacking: background, content, brands, copy;
  $button-size: 200px;
  $brand-size: 280px;
  $headline-size: scut-rem(100) !default;
  $transition-time: 2s;
  $transition-ease: cubic-bezier(0.15, 0.75, 0.5, 1);

  position: relative;
  height: 100%;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z($stacking, background);

    & > .Video {
      opacity: 0.9;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: z($stacking, content);
  }

  &__copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-grow: 1;
    color: var(--primary-contrast-fg-main);
    z-index: z($stacking, copy);
  }

  &__topline {
    margin-left: -33%;
    font-size: $font-size-xxxxl;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-wide;
    text-transform: uppercase;
    opacity: 0;
  }

  &__headline {
    font-size: $headline-size;
    font-weight: $font-weight-bold;
    line-height: $line-height-xs;
    opacity: 0;
  }

  &__button {
    position: absolute;
    bottom: calc(-1 * $button-size / 2);
    right: $brand-size;
    height: $button-size;
    width: $button-size;
    background: var(--primary-accent-bg-main);
    border-radius: 50%;
    outline: 0;
    transform: scale(0);
    transition: background $transition-fast;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: $button-size;
      width: $button-size;
      border-radius: 50%;
      background: var(--primary-accent-bg-main-alpha-medium);
    }

    & > .Icon {
      position: relative;
      font-size: calc($button-size / 2);
    }
  }

  &__brands {
    position: relative;
    padding: $space-xxl 0;
    background: var(--primary-bg-main); // Can't use a transparent color because brand logos are on white.
    white-space: nowrap;
    z-index: z($stacking, brands);
    overflow: hidden;
    opacity: 0.9;
    text-align: center;
    opacity: 0.9;
  }

  &__brandsSlider {
    display: inline-block;
  }

  &__brand {
    display: inline-block;
    margin: 0 $space-xxl;
    width: $brand-size;
    height: calc($brand-size / 2);
  }

  &--visible {
    .BrandsSlide__topline {
      animation: BrandsSlide-fadein $transition-time $transition-ease forwards;
    }

    .BrandsSlide__headline {
      animation: BrandsSlide-fadein $transition-time calc($transition-time / 5) $transition-ease forwards;
    }

    .BrandsSlide__button {
      animation: BrandsSlide-scalein $transition-slow $transition-time $transition-ease forwards;

      &::before {
        animation: BrandsSlide-buzz 1.5s ($transition-slow + $transition-time) $transition-ease infinite;
      }
    }
  }

  &--animateSlider {
    .BrandsSlide__brands {
      text-align: initial;
    }

    .BrandsSlide__brandsSlider:first-child {
      position: absolute;
    }
  }

  &--visible#{&}--animateSlider &__brandsSlider {
    animation: BrandsSlide-slider linear infinite;
  }

  @keyframes BrandsSlide-fadein {
    0% {
      transform: translateX(-50%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes BrandsSlide-scalein {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes BrandsSlide-slider {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes BrandsSlide-buzz {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1.5);
    }
  }
}
