.CardLayout {
  display: flex;

  & > * {
    flex: 0 1 480px; // Design decision
  }

  & > * + * {
    margin-left: $space-xxl;
  }
}
