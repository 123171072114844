.ProductTitle {
  @include typography-headline-m;

  $icon-size: 5px;

  margin: 0;

  &__inner {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__icon {
    display: inline-block;
    width: $icon-size;
    height: $icon-size;
    background: currentColor;
    border-radius: 50%;
    margin: auto $space-xs;
    flex-shrink: 0;
  }

  &__year {
    font-weight: $font-weight-regular;
  }

  &__price {
    font-size: 0.75em; // Dependent on TextFit
    font-weight: $font-weight-regular;
    padding-left: $space-xs;
    white-space: nowrap;

    @include media(m) {
      padding-left: $space-base;
    }

    @include media(l) {
      padding-left: $space-xl;
    }
  }

  &__originalPrice {
    font-size: 0.55em; // Dependent on TextFit
    text-decoration: line-through;
    font-weight: $font-weight-regular;
    margin-left: $space-xs;
    color: var(--primary-fg-support);
  }

  &__badge {
    display: flex;
    align-items: center;
    margin-left: $space-xs;
    font-size: 0.6em; // Dependent on TextFit

    @include media(l) {
      font-size: $font-size-xs;
    }
  }

  &--align {
    &-center {
      .ProductTitle__inner {
        justify-content: center;
      }
    }

    &-left {
      .ProductTitle__inner {
        justify-content: flex-start;
      }

      .ProductTitle__price {
        padding-left: 0;
      }
    }
  }
}
