.UpdaterLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  padding: $page-layout-padding;
  text-align: center;
  background: linear-gradient(to top right, var(--primary-bg-gradient-minor-to-support));

  &__footer {
    position: absolute;
    bottom: $page-layout-padding;
  }
}
