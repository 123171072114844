.FilterModalBrandsGrid {
  &__description {
    @include typography-headline-minor;

    color: var(--primary-fg-support);
    letter-spacing: $letter-spacing-wide;
  }

  &__brands {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: $space-l;
  }
}
