.PrinterStatus {
  &__label {
    @include typography-headline-minor;

    color: var(--primary-fg-support);
    margin-bottom: $space-xxs;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > .Icon {
      margin-right: $space-s;
    }
  }

  &__printerName {
    @include typography-headline-m;
    font-weight: $font-weight-thin;
    max-width: 50%;
  }

  &__children {
    display: flex;
    margin-left: $space-l;
  }
}
