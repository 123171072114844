.BrandTile {
  $ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--primary-bg-main);
  color: var(--primary-fg-main);

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    text-decoration: none;
    padding: 0 $space-base;
    font-size: $font-size-xl;
    outline: 0;
    overflow: hidden;
  }

  &__image {
    height: $icon-size-xl;
    width: $brand-logo-width;
    padding: $space-l 0;
    transition: transform $transition-base $ease-out-quart;
  }

  &__closeButton {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__action {
    position: absolute;
    bottom: 0;
    right: 0;
    color: var(--primary-fg-support);
  }

  &__year {
    // To keep height of &__year even when it's empty and
    // cause no change in the position of the image.
    min-height: $font-size-xl * $line-height-base;
  }

  &__doubleClickInfo {
    position: absolute;
    bottom: $space-base;
    animation: brand-tile-shift-in $transition-base $ease-out-quart forwards;
  }

  &--height {
    &-default {
      height: $tile-height;
    }

    &-l {
      height: $tile-height-l;
    }

    &-xl {
      height: $tile-height-xl;
    }
  }

  &__badge {
    position: absolute;
    top: $space-s;
    left: $space-s;
  }

  &--doubleClickInfo &__image {
    transform: scale(0.8);
  }

  &--touched:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: " ";
    pointer-events: none;
    background: var(--primary-active-bg-main);
    mix-blend-mode: multiply;
  }

  &--isBackside {
    background-color: var(--primary-bg-minor);
  }
}

@keyframes brand-tile-shift-in {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
