.PrintTipsButton {
  $height: 470px; // Defined by design

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  height: $height;
  padding-bottom: 0.25 * $height;
  background: url("../../resources/images/print-tips.svg") center center no-repeat;
  background-size: contain;
}
