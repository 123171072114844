@mixin absolute-overlay($z-index, $ignorePointerEvents: false) {
  position: absolute;
  z-index: $z-index;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @if $ignorePointerEvents {
    pointer-events: none;
  }

  @content;
}
