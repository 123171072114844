@use "sass:math";

.TimeControlSlider {
  $stacking-context: rail, track, handle;
  $slider-width: 2px;

  position: relative;
  height: 100%;

  .rc-slider {
    position: relative;
    height: 100%;
    width: $slider-width;
    touch-action: none;

    &-rail {
      position: absolute;
      height: 100%;
      width: $slider-width;
      background-color: var(--primary-accent-bg-main-alpha-light);
      touch-action: pan-x;
      z-index: z($stacking-context, rail);
    }

    &-track {
      position: absolute;
      height: 100%;
      width: 100%;
      background: var(--primary-accent-bg-main);
      z-index: z($stacking-context, track);
    }

    &-handle {
      position: absolute;
      touch-action: pan-y;
      z-index: z($stacking-context, handle);

      & > * {
        transform: translate(calc(-50% + #{math.div($slider-width, 2)}));
      }
    }
  }

  &--disabled {
    .rc-slider {
      &-rail {
        background-color: var(--primary-disabled-bg-support);
      }

      &-track {
        background: var(--primary-disabled-bg-minor);
      }
    }
  }
}
