.ConnectionButton {
  display: inline-block;
  text-align: center;
  cursor: pointer;

  & > .Icon {
    display: block;
    margin: 0 auto $space-xs;
  }
}
