/* Bold */
@font-face {
  font-family: "DIN 2014";
  src: url("../../resources/fonts/DIN 2014/dpt75-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

/* Demi */
@font-face {
  font-family: "DIN 2014";
  src: url("../../resources/fonts/DIN 2014/dpt65-webfont.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

/* Regular */
@font-face {
  font-family: "DIN 2014";
  src: url("../../resources/fonts/DIN 2014/dpt55-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

/* Light */
@font-face {
  font-family: "DIN 2014";
  src: url("../../resources/fonts/DIN 2014/dpt45-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu Mono";
  src: url("../../resources/fonts/UbuntuMono/UbuntuMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
