.ProgressStatus {
  // Roughly chosen by design in a way that the position of the loading indicator
  // does not change when the label changes
  $label-width: 15em;

  display: flex;

  &__label {
    @include typography-headline-base;

    width: $label-width;
    margin-right: $space-l;
  }
}
