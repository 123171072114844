.Table {
  position: relative;
  padding: 0;
  line-height: 1; // Equalize vertical padding

  &__table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
  }

  &__headerRow {
    & > * {
      position: sticky;
      top: 0;
      z-index: 1; // Needs z-index to prevent overlapping issues
    }
  }

  &__body::before {
    content: "";
    display: block;
    width: 100%;
    height: $space-base; // Extra spacing from header to first row
  }

  &--noStickyHeader {
    .Table__headerRow {
      & > * {
        position: relative;
        top: initial;
        z-index: initial;
      }
    }
  }

  &--dynamicSize {
    .Table__table {
      table-layout: auto;
      width: auto;
      max-width: 100%;
    }
  }

  &--inContent {
    background: var(--primary-bg-main);
  }
}
