.Image {
  $cross-rotation: 45deg;

  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden; // Keep layout even if image does not have the correct size

  &__img {
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity $transition-fast ease-in;
    object-fit: scale-down;
  }

  &--loaded {
    .Image__img {
      opacity: 1;
    }
  }

  &--position {
    &-left .Image__img {
      object-position: left center;
    }

    &-center .Image__img {
      object-position: center center;
    }

    &-right .Image__img {
      object-position: center right;
    }
  }

  &--cover .Image__img {
    object-fit: cover;
  }

  &--fill {
    height: 100%;
  }

  &--originalSize {
    &,
    .Image__img {
      position: relative;
      width: auto;
      height: auto;
    }
  }

  &--error {
    background-color: var(--primary-disabled-bg-main);

    .Image__img {
      display: none; // Don't show the default ugly "broken image" icon of the browser
    }

    &:before,
    &:after {
      content: " ";
      background-color: var(--primary-disabled-fg-main);
      position: absolute;
      height: 100%;
      width: $border-width-l;
      top: 0;
      left: 50%;
    }

    &:before {
      transform: rotate($cross-rotation);
    }

    &:after {
      transform: rotate(-$cross-rotation);
    }
  }

  // Some images are missing a transparent background image
  &--multiplyBlendMode .Image__img {
    mix-blend-mode: multiply;
  }
}
