.Hotline {
  $box-spacing-y: 1em;
  $box-spacing-x: $space-l;

  display: inline-block;
  padding: $box-spacing-y 0 0;
  border-radius: $border-radius-base;
  text-align: center;
  background: var(--standout-bg-main);
  color: var(--standout-fg-main);
  border: $border-width-base solid var(--standout-fg-main);

  &__title,
  &__mail {
    color: var(--standout-fg-support);
  }

  &__title {
    padding: 0 $box-spacing-x;
  }

  &__phone {
    padding: 0 $box-spacing-x;
    font-weight: $font-weight-bold;
    color: var(--standout-fg-accent);
  }

  &__label {
    margin-top: $box-spacing-y;
    border-radius: 0 0 $border-radius-xs $border-radius-xs;
    background: var(--standout-contrast-bg-main);
    color: var(--standout-contrast-fg-main);
  }

  &--size {
    &-default {
      .Hotline__title {
        font-size: $font-size-xl;
      }

      .Hotline__phone {
        font-size: $font-size-xxxl;
      }

      .Hotline__label {
        font-size: $font-size-base;
        line-height: $font-size-xxxxl;
      }

      .Hotline__label {
        font-size: $font-size-base;
      }
    }

    &-s {
      .Hotline__title {
        font-size: $font-size-l;
      }

      .Hotline__phone {
        font-size: $font-size-xl;
      }

      .Hotline__label {
        font-size: $font-size-base;
        line-height: $font-size-xxxl;
      }

      .Hotline__label {
        font-size: $font-size-s;
      }
    }
  }

  &--block {
    display: block;
  }
}
