.CategoryListItem {
  @include list-item;

  &__icon {
    @include checkbox-base;
  }

  &--active {
    font-weight: $font-weight-medium;
    background: var(--primary-accent-bg-main);
    color: var(--primary-accent-fg-main);

    .CategoryListItem__icon {
      background: var(--primary-bg-main);
      color: var(--primary-fg-accent);
      border-color: var(--color-util-transparent);
    }
  }
}
