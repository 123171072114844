@mixin typography-headline-base {
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-thin;
  line-height: $line-height-s;
}

@mixin typography-headline-l {
  font-size: $font-size-xxxxl;
  font-weight: $font-weight-bold;
  line-height: $line-height-s;
}

@mixin typography-headline-m {
  font-size: $font-size-xxxl;
  font-weight: $font-weight-bold;
  line-height: $line-height-s;
}

@mixin typography-headline-s {
  font-size: $font-size-xl;
  font-weight: $font-weight-regular;
  letter-spacing: $letter-spacing-thin;
  line-height: $line-height-s;
}

@mixin typography-headline-xs {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}

@mixin typography-headline-hero {
  font-size: $font-size-xxxxxl;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-thin;
  line-height: $line-height-s;
}

@mixin typography-headline-minor {
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  line-height: $line-height-base;
}

@mixin typography-subheadline {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-wide;
  text-transform: uppercase;
}

@mixin typography-paragraph-base {
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  line-height: $line-height-base;
}
