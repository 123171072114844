.ItemListGroup {
  $label-width: 60px;

  display: flex;

  & > .Headline {
    width: $label-width;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__list {
    @include scut-clearfix;

    & {
      flex-grow: 1;
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  &__item {
    float: left;
    border-bottom: 1px solid var(--primary-fg-divider);
    border-right: 1px solid var(--primary-fg-divider);
    width: 33.33%;
  }
}
