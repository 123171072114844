@use "sass:math";

.DescriptionListCard {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  width: 100%;
  margin: 0;
  padding: $space-s $space-base;
  font-size: $font-size-s;
  border-radius: $border-radius-xxl;
  background: var(--primary-bg-main);
  color: var(--primary-fg-main);

  & > * {
    padding: math.div($space-xs, 2) 0;
    line-height: $line-height-l;
  }

  & > :nth-child(2n) {
    margin-left: 0;
    padding-left: $space-base;
    font-weight: $font-weight-medium;
    word-wrap: break-word;
    text-align: right;
  }

  & > :nth-child(n + 3) {
    border-top: $border-width-base solid var(--primary-fg-divider);
  }

  &--noCard {
    padding: 0;
    border-radius: 0;
    background: var(--color-util-transparent);
  }
}
