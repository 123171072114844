.Markdown {
  color: var(--primary-fg-main);
  font-size: $font-size-base;
  font-weight: $font-weight-regular;

  & > * + * {
    margin-top: $space-base;
  }

  a {
    text-decoration: none;
    font-weight: $font-weight-medium;
    color: var(--primary-fg-accent);
  }

  p {
    @include typography-paragraph-base;
    margin: 0;
    margin-top: $space-base;
  }

  h1,
  h2,
  h3,
  h4 {
    @extend .Headline;
  }

  & > * + h1,
  & > * + h2,
  & > * + h3,
  & > * + h4 {
    margin-top: 1em;
  }

  h1 {
    @include typography-headline-l;
  }

  h2 {
    @include typography-headline-m;
  }

  h3 {
    @include typography-headline-base;
  }

  h4 {
    @include typography-headline-s;
  }

  strong {
    font-weight: $font-weight-bold;
  }

  hr {
    // border: 0;
    border-bottom: var(--primary-fg-minor);
  }
}
