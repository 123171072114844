.CircularButton {
  $size-default: $space-xxl;
  $size-small: $space-xl;
  $circle-box-shadow: 0 0 20px var(--color-shadow-light);

  display: inline-block;
  outline: none;

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    outline: none;
    border-radius: 50%;
    overflow: hidden;
    border: $border-width-base solid;
  }

  &__label {
    display: block;
    margin-top: $space-s;
    text-align: center;
    text-transform: uppercase;
    font-size: $font-size-xs;
  }

  &--elevated &__circle {
    box-shadow: $circle-box-shadow;
  }

  &--variant {
    &-primary {
      .CircularButton__label {
        color: var(--primary-fg-minor);
      }

      .CircularButton__circle {
        background: var(--primary-bg-main);
        color: var(--primary-fg-main);
        border-color: var(--color-util-transparent);
      }

      &:disabled {
        .CircularButton__circle {
          background: var(--primary-disabled-bg-main);
          color: var(--primary-disabled-fg-main);
        }
      }
    }

    &-standout {
      .CircularButton__label {
        color: var(--standout-fg-minor);
      }

      .CircularButton__circle {
        color: var(--standout-fg-main);
        border-color: var(--standout-fg-main);
      }

      &:disabled {
        .CircularButton__circle {
          color: var(--standout-disabled-fg-main);
          border-color: var(--standout-disabled-fg-main);
        }
      }
    }

    &-accent {
      .CircularButton__label {
        color: var(--accent-fg-minor);
      }

      .CircularButton__circle {
        background: var(--accent-bg-main);
        color: var(--accent-fg-main);
        border-color: var(--color-util-transparent);
      }

      &:disabled {
        .CircularButton__circle {
          background: var(--accent-disabled-bg-main);
          color: var(--accent-disabled-fg-main);
        }
      }
    }
  }

  &--size {
    &-default {
      .CircularButton__circle {
        font-size: $keyboard-button-font-size-base;
        width: $size-default;
        height: $size-default;
      }
    }

    &-s {
      .CircularButton__circle {
        height: $size-small;
        width: $size-small;
        font-size: $font-size-base;

        .Icon {
          font-size: $font-size-base;
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}
