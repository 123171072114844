.NextCustomerWorldHomeButton {
  $clickable-size: 120px; // design decision
  $child-offset: 30px;

  width: $clickable-size;
  height: $clickable-size;

  background-image: radial-gradient(var(--standout-bg-main-alpha-medium), var(--color-util-transparent) 60%);
  background-repeat: no-repeat;
  background-position: calc(-1 * #{$clickable-size}) calc(-1 * #{$clickable-size});
  background-size: calc(2 * #{$clickable-size}) calc(2 * #{$clickable-size});

  cursor: pointer;

  &__children {
    color: var(--standout-fg-main);

    transform: translate($child-offset, $child-offset);
  }
}
