.PriceFilterLayout {
  $input-width: $filter-price-input-width;

  display: grid;
  grid-template-areas:
    "from divider to clear"
    "range range range .";
  column-gap: $space-xxs;
  row-gap: $space-xxl;
  align-items: center;

  grid-template-columns: $input-width min-content $input-width $space-xxl;

  &__from {
    grid-area: from;
  }

  &__to {
    grid-area: to;
  }

  &__divider {
    grid-area: divider;
    display: flex;
    justify-content: center;
    padding: 0 15px;
  }

  &__divider hr {
    height: 1px;
    width: $space-s;
    background-color: var(--primary-fg-divider);
    border: none;
  }

  &__range {
    grid-area: range;
  }

  &__clear {
    grid-area: clear;
  }
}
