.ErrorLog {
  max-width: 100%;
  color: var(--primary-fg-main);

  &__message {
    @include typography-paragraph-base;
    font-family: $font-family-mono;
    font-style: initial;
    margin: 0;
    word-wrap: break-word;
  }

  &__message + &__message {
    margin-top: $space-base;
  }
}
