.ConnectionSignal {
  $max-signal: 5;
  $bar-size: 4px;
  $error-size: 20px;
  $margin-size: calc($bar-size / 2);

  position: relative;
  display: inline-block;
  width: $max-signal * $bar-size + ($max-signal - 1) * $margin-size;
  flex-shrink: 0;

  &__bar {
    display: inline-block;
    width: $bar-size;
    vertical-align: bottom;
    background: var(--primary-feedback-bg-positive);

    & + & {
      margin-left: $margin-size;
    }
  }

  @for $i from 1 through $max-signal {
    &__bar:nth-of-type(#{$i}) {
      height: $i * $bar-size;
    }
  }

  &__error {
    position: absolute;
    left: 3 * $bar-size;
    display: inline-block;
    width: $error-size;
    height: $error-size;
    line-height: $error-size - 2 * $border-width-base;
    font-size: $font-size-xs;
    border-radius: 50%;
    border: $border-width-base solid;
    text-align: center;
    font-weight: $font-weight-bold;
    background: var(--primary-feedback-bg-negative);
    color: var(--primary-feedback-fg-main);
    border-color: var(--primary-fg-negative);
  }
}
