.Ripple {
  $animation-duration: 0.35s;
  $ripple-size-base: 15px;

  // Special case where the component is allowed to be fixed and globally stacked
  position: fixed;
  z-index: z($stacking-global, cursor-highlight);
  width: $ripple-size-base;
  height: $ripple-size-base;
  background: var(--accent-bg-main);
  border-radius: 50%;
  border: $border-width-base solid var(--accent-bg-main-alpha-medium);
  pointer-events: none;
  transform: translate(-50%, -50%);
  animation: Ripple-effect $animation-duration ease-out forwards;
}

@keyframes Ripple-effect {
  to {
    transform: translate(-50%, -50%) scale(10);
    opacity: 0.01;
  }
}
