.TextareaField {
  display: block;
  width: 100%;
  resize: none;
  padding: $space-s;
  border: $border-width-base solid var(--primary-fg-minor);
  border-radius: $border-radius-base;
  background: var(--primary-bg-main);
  font-size: $font-size-base;
  outline: none;

  &:focus {
    border-color: var(--primary-fg-accent);
    // Use additional box-shadow to avoid size-changes
    box-shadow: $field-box-shadow;
  }

  &--error {
    border-color: var(--primary-fg-negative);
    box-shadow: $field-box-shadow-error;
  }

  &--disabled {
    color: var(--primary-disabled-fg-main);
    border: $border-width-base solid var(--primary-disabled-bg-main);
  }
}
