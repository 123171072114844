@use "sass:math";

.ProductDetailsAdvertisement {
  $logo-height-s: 40px;
  $logo-height-m: 50px;
  $logo-height-l: 60px;
  $padding-vertical: $space-l;

  position: relative;
  display: flex;
  flex-direction: column;
  gap: $space-base;
  padding: $space-xl $padding-vertical $space-l;
  width: 100%;

  background: var(--primary-bg-main);
  border-radius: $border-radius-xl;
  box-shadow: $product-details-content-box-shadow;

  &__text {
    margin: 0;
    line-height: $line-height-s;
  }

  &__logo {
    position: absolute;
    left: $padding-vertical;
    max-width: 75%;
  }

  &--logoSize {
    &-s .ProductDetailsAdvertisement__logo {
      top: math.div($logo-height-s, -2.5);
      height: $logo-height-s;
    }

    &-m .ProductDetailsAdvertisement__logo {
      top: math.div($logo-height-m, -2);
      height: $logo-height-m;
    }

    &-l .ProductDetailsAdvertisement__logo {
      top: math.div($logo-height-l, -1.5);
      height: $logo-height-l;
    }
  }
}
