.CenteredContent {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__footerLeft {
    width: 50%;
    bottom: $space-l;
    left: $space-l;
    position: absolute;

    > * {
      display: block;
      text-align: left;
    }
  }

  &__footerRight {
    width: 50%;
    bottom: $space-l;
    right: $space-l;
    position: absolute;

    > * {
      display: block;
      text-align: right;
    }
  }

  &--children-width {
    &-auto {
      .CenteredContent__children {
        width: auto;
      }
    }

    &-full {
      .CenteredContent__children {
        width: 100%;
      }
    }
  }
}
