.ProgressButton {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: 0;

  & > :first-child {
    pointer-events: none;
    animation-name: progress-button-reverse;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-delay: 0.1s; // to prevent a transparent line between the clipped elements
  }

  & > :last-child {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: progress-button;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  &:active > :first-child,
  &--touched > :first-child {
    border: 0;
    background: var(--primary-accent-bg-main);
    color: var(--primary-accent-fg-main);
  }
}

@keyframes progress-button-reverse {
  0% {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
  100% {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  }
}

@keyframes progress-button {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
}
