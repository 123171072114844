.ProductFinderPageLayout {
  $sidebar-width: 300px;
  $sidebar-width-s: 240px;
  $max-content-width: 1120px;
  $stacking: content, note, aside, header;

  position: relative;
  background: linear-gradient(to right top, var(--primary-bg-gradient-minor-to-support));
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    height: $content-header-height;
    pointer-events: none;
    z-index: z($stacking, header);
    mix-blend-mode: multiply; // TODO: Temporary hack. Removes white background of brand images but should be removed in favor of transparent brand images.
  }

  &__headerLeft,
  &__headerRight {
    // Disabling the pointer events and enabling them again for the children gives us a more generous click area for the scroll gesture.
    // At the same time we don't loose any of the click functionality of buttons
    pointer-events: auto;
  }

  &__headerLeft {
    display: flex;
    align-items: center;
    padding-left: $space-l;
  }

  &__headerRight {
    padding-right: $page-layout-padding;

    // Special case for breadcrumb, logo
    & > .CurrentPageBreadcrumb,
    & > .Logo {
      position: relative;
      right: -1 * $page-layout-padding;
      align-self: flex-start;
    }
  }

  &__scrollCanvas {
    position: absolute;
    z-index: z($stacking, content);
    top: $header-height-small;
    padding: 0 $space-l;
    right: 0;
    left: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;

    @include media(l) {
      padding: 0 $space-xxxl;
    }

    // Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: $space-xxl;
    min-height: calc(100vh - #{$header-height-small}); // Browser fallback
    min-height: calc(var(--vh, 1vh) * 100 - #{$header-height-small});

    // Special case for centered content
    & > .CenteredContent {
      flex-grow: 1;
    }

    & > * + * {
      margin-top: $space-l;

      @include media(l) {
        margin-top: $space-xxl;
      }
    }
  }

  &__aside {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: z($stacking, aside);
    top: $header-height-small;
    right: 0;
    bottom: 0;
    width: $sidebar-width-s;

    @include media(l) {
      width: $sidebar-width;
    }
  }

  &--aside {
    .ProductFinderPageLayout__scrollCanvas {
      right: $sidebar-width-s;

      @include media(l) {
        right: $sidebar-width;
      }
    }
  }
}
