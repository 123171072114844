.StickyHeaderLayout {
  $stacking: children, header;

  position: relative;

  &__header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: z($stacking, header);
    margin-bottom: $space-xxl;
    padding: $space-xxs;
  }

  &__children {
    position: relative;
    z-index: z($stacking, children);
  }

  &--isSticky &__header {
    background: var(--primary-bg-main);

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 30px;
      background: radial-gradient(farthest-side at center top, var(--primary-bg-gradient-support-to-transparent));
    }
  }
}
