.CheckboxListItem {
  @include list-item;

  &__icon {
    @include checkbox-base;
  }

  &--active {
    font-weight: $font-weight-medium;

    .CheckboxListItem__icon {
      background: var(--primary-accent-bg-main);
      border-color: var(--primary-fg-accent);
      color: var(--primary-accent-fg-main);
    }
  }

  &--outline {
    border: $border-width-base solid var(--primary-fg-minor);
  }
}
