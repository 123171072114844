.ConnectionStatusList {
  $connection-status-width: 800px;
  $connection-status-height: 440px;

  width: $connection-status-width;
  padding-right: $space-xxl;

  &__body {
    @include scrollbar {
      max-height: $connection-status-height;
      width: 100%;
      padding-right: $space-xxl;
      overflow: auto;
    }
  }
}
