.TableHeaderCell {
  // The `width` is calculated by the component.
  padding: 0 $space-l;
  font-weight: $font-weight-bold;
  background: var(--primary-bg-main);
  font-size: $font-size-s;

  @include media(l) {
    font-size: $font-size-base;
  }

  &--text-align {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }
  }

  &--inContent {
    padding: $space-xs $space-l;

    @include media(l) {
      padding: $space-s $space-l;
    }

    @include media(xl) {
      padding: $space-base $space-l;
    }
  }

  &--noStickyHeader {
    background: var(--color-util-transparent);
  }
}
