.ProductTileSlider {
  $product-tile-width: 280px;

  overflow-x: auto;
  display: flex;
  padding: $space-base $space-l;

  > * {
    margin-right: $space-base;
    min-width: $product-tile-width;
    width: $product-tile-width;

    &:last-child {
      margin-right: 0;
    }
  }
}
