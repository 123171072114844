.ToggleFormLayout {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row-gap: $space-l;
  align-items: center;

  &__label {
    margin-right: $space-l;
  }
}
