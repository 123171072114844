.ColorCheckCircle {
  $indicator-size: 50px;

  width: $indicator-size;
  height: $indicator-size;
  border-radius: 50%;
  display: inline-block;
  margin: $space-xs;
  border: $border-width-l solid;

  &__icon {
    position: relative;
    bottom: 1px; // visually center icon
  }
}
