.ConnectionStatusListHeader {
  @include typography-headline-minor;

  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: $space-xxl;

  &__signal,
  &__name {
    color: var(--primary-fg-support);
  }

  &__signal {
    flex-basis: 15%;
  }

  &__name {
    flex-grow: 1;
  }

  &__loader {
    margin-right: $scrollbar-size;
  }
}
