.ProductFilterList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background: var(--primary-bg-main);
  color: var(--primary-fg-main);

  &__header,
  &__footer {
    position: sticky;
    left: 0;
    right: 0;
  }

  &__header {
    top: 0;
  }

  &__children {
    flex-grow: 1;
    overflow: auto;
  }

  &__footer {
    bottom: 0;
    background: var(--primary-contrast-bg-main);
    text-align: center;
    font-size: $font-size-xs;
    padding: $space-s;

    & > * + * {
      margin-top: $space-s;
    }

    @include media(xl) {
      padding: $space-base;
    }
  }
}
