.u-width-100 {
  width: 100%;
}

.u-width-70 {
  width: 70%;
}

.u-width-50 {
  width: 50%;
}

.u-width-30 {
  width: 30%;
}

.u-width-20 {
  width: 20%;
}

.u-width-60ch {
  width: 60ch;
}

.u-width-base {
  width: $width-base;
}
