@mixin radio-base($responsive-size: false, $variant: "primary") {
  $radio-icon-size: 32px;
  $inner-circle-size: 28%;

  position: relative;
  height: $radio-icon-size;
  width: $radio-icon-size;
  flex-shrink: 0;

  @content;

  @if $variant == "primary" {
    color: var(--primary-fg-minor);

    &:before {
      background: var(--primary-bg-main);
    }
  }

  @if $responsive-size {
    $radio-icon-size-media-m: 24px;
    $radio-icon-size-media-l: 28px;
    $radio-icon-size-kiosk: $radio-icon-size;

    @include media(m) {
      height: $radio-icon-size-media-m;
      width: $radio-icon-size-media-m;
    }

    @include media(l) {
      height: $radio-icon-size-media-l;
      width: $radio-icon-size-media-l;
    }

    @include media(xl) {
      height: $radio-icon-size-kiosk;
      width: $radio-icon-size-kiosk;
    }
  }

  // Outer circle
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: solid 2px currentColor;
  }

  // Inner circle
  &:after {
    content: "";
    position: absolute;
    top: $inner-circle-size;
    left: $inner-circle-size;
    width: 100% - 2 * $inner-circle-size;
    height: 100% - 2 * $inner-circle-size;
    border-radius: 100%;
    background: var(--color-util-transparent);
  }
}
