.RadioListItem {
  @include list-item;

  &__icon {
    @include radio-base {
      height: $input-icon-size;
      width: $input-icon-size;
    }
  }

  &--active {
    font-weight: $font-weight-medium;

    .RadioListItem__icon:after {
      background: var(--primary-accent-bg-main);
    }
  }

  &--outline {
    border: $border-width-base solid var(--primary-fg-minor);
  }
}
