.EditableProductTile {
  $stacking: before, check;

  position: relative;
  border: $border-width-l solid var(--color-util-transparent);

  &__productTile {
    position: relative;

    &::before {
      content: "";
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.05;
      pointer-events: none;
      z-index: z($stacking, before);
      background: var(--primary-accent-bg-main);
    }
  }

  &__check {
    position: absolute;
    top: 0;
    left: $space-s;
    font-size: $font-size-xs;
    pointer-events: none;
    z-index: z($stacking, check);
    color: var(--primary-fg-minor);
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: $space-base $space-s;
    background: var(--primary-bg-main);
    color: var(--primary-fg-minor);
  }

  &__footerLeft,
  &__footerRight {
    display: flex;
  }

  &__footerColumn {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    & > .InputField {
      width: 125px; // Design decision
    }
  }

  &__footerColumn + &__footerColumn {
    margin-left: $space-s;
  }

  &__footerLabel {
    font-size: $font-size-xs;
  }

  &__reset {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $field-height;
    width: $field-height;
    border-radius: 50%;
    border: $border-width-l solid var(--primary-fg-accent);
    color: var(--primary-fg-accent);
  }

  &--checked {
    border: $border-width-l solid var(--primary-fg-accent);

    .EditableProductTile__productTile::before {
      display: block;
    }
  }

  &--priceField &__footer {
    justify-content: space-between;
  }
}
