.VariantInformationBox {
  $stacking: content, image;
  $stacking-content: wrapper, before;

  $grid-cols: 1fr;
  $grid-rows: auto auto 1fr;

  $height-scroll-indicator: $space-xxl;

  display: grid;
  grid-template-columns: $grid-cols;
  grid-template-rows: $grid-rows;

  max-height: 100%;

  background-color: var(--primary-bg-main);

  &__annotationTop {
    margin-bottom: $space-xs;
  }

  &__image {
    position: relative;
    padding: $space-xl $space-xxl;
    z-index: z($stacking, image);

    @include media(l) {
      padding: $space-xxl $space-xxxl;
    }
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: $space-l;
  }

  &__content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    overflow-x: hidden;
    padding: 0;
    z-index: z($stacking, content);
  }

  &__contentScrollContainer {
    @include hide-scrollbar {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 0 $space-s;
      z-index: z($stacking-content, wrapper);
    }

    @include media(l) {
      padding: 0 $space-xxl;
    }

    @include media(xl) {
      padding: 0 $space-xxxl;
    }

    & > * + * {
      margin-top: $space-base;
      padding-top: $space-base;
      border-top: $border-width-base solid var(--primary-fg-minor);
    }
  }

  &__scrollIndicator {
    position: absolute;
    height: $height-scroll-indicator;
    width: calc(100% - #{$scrollbar-size});
    z-index: z($stacking-content, before);

    pointer-events: none;

    opacity: 1;
    transition: $transition-base opacity $ease-in-out-quad;

    &--top {
      top: 0;
      background: linear-gradient(to top, var(--primary-bg-gradient-transparent-to-main));
    }

    &--bottom {
      bottom: 0;
      background: linear-gradient(to bottom, var(--primary-bg-gradient-transparent-to-main));
    }
  }

  &--hasAnnotations {
    @include media(l) {
      .VariantInformationBox__contentScrollContainer {
        padding: 0 $space-s;
      }
    }
  }

  &--isNotScrollable {
    .VariantInformationBox__scrollIndicator {
      &--top,
      &--bottom {
        opacity: 0;
      }
    }
  }

  &--isScrolledToTop {
    .VariantInformationBox__scrollIndicator--top {
      opacity: 0;
    }
  }

  &--isScrolled {
    .VariantInformationBox__scrollIndicator {
      &--top,
      &--bottom {
        opacity: 1;
      }
    }
  }

  &--isScrolledToBottom {
    .VariantInformationBox__scrollIndicator--bottom {
      opacity: 0;
    }
  }
}
