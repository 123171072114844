.SelectField {
  $icon-size-media-m: $font-size-s;
  $icon-size-media-l: $font-size-base;
  $icon-size-media-xl: $font-size-l;

  $padding-icon: $space-xs;
  $padding-left: $space-s;

  $arrow-field-width: 2 * $padding-icon + $icon-size-base;

  position: relative;
  display: flex;
  min-width: 0;

  &__button {
    @include scut-truncate;

    // To guarantee that the ellipses have the same color as the placeholder, we need to set the color where
    // the overflow (and truncation) happens and overwrite it when the field is selected.
    color: var(--primary-fg-minor);

    position: relative;
    display: block;
    width: 100%;
    height: $field-height-xs;
    padding: 0 $arrow-field-width 0 $padding-left;
    outline: 0;
    text-align: left;
    background: var(--primary-bg-main);
    border: 1px solid var(--primary-fg-minor);
    border-radius: $border-radius-base;

    @include media(l) {
      height: $field-height-s;
    }

    @include media(xl) {
      height: $field-height;
    }

    &:focus {
      font-weight: $font-weight-active;
      letter-spacing: $letter-spacing-compensate-font-weight;
    }
  }

  &,
  &__menu {
    font-size: $font-size-xs;
    line-height: $field-height-xs;

    @include media(l) {
      font-size: $font-size-s;
      line-height: $field-height-s;
    }

    @include media(xl) {
      font-size: $font-size-base;
      line-height: $field-height;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 $padding-icon;
    color: var(--primary-fg-support);
    transition: transform $transition-fast ease-out;

    & > .Icon {
      font-size: $icon-size-media-m;

      @include media(l) {
        font-size: $icon-size-media-l;
      }

      @include media(xl) {
        font-size: $icon-size-media-xl;
      }
    }
  }

  // NOTE: Much of the styling for the flyout menu in regards to spacing & position,
  // is handled in the logic file to avoid calculatation issues.
  &__flyout {
    position: absolute;
    z-index: z($stacking-global, portal);

    // Use this modifier if menu should have position fixed.
    // This is the case if select field is used within a fixed container.
    // This is a special modifier on a BEM child because of react portal!
    &--fixed {
      position: fixed;
    }
  }

  &__menu {
    list-style: none;
    margin: 0;
    padding: 0;
    background: var(--primary-bg-main);
    max-height: 5 * $field-height; // Design decision
    overflow-y: auto;
    border: 1px solid var(--primary-fg-minor);
    border-radius: $border-radius-base;

    & > * + * {
      border-top: solid 1px var(--primary-fg-minor);
    }
  }

  &__name {
    position: absolute;
    top: -25%;
    left: calc($padding-left - $space-xxs);
    padding: 0 $space-xxs;
    background-color: var(--primary-bg-main);
    color: var(--primary-fg-support);
    font-size: $font-size-xs;
    line-height: 1;
    opacity: 0;
    transition: opacity $transition-fast $ease-out-expo;
    pointer-events: none;

    @include media(l) {
      font-size: $font-size-s;
      top: -22.5%;
    }

    @include media(xl) {
      font-size: $font-size-base;
      top: -20%;
    }
  }

  &__reset-button {
    padding: 0 $padding-icon;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity $transition-fast $ease-out-expo;

    & > .Icon {
      color: var(--primary-fg-support);

      // This is currently the only way to achieve responsive icons without js (BCD-4931 Responsive Icon Sizes)
      font-size: $font-size-s;

      @include media(m) {
        font-size: $font-size-base;
      }

      @include media(l) {
        font-size: $font-size-l;
      }

      @include media(xl) {
        font-size: $icon-size-base;
      }
    }
  }

  &__ctaButtons {
    padding-bottom: $space-xxs;
    display: flex;
    flex-direction: column;
    gap: $space-xxs;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: $padding-left;
    background: var(--primary-accent-bg-main);
    color: var(--primary-accent-fg-main);
    width: $field-height-xs;

    @include media(l) {
      width: $field-height-s;
    }

    @include media(xl) {
      width: $field-height;
    }

    .Icon {
      // This is currently the only way to achieve responsive icons without js (BCD-4931 Responsive Icon Sizes)
      font-size: $font-size-xs;

      @include media(m) {
        font-size: $font-size-base;
      }

      @include media(l) {
        font-size: $font-size-l;
      }

      @include media(xl) {
        font-size: $icon-size-base;
      }
    }
  }

  &--withIcon {
    .SelectField__button {
      padding-left: $field-height-xs + $padding-left;

      @include media(l) {
        padding-left: $field-height-s + $padding-left;
      }

      @include media(xl) {
        padding-left: $field-height + $padding-left;
      }
    }

    .SelectField__name {
      left: $field-height-xs + $padding-left - $space-xxs;

      @include media(l) {
        left: $field-height-s + $padding-left - $space-xxs;
      }

      @include media(xl) {
        left: $field-height + $padding-left - $space-xxs;
      }
    }
  }

  &--isOpen {
    .SelectField__button {
      border-color: var(--primary-fg-accent);
      // Use additional box-shadow to avoid size-changes
      box-shadow: $field-box-shadow;
    }

    .SelectField__name {
      color: var(--primary-fg-accent);

      // Emulate bold font weight with text-shadow to avoid size-changes
      text-shadow: $field-name-active-text-shadow;
    }

    .SelectField__arrow {
      transform: scaleY(-1);
    }

    .SelectField__icon {
      background: transparent;
      color: var(--primary-fg-accent);
    }
  }

  &--selected {
    .SelectField__button {
      color: var(--primary-fg-main);
    }

    .SelectField__name {
      opacity: 1;
    }
  }

  &--disabled {
    .SelectField__button {
      color: var(--primary-disabled-fg-main);
      border-color: var(--primary-disabled-bg-main);
    }

    .SelectField__arrow {
      color: var(--primary-disabled-fg-main);
    }
  }

  &--error {
    .SelectField__button {
      border-color: var(--primary-fg-negative);
      box-shadow: $field-box-shadow-error;
    }
  }

  &--withResetSelectionButton {
    &.SelectField--selected {
      .SelectField__reset-button {
        opacity: 1;
      }
    }
  }

  &--showName {
    margin-top: $space-xs;
  }
}
