.ConnectionStatus {
  &__label {
    @include typography-headline-minor;

    margin-bottom: $space-xxs;
    color: var(--primary-fg-support);
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    & > .ConnectionSignal {
      margin-right: $space-s;
    }
  }

  &__name {
    @include typography-headline-m;
    max-width: 50%;
  }

  &__children {
    display: flex;
    align-items: center;
    margin-left: $space-l;
  }
}
