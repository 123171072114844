.SymbolsKeyboardLayout {
  $number-of-keys: 16;

  &__grid {
    display: grid;
    grid-template-columns: repeat($number-of-keys, $keyboard-button-width);
    grid-auto-rows: $keyboard-button-height;
    grid-column-gap: $keyboard-button-gap;
    grid-row-gap: $keyboard-button-gap;
    justify-content: start;
  }

  &__keys {
    grid-column-start: 1;
    grid-column-end: $number-of-keys + 1;
    grid-row-start: 1;
    grid-row-end: 4;
    overflow: auto;
    // Create a little negative space for the vertical scrollbar
    margin-right: calc($keyboard-button-gap * -2);
  }

  &__backButton {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }

  &__instruction-text {
    grid-column-start: 3;
    grid-column-end: $number-of-keys - 2;
    grid-row-start: 4;
    grid-row-end: 5;
    justify-self: center;
    align-self: center;
    color: var(--standout-fg-support);
  }

  &__downButton {
    grid-column-start: $number-of-keys;
    grid-column-end: $number-of-keys + 1;
    grid-row-start: 4;
    grid-row-end: 5;
  }

  &__upButton {
    grid-column-start: $number-of-keys - 1;
    grid-column-end: $number-of-keys;
    grid-row-start: 4;
    grid-row-end: 5;
  }
}
