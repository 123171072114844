.ModalContainer {
  $stacking: background, content;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: z($stacking-global, portal);

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--primary-contrast-bg-main-alpha-dense);
    z-index: z($stacking, background);
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: hidden;
    z-index: z($stacking, content);
    // Disable pointer events to allow click on background
    pointer-events: none;
    transition: bottom $transition-fast;

    & > * {
      // Enable pointer events in children again
      pointer-events: initial;
    }
  }

  &--position {
    &-center {
      .ModalContainer__content {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &-top {
      .ModalContainer__content {
        top: 0;
        left: 0;
        right: 0;
        bottom: 35%;
      }
    }

    &-bottom {
      .ModalContainer__content {
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &-right {
      .ModalContainer__content {
        top: 0;
        left: auto;
        right: 0;
        bottom: 0;
      }
    }

    &-top-right {
      .ModalContainer__content {
        top: 10%;
        left: auto;
        right: 0;
        bottom: auto;
        // Since we restrict the height of the modal, we need to allow overflow.
        // Otherwise the shadow will be cut off.
        overflow: visible;
      }
    }
  }

  &--hideBackground &__background {
    background: var(--color-util-transparent);
  }

  &--keyboardOpen &__content {
    bottom: $keyboard-panel-height;

    & > .Modal {
      max-height: 90%;
      overflow-y: auto;
    }
  }
}
