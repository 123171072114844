.LabeledStatus {
  &__header {
    @include typography-headline-minor;

    display: flex;
    align-items: center;
    margin-bottom: $space-xxs;
  }

  &__label {
    margin-right: $space-xs;
    color: var(--primary-fg-support);
  }

  &__children {
    @include typography-headline-m;
  }
}
