.FlexFooterLayout {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__children {
    flex-grow: 1;
    margin-bottom: $space-l;
  }
}
