.Annotation {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  &--variant {
    &-primary {
      &.Annotation--kind-main {
        &,
        & > .Icon {
          color: var(--primary-fg-main);
        }
      }

      &.Annotation--kind-support {
        &,
        & > .Icon {
          color: var(--primary-fg-support);
        }
      }
    }

    &-standout {
      &.Annotation--kind-main {
        &,
        & > .Icon {
          color: var(--standout-fg-main);
        }
      }

      &.Annotation--kind-support {
        &,
        & > .Icon {
          color: var(--standout-fg-support);
        }
      }
    }
  }

  &--icon-position {
    &-right {
      flex-direction: row;

      & > .Icon {
        margin-left: 0.5em;
        flex-shrink: 0;
      }
    }

    &-left {
      flex-direction: row-reverse;
      justify-content: flex-end;

      & > .Icon {
        margin-left: 0;
        margin-right: 0.5em;
      }
    }
  }

  &--size {
    &-default {
      font-size: $font-size-xs;
    }

    &-l {
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
    }
  }
}
