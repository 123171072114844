@mixin checkbox-base($variant: "primary") {
  $check-icon-size: 18px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: $input-icon-size;
  width: $input-icon-size;
  min-width: $input-icon-size; // otherwise checkbox gets squeezed in small container with long label
  line-height: 0;
  color: var(--color-util-transparent);
  border: solid $border-width-l;
  border-radius: $border-radius-base;

  @if $variant == "primary" {
    background: var(--primary-bg-main);
    border-color: var(--primary-fg-minor);
  }

  & > .Icon {
    font-size: $check-icon-size;
  }
}
