.LoadingContent {
  width: 100%;
  height: 100%;

  & > * {
    width: 100%;
    height: 100%;
  }

  &__loading {
    * {
      color: var(--color-util-transparent);
      animation: background-loading 0.65s linear infinite alternate;
      border-radius: $border-radius-base;
    }
  }

  @keyframes background-loading {
    0% {
      background-color: var(--standout-bg-support);
    }
    100% {
      background-color: var(--standout-bg-minor);
    }
  }
}
