.TabLink {
  $border-height: 3px;

  position: relative;
  outline: none;
  text-decoration: none;
  display: inline-block;

  &__content {
    display: flex;
    gap: $space-xs;
    align-items: center;
  }

  &__label {
    color: var(--primary-fg-main);
    font-size: $font-size-xxxl;
    font-weight: $font-weight-regular;
    line-height: $line-height-s;
    margin-bottom: $space-xxs;
  }

  &__border {
    position: relative;
    display: block;
    height: $border-height;
    width: 100%;

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      content: "";
      display: block;
      height: $border-height;
      width: 100%;
      background-color: var(--color-util-transparent);
      transform: scaleX(0);
      transform-origin: left;
      transition: all $transition-slow $ease-out-expo;
    }
  }

  &--active {
    .TabLink__label {
      font-weight: $font-weight-bold;
    }

    .TabLink__border::after {
      background-color: var(--primary-accent-bg-main);
      transform: scaleX(1);
    }
  }
}
