.TableRow {
  $border-radius: 9999px; // half-circle edge

  border-radius: $border-radius;
  transition:
    opacity $transition-fast ease-out,
    box-shadow $transition-fast ease-out;

  &--clickable {
    cursor: pointer;
  }

  &--selected {
    // Simulate outline with box-shadow, which is consistent in all browsers
    $outline-width: 1px;

    @function box-shadow($x, $y) {
      @return $x $y var(--primary-bg-minor) inset;
    }

    td {
      background-color: var(--primary-bg-support);

      box-shadow: box-shadow(0, -$outline-width), box-shadow(0, $outline-width);

      &:first-child {
        box-shadow: box-shadow(0, -$outline-width), box-shadow(0, $outline-width), box-shadow($outline-width, 0);
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      &:last-child {
        box-shadow: box-shadow(0, $outline-width), box-shadow(0, -$outline-width), box-shadow(-$outline-width, 0);
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      // When it is only one column in the row, add an 'outline' to all sides
      &:first-child:last-child {
        box-shadow: box-shadow(0, $outline-width), box-shadow(0, -$outline-width), box-shadow(-$outline-width, 0),
          box-shadow($outline-width, 0);
      }
    }
  }

  &--disabled > * {
    color: var(--primary-disabled-fg-main);
  }
}
