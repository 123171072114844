.WebViewWrapper {
  height: 100%;

  & > * {
    width: 100%;
    height: 100%;
    border: none;
  }

  &--initSizeWhenLoaded {
    & > * {
      height: 0;
      width: 0;
    }
  }

  &--loaded {
    & > * {
      width: 100%;
      height: 100%;
    }
  }
}
