.SelectCtaButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $field-height;
  padding: 0 $space-s;
  background: var(--accent-bg-main);
  border-radius: $border-radius-base;
  color: var(--accent-fg-main);
  font-size: $font-size-xs;
  line-height: $line-height-s;

  @include media(l) {
    font-size: $font-size-s;
    padding: $space-xs $space-s;
    height: auto;
  }

  @include media(xl) {
    font-size: $font-size-base;
  }

  &__children {
    text-align: left;
  }

  &:disabled {
    background: var(--accent-disabled-bg-main);
    color: var(--accent-disabled-fg-main);
    cursor: not-allowed;
  }
}
