.FilterListItem {
  $width-remove-button: $list-item-height;

  display: flex;

  &__filter {
    @include list-item(".FilterListItem");
  }

  &__remove-button {
    background: var(--primary-bg-main);
    color: var(--primary-fg-minor);
    border-left: 1px solid var(--primary-fg-divider);
    font-size: $font-size-xl;
    width: $width-remove-button;
    outline: 0;
    flex-shrink: 0;

    & > .Icon {
      display: block;
      margin: auto;
    }
  }

  &__number {
    margin-right: $space-s;
  }

  &:active,
  &--touched {
    .FilterListItem__label,
    .FilterListItem__description {
      color: var(--primary-active-fg-main);
    }
  }

  &--active {
    .FilterListItem__filter {
      width: calc(100% - $width-remove-button);
    }

    .FilterListItem__label {
      font-weight: $font-weight-medium;
    }

    .FilterListItem__label,
    .FilterListItem__description {
      // Overwrite declaration from @mixin list-item;
      margin-right: 0;
    }
  }

  &--disabled {
    .FilterListItem__filter {
      background-color: var(--primary-disabled-bg-main);
    }

    .FilterListItem__label,
    .FilterListItem__description {
      color: var(--primary-disabled-fg-main);
    }
  }

  &--warning {
    .FilterListItem__number {
      color: var(--primary-fg-warning);
    }
  }
}
