.PriceInfo {
  display: flex;
  gap: $space-xs;
  color: var(--primary-fg-main);
  align-items: center;

  &__originalPrice {
    text-decoration: line-through;
    color: var(--primary-fg-support);
    font-size: 0.9em;
  }

  &__badge {
    font-size: 0.8em;
  }
}
