.ContextMenuItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: $space-xs $space-s;
  min-height: $field-height - 2px; // Compensate for surrounding border
  font-size: $font-size-base;
  outline: 0;
  text-align: left;
  background: var(--primary-bg-main);
  color: var(--primary-fg-main);

  &:active,
  &--touched {
    background-color: var(--primary-active-bg-main);
    color: var(--primary-active-fg-main);
  }
}
