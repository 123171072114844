.ImageSlider {
  $slider-padding-x: 170px;
  $slider-arrow-size: 50px;

  position: relative;
  width: 100%;

  &__container {
    overflow: hidden;
    border-radius: $border-radius-l;
    box-shadow: 0 10px 30px var(--color-shadow-medium);
    margin-bottom: $space-base;
  }

  &__navigation {
    text-align: center;
  }

  &__prev,
  &__next {
    outline: 0;
  }

  &__prev {
    margin-right: $space-l;
  }

  &__next {
    margin-left: $space-l;
  }

  &__dot,
  &__dotActive {
    width: 1em;
    height: 1em;
    border-radius: 100%;
    border: 1px solid currentColor;
    margin: 0 $space-s;
    vertical-align: middle;
    outline: 0;
  }

  &__dotActive {
    background: currentColor;
  }

  &--textAlign {
    &-center {
      text-align: center;
    }

    &-left {
      text-align: left;
    }
  }

  &--matchHeights {
    // Source: https://github.com/kenwheeler/slick/issues/179
    .ImageSlider__container {
      .slick-track {
        display: flex;

        .slick-slide {
          height: auto;

          > div {
            height: 100%;

            > * {
              height: 100%;
            }
          }
        }
      }
    }
  }
}
