// Common styles to build a list item

@mixin list-item($component-class: &, $variant: "primary") {
  $text-class: #{$component-class}__text !default;
  $label-class: #{$component-class}__label !default;
  $description-class: #{$component-class}__description !default;

  display: flex;
  align-items: center;
  width: 100%;
  height: $list-item-height;
  line-height: $list-item-line-height;
  padding: $space-s;
  text-align: left;
  outline: 0;

  @if $variant == "primary" {
    background: var(--primary-bg-main);
    color: var(--primary-fg-main);

    #{$description-class} {
      color: var(--primary-fg-minor);
    }
  }

  #{$text-class} {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  #{$label-class} {
    display: block;
    font-size: $font-size-xs;

    @include media(m) {
      font-size: $font-size-s;
    }

    @include media(l) {
      font-size: $font-size-base;
    }
  }

  #{$label-class},
  #{$description-class} {
    @include scut-truncate;

    flex-grow: 1;
    margin-right: 1em;
  }

  #{$description-class} {
    font-size: $font-size-xs;
  }
}
