// Please keep this file in sync with /src/resources/css/scrollbar.css

@mixin scrollbar {
  $border-scrollbar: 1px solid var(--color-scrollbar-border);

  @content;

  ::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-scrollbar-thumb);
    border-radius: calc($scrollbar-size/2);
    border: $border-scrollbar;

    &:active,
    &:hover {
      background-color: var(--color-scrollbar-thumb-active);
      border: none;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: var(--color-scrollbar-track);
    border-radius: calc($scrollbar-size/2);

    &:active,
    &:hover {
      background-color: var(--color-scrollbar-track-active);
    }
  }
}

@mixin hide-scrollbar {
  // Firefox
  scrollbar-width: none;

  @content;

  // Chromium/Chrome/Edge
  &::-webkit-scrollbar {
    display: none;
  }
}
