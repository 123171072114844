@use "sass:math";

.FloatingActionBar {
  $height: 50px;

  height: $height;
  display: flex;
  align-items: center;
  padding: 0 $space-base;
  border-radius: math.div($height, 2);
  box-shadow: $balloon-box-shadow;
  gap: $space-base;
  background-color: var(--standout-bg-main);
  color: var(--standout-fg-main);

  &__info {
    display: flex;
    align-items: center;
  }

  &__actions {
    display: flex;
    gap: $space-s;
  }
}
