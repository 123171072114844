.Card {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-xl;
  padding: $space-xl;
  border: 2px solid;
  background-color: var(--primary-bg-main);
  border-color: var(--color-util-transparent);

  &__header {
    margin-bottom: $space-sl;
    flex-grow: 0;
    flex-shrink: 0;

    & > * + * {
      margin-top: $space-xs;
    }
  }

  &__content {
    flex-grow: 1;
    flex-shrink: 0;

    & > * + * {
      margin-top: $space-sl;
    }
  }

  &__actions {
    margin-top: $space-xxl;
    display: flex;
    justify-content: flex-end;
    flex-grow: 0;
    flex-shrink: 0;

    & > * + * {
      margin-left: $space-base;
    }
  }

  &--active {
    border-color: var(--primary-fg-accent);
  }
}
