.ErrorBox {
  $error-box-max-height: 180px;

  margin: 0;
  padding: $space-base;
  max-height: $error-box-max-height;
  overflow: auto;
  background: var(--tertiary-bg-main);
  border-left: $border-width-xl solid var(--tertiary-fg-negative);
  font-family: $font-family-mono;
  font-style: initial;
  @include typography-paragraph-base;
}
