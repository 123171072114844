.TimeControlDayToggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .Headline {
    margin-bottom: $space-s;
  }
}
