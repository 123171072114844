$stacking: overlay, controls, notice;
$controls-stacking: rest, first;

.ZoomImageModal {
  display: grid;
  place-items: center;
  position: relative;
  z-index: 0;
  background: var(--primary-bg-main);
  transition: background $transition-crawl;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z($stacking, overlay);
    background: var(--primary-accent-bg-main);
    opacity: 0;
    pointer-events: none;
    transition: opacity $transition-crawl;
  }

  &__image {
    // Force images that are smaller than the viewport to fill the viewport
    min-height: 100svh;
    min-width: 100svw;
  }

  &__controls {
    position: fixed;
    top: $space-base;
    display: flex;
    align-items: center;
    gap: $space-s;
    z-index: z($stacking, controls);

    & > * {
      position: relative;
      z-index: z($controls-stacking, rest);
    }

    & > *:first-child {
      z-index: z($controls-stacking, first);
      animation: zoomOut ($transition-slow + $transition-fast) forwards;
    }

    & > * + * {
      opacity: 0;
      animation: slideIn $transition-base forwards;
      animation-delay: $transition-slow;
    }
  }

  &__notice {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: z($stacking, notice);
    pointer-events: none;
  }

  &--showNotice {
    &::after {
      opacity: 0.6;
    }
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  67% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
