.SpecsItem {
  $specs-item-font-size: 16px; // design decision: this is a special case and we should never use 16px anywhere else

  line-height: $line-height-s;
  font-size: $specs-item-font-size; // design decision to fit all specs on one screen

  &--empty &__text {
    color: var(--primary-fg-minor);
  }
}
