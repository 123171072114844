.BodySizingModalLayout {
  position: relative;

  &__main {
    display: flex;
    width: 100%;
    min-height: 450px; // Design decision
    background-color: var(--primary-bg-main);

    @include media(m) {
      min-height: 550px; // Design decision
    }
  }

  &__children {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $space-xxl;

    @include media(m) {
      padding: $space-xxxl;
    }
  }

  &__aside {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    padding: $space-xxl $space-xl;
    width: 290px; // Design decision

    @include media(m) {
      padding: $space-xxxl;
      width: 430px; // Design decision
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space-base $space-xxl;
    gap: $space-xxxl;
    width: 100%;
    background-color: var(--primary-bg-support);

    @include media(m) {
      padding: $space-l $space-xxxl;
    }
  }

  &__progress {
    flex-grow: 1;
    max-width: 360px; // Design decision
  }

  &--scroll {
    .BodySizingModalLayout__main {
      min-height: 0;
    }

    .BodySizingModalLayout__children {
      padding-top: 0;
    }
  }
}
