.FoldoutDescription {
  $stacking-context: content, button-wrapper;

  position: relative;
  text-align: left;
  font-size: $font-size-base;
  background: var(--primary-bg-main);
  color: var(--primary-fg-support);

  &__content {
    overflow: hidden;
    position: relative;
    z-index: z($stacking-context, content);

    &::after {
      display: none;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: $space-xxl;
      pointer-events: none;
      background: linear-gradient(to bottom, var(--primary-bg-gradient-transparent-to-minor)) no-repeat;
    }
  }

  &__buttonWrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: z($stacking-context, button-wrapper);
  }

  &--isFold {
    padding-bottom: calc($field-height-s / 2);

    .FoldoutDescription__content::after {
      display: block;
    }
  }
}
