.NextCustomerWorldLayout {
  $stacking: backgroundVideo, main, exit, navigation, progress, navigationToggle, overlay;

  $pause-size: 180px;
  $pause-icon-size: 60px;

  $pi: 3.14159265359;

  $resume-progress-size: 80px;
  $resume-progress-stroke: 2px;
  $resume-radius: $resume-progress-size * 0.5 - $resume-progress-stroke;
  $resume-circumference: 2 * $pi * $resume-radius;

  $resume-box-width: 750px;

  // 1600 (new width) / 1920 (screen width)
  $next-customer-world-scale: 0.83333333;
  $next-customer-world-half-diff-percentage: percentage(calc(((1 - 1 * $next-customer-world-scale) * 0.5) / 1));

  $main-backdrop-filter: blur(20px);

  $progress-bar-height: 5px;

  width: 100%;
  position: relative;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  &__exit {
    position: absolute;
    top: 0;
    left: 0;
    z-index: z($stacking, exit);
  }

  &__main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z($stacking, main);
    backdrop-filter: $main-backdrop-filter;
    background: var(--standout-bg-main-alpha-dense);
  }

  &__slide,
  &__slideCurrent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__children {
    &,
    & > * {
      height: 100%;
    }

    & > * {
      width: 100%;
      position: absolute;
      overflow: hidden;
      right: $next-customer-world-half-diff-percentage;
      bottom: $next-customer-world-half-diff-percentage;
      transform: scale($next-customer-world-scale);
    }
  }

  &__slide {
    pointer-events: none;
    opacity: 0;
  }

  &__progressBar {
    width: 100%;
    position: absolute;
    height: $progress-bar-height;
    top: calc(-1 * #{$progress-bar-height});
    background: var(--primary-bg-support-alpha-medium);
    transform: translateY(100%);
    transition: transform $transition-base ease-in-out;
    z-index: z($stacking, progress);
  }

  &__progressBarIndicator {
    width: 100%;
    height: 100%;
    background: var(--primary-bg-main);
    transform: scaleX(0);
    transform-origin: left center;
  }

  &__sideContainer {
    position: absolute;

    z-index: z($stacking, main);

    top: 0;
    right: 0;
    left: percentage($next-customer-world-scale);
    bottom: percentage(1 - $next-customer-world-scale);
  }

  &__footerContainer {
    position: absolute;
    z-index: z($stacking, main);

    left: 0;
    bottom: 0;
    top: percentage($next-customer-world-scale);
    right: percentage(1 - $next-customer-world-scale);
  }

  &__backgroundVideo {
    z-index: z($stacking, backgroundVideo);

    video {
      position: absolute;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: scale(1.55);
    }
  }

  &__paused {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: $pause-size;
    width: $pause-size;
    top: 50%;
    left: 50%;
    margin-left: -$pause-size * 0.5;
    margin-top: -$pause-size * 0.5;
    border-radius: 50%;
    z-index: z($stacking, overlay);
    pointer-events: none;
    opacity: 0;
    transform: scale(0.5);
    transition:
      transform $transition-base ease-out,
      opacity $transition-base ease-out;

    & > .Icon {
      font-size: 0.7 * $pause-size;
    }
  }

  &__resume {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z($stacking, navigationToggle);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: z($stacking, overlay);
  }

  &__resumeBox,
  &__paused {
    background: var(--standout-bg-main-alpha-heavy);
    color: var(--standout-fg-main);
  }

  &__resumeBox {
    display: flex;
    align-items: center;
    width: $resume-box-width;
    padding: $space-xxl;
    opacity: 0;
    transform: scale(0.8);
    transition:
      transform $transition-base ease-out,
      opacity $transition-base ease-out;
    font-size: $font-size-xl;
    border-radius: $border-radius-l;
  }

  &__resumeProgress {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $resume-progress-size;
    height: $resume-progress-size;
    margin-right: $space-xl;
    flex-shrink: 0;

    & > .Icon {
      font-size: 0.4 * $resume-progress-size;
    }
  }

  &__resumeProgressRing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__resumeProgressCircle {
    fill: none;
    stroke-dasharray: $resume-circumference;
    stroke-dashoffset: $resume-circumference;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: var(--primary-fg-accent);
  }

  &__resumeTitle {
    display: block;
    font-weight: $font-weight-bold;
    font-size: $font-size-xxxl;
  }

  &--pause &__paused {
    animation: NextCustomerWorldLayout-paused-appear 3s ease-out;
  }

  &--willResume &__resume {
    pointer-events: all;
  }

  &--willResume &__resumeBox {
    opacity: 1;
    transform: scale(1);
  }

  &--willResume &__resumeProgressCircle {
    animation-name: NextCustomerWorldLayout-resume-progress;
    animation-timing-function: linear;
  }

  @keyframes NextCustomerWorldLayout-paused-appear {
    20%,
    80% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  @keyframes NextCustomerWorldLayout-resume-progress {
    from {
      stroke-dashoffset: $resume-circumference;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
}
