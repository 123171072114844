.Bookmark {
  $description-font-size: $font-size-xl;
  $description-height: 108px;
  $description-width: 180px;

  $counter-font-size: $font-size-xxxxxl;

  $inner-padding-top: $space-xl;
  $inner-padding-left: $space-l;
  $inner-padding-bottom: $space-l;

  position: relative;
  display: flex;
  height: $bookmark-height;
  width: $bookmark-width;
  padding: $inner-padding-top $inner-padding-left $inner-padding-bottom $inner-padding-left;
  text-align: left;

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .img {
      pointer-events: none;
    }
  }

  &__label {
    position: relative;
    height: $description-height;
    width: $description-width;
    font-size: $description-font-size;
  }

  &__counter {
    position: absolute;
    font-size: $counter-font-size;
    font-weight: $font-weight-thin;
    align-self: flex-end;
  }

  &__check {
    position: absolute;
    top: $space-xxs;
    right: $space-s;
    font-size: scut-rem(26px);
  }

  &--kind {
    &-empty {
      font-weight: $font-weight-bold;
      color: var(--primary-fg-support);
    }

    &-unsaved {
      color: var(--primary-accent-fg-main);
      fill: var(--primary-accent-bg-main);

      .Bookmark__label {
        font-weight: $font-weight-bold;
      }
    }

    &-unsaved,
    &-saved {
      filter: drop-shadow(0 2px 20px var(--color-shadow-medium));
    }

    &-saved {
      .Bookmark__counter {
        color: var(--primary-fg-accent);
      }

      .Bookmark__label {
        color: var(--primary-fg-support);
      }
    }
  }
}
