.FlippableTile {
  perspective: $perspective-default;
  transition: transform $transition-base ease-in-out;
  position: relative;
  width: 100%;

  &__frontSide,
  &__backSide {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    transition: transform $transition-fast ease-in-out;
  }

  &__backSide {
    transform: rotateY(180deg);
  }

  &--isFlipped {
    .FlippableTile__frontSide {
      transform: rotateY(180deg);
    }
    .FlippableTile__backSide {
      transform: rotateY(360deg);
    }
  }

  &--height {
    &-default {
      height: $tile-height;
    }

    &-l {
      height: $tile-height-l;
    }

    &-xl {
      height: $tile-height-xl;
    }
  }
}
