.IconLabel {
  display: flex;
  align-items: center;
  gap: $space-base;

  &--kind {
    &-default {
      color: var(--primary-fg-main);
    }

    &-positive > .Icon {
      color: var(--primary-fg-positive);
    }

    &-negative {
      color: var(--primary-fg-negative);
    }
  }
}
