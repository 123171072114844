.DescriptionList {
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, auto);
  grid-column-gap: $space-base;
  grid-row-gap: $space-xs;
  font-size: $font-size-xs;
  color: var(--primary-fg-main);
  margin: 0;
  text-align: left;

  @include media(l) {
    font-size: $font-size-s;
  }

  @include media(xl) {
    font-size: $font-size-base;
  }

  & > :nth-child(2n + 1) {
    font-weight: $font-weight-medium;
  }

  & > :nth-child(2n) {
    margin-left: 0;
    word-wrap: break-word;
  }

  &--major {
    grid-template-columns: 1fr;

    & > :nth-child(2n) {
      margin-bottom: $space-base;
    }

    & > :last-child {
      margin-bottom: initial;
    }
  }
}
