.ComponentAnimation {
  $ca-zoom-scale-min: 0.9;

  transform-origin: center center;
  width: fit-content;

  // Note: prefix new keyframes with ca = component-animation to make them unique across the app!
  @keyframes ca-fade-in {
    to {
      opacity: 1;
    }
  }

  @keyframes ca-fade-out {
    to {
      opacity: 0;
    }
  }

  @keyframes ca-zoom-in {
    to {
      transform: scale(1);
    }
  }

  @keyframes ca-zoom-out {
    to {
      transform: scale($ca-zoom-scale-min);
    }
  }

  @keyframes ca-zoom-in-out {
    0% {
      transform: scale($ca-zoom-scale-min);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale($ca-zoom-scale-min);
    }
  }

  @keyframes ca-fade-in-zoom-in {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes ca-fade-out-zoom-out {
    to {
      opacity: 0;
      transform: scale($ca-zoom-scale-min);
    }
  }

  &--animation {
    &-fade-in {
      opacity: 0;
      animation-name: ca-fade-in;
    }

    &-fade-out {
      animation-name: ca-fade-out;
    }

    &-zoom-in {
      transform: scale($ca-zoom-scale-min);
      animation-name: ca-zoom-in;
    }

    &-zoom-out {
      animation-name: ca-zoom-out;
    }

    &-zoom-in-out {
      animation-name: ca-zoom-in-out;
    }

    &-fade-in-zoom-in {
      opacity: 0;
      transform: scale($ca-zoom-scale-min);
      animation-name: ca-fade-in-zoom-in;
    }

    &-fade-out-zoom-out {
      opacity: 1;
      animation-name: ca-fade-out-zoom-out;
    }
  }
}
