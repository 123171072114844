.SidebarNavigationLink {
  display: block;
  padding: $space-xs $space-l;
  color: var(--primary-fg-main);
  text-decoration: none;
  outline: 0;
  transition:
    background-color $transition-fast $ease-in-out-quad,
    color $transition-fast $ease-in-out-quad;

  &:active,
  &--touched {
    background-color: var(--primary-active-bg-main);
    color: var(--primary-active-fg-main);
  }

  &--active,
  &--active:active {
    background-color: var(--primary-accent-bg-main);
    color: var(--primary-accent-fg-main);
    font-weight: $font-weight-medium;
  }
}
