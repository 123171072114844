.CheckboxListItemWithImage {
  $image-width: (
    s: 100px,
    m: 125px,
    l: 175px
  );

  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  padding: $space-base $space-xl $space-sl;
  outline: 0; // Hide focus outline

  background: var(--primary-bg-main);
  color: var(--primary-fg-main);

  &__image {
    margin-bottom: $space-base;
  }

  &__checkbox {
    @include checkbox-base;
  }

  &__badge {
    position: absolute;
    display: flex;
    top: $space-xxs;
    left: $space-xxs;
  }

  &--active {
    font-weight: $font-weight-medium;

    .CheckboxListItemWithImage__checkbox {
      background: var(--primary-accent-bg-main);
      border-color: var(--primary-fg-accent);
      color: var(--primary-accent-fg-main);
    }
  }

  &--outline {
    border: $border-width-base solid var(--primary-fg-minor);
  }

  &--image-width-s {
    .CheckboxListItemWithImage__image {
      width: map-get($image-width, s);
    }
  }

  &--image-width-m {
    .CheckboxListItemWithImage__image {
      width: map-get($image-width, m);
    }
  }

  &--image-width-l {
    .CheckboxListItemWithImage__image {
      width: map-get($image-width, l);
    }
  }
}
