.WelcomeAnimation {
  height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__logo {
    position: absolute;
    top: 0;
    right: 0;
    height: 100px;
    width: 100px;
    align-self: flex-start;
  }

  &__content-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
  }

  &__button {
    display: flex;
    gap: $space-base;
  }
}
