.CustomerWorldNavigationButton {
  $button-size: 50px;
  $label-size: 16px;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--standout-fg-main);
  outline: 0;

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $button-size;
    width: $button-size;
    margin-bottom: $space-xxs;
    border: $border-width-base solid var(--standout-fg-main);
    border-radius: 50%;
  }

  &__label {
    font-size: $label-size;
  }
}
