.TabBarOverlay {
  $header-height: 100px;
  $header-height-s: 70px;

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--primary-bg-main);

  &__header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    background: var(--primary-bg-support);
    height: $header-height-s;

    @include media(l) {
      height: $header-height;
    }
  }

  &__headerLeft,
  &__headerCenter,
  &__headerRight {
    flex-grow: 1;
    flex-basis: 0;
  }

  &__headerLeft {
    text-align: left;
  }

  &__headerCenter {
    text-align: center;
  }

  &__headerRight {
    text-align: right;
  }

  &__content {
    flex-grow: 1;
    overflow: auto;
    padding: $space-xxl $space-xl;
    height: 100%;

    & > * + * {
      margin-top: $space-base;
    }
  }

  &__annotation {
    position: absolute;
    bottom: $space-base;
    right: $space-xl;
    max-width: 350px; // Design decision
  }

  &--noContentPadding {
    .TabBarOverlay__content {
      padding: 0;
    }
  }
}
