.DetailListItem {
  @include list-item;

  &__text {
    flex-grow: 1;
  }

  &__indicator {
    flex-shrink: 0;
  }

  &--active {
    .DetailListItem__label {
      font-weight: $font-weight-medium;
    }
  }

  &--disabled {
    background-color: var(--primary-disabled-bg-main);
    cursor: not-allowed;

    .DetailListItem__label,
    .DetailListItem__description,
    .DetailListItem__indicator {
      color: var(--primary-disabled-fg-main);
    }
  }

  &--warning {
    .DetailListItem__indicator {
      color: var(--primary-fg-warning);
    }
  }
}
