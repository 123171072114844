.ProductDetailsImage {
  $stacking: image, action;

  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: $space-xl;
  z-index: 0;

  &__image,
  &__title {
    display: flex;
    justify-content: center;
  }

  &__image {
    flex-grow: 1;
    z-index: z($stacking, image);
  }

  &__action {
    position: absolute;
    top: $space-base;
    margin: 0 auto;
    z-index: z($stacking, action);
  }
}
