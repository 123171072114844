.SingleDescribedInputFormLayout {
  display: grid;

  & > .Headline {
    grid-area: label;
  }

  &__description {
    grid-area: description;

    & > * + * {
      margin-top: $space-s;
    }
  }

  &__input {
    grid-area: input;
  }

  &--disabled {
    .Headline,
    .SingleDescribedInputFormLayout__description {
      color: var(--primary-disabled-fg-main);
    }
  }

  &--inputPosition {
    &-right {
      grid-template-columns: $width-base minmax(0, auto);
      grid-template-areas:
        "label ."
        "description input";
      grid-column-gap: $space-l;
      grid-row-gap: $space-base;
    }

    &-below {
      grid-template-areas:
        "label ."
        "description ."
        "input input";
      grid-template-columns: 1fr;
      grid-column-gap: $space-l;
      grid-row-gap: $space-l;
    }
  }
}
