.FlexLayout {
  width: 100%;
  display: flex;

  &--justify-content {
    &-start {
      justify-content: flex-start;
    }

    &-space-between {
      justify-content: space-between;
    }

    &-end {
      justify-content: flex-end;
    }

    &-center {
      justify-content: center;
    }
  }

  &--direction {
    &-column {
      flex-direction: column;
    }

    &-row {
      flex-direction: row;
    }
  }

  &--align-items {
    &-normal {
      align-items: normal;
    }

    &-center {
      align-items: center;
    }

    &-flex-start {
      align-items: flex-start;
    }

    &-flex-end {
      align-items: flex-end;
    }
  }

  &--gap {
    &-none {
      gap: 0;
    }

    &-base {
      gap: $space-base;
    }

    &-xs {
      gap: $space-xs;
    }

    &-s {
      gap: $space-s;
    }

    &-sl {
      gap: $space-sl;
    }

    &-l {
      gap: $space-l;
    }

    &-xxxl {
      gap: $space-xxxl;
    }
  }

  &--alignLastItemToRight {
    & > *:last-child {
      margin-left: auto;
    }
  }
}
