.SidebarNavigation {
  $stacking: main, header, scroll-footer;
  $scroll-button-space: 2px;

  position: relative;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  background: var(--primary-bg-main);
  color: var(--primary-fg-main);
  overflow: hidden;

  &__header {
    position: relative;
    z-index: z($stacking, header);
    display: flex;
    align-items: center;
    padding-left: $space-l;
    height: $content-header-height;
  }

  &__main {
    position: absolute;
    z-index: z($stacking, main);
    top: $content-header-height;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: $space-xl;
    background: linear-gradient(to top, var(--primary-bg-gradient-transparent-to-minor)) no-repeat;
    background-size: 100% $space-xxl;

    & > * {
      margin-bottom: $space-xl;
    }
  }

  &__scrollFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: z($stacking, scroll-footer);
    transform: translateY(100%);
    transition: transform $transition-fast ease-out;
    padding: $scroll-button-space;
  }

  &__scrollButton {
    display: block;
    width: 100%;
    outline: 0;
    background: var(--primary-contrast-bg-main-alpha-light);
    color: var(--primary-contrast-fg-main);
    padding: $space-xs;
    text-align: center;
  }

  &--scrollDown {
    .SidebarNavigation__scrollFooter {
      transform: none;
    }
  }
}
