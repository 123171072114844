.NumberIndicator {
  display: inline-block;
  width: $number-indicator-size;
  height: $number-indicator-size;
  line-height: $number-indicator-size;
  border-radius: 50%;
  background: var(--accent-bg-main);
  color: var(--accent-fg-main);
  font-weight: $font-weight-medium;
  text-align: center;
  font-size: $font-size-xs;
}
