.MainContentLayout {
  $stacking: content, aside, header;
  $stacking-header: background, header-content;
  $aside-width: 250px;

  position: relative;
  height: 100vh; // Browser fallback
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  // background-image is set in the tsx/template file
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top left;
  background-size: cover;

  // FUTURE: If we need more variability in the header we should refactor the header into its own component.
  &__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    height: $content-header-height;
    pointer-events: none;
    z-index: z($stacking, header);

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 100vh; // Browser fallback
      height: calc(var(--vh, 1vh) * 100);
      width: 100%;
      background-size: 100% 100vh; // Browser fallback
      background-size: 100% calc(var(--vh, 1vh) * 100);
      // Background image of this element has to be always the same as that of the MainContentLayout itself
      background: linear-gradient(to right top, var(--primary-bg-gradient-minor-to-support));
      mask-image: linear-gradient(
        to bottom,
        var(--color-util-black) $content-header-height,
        var(--color-util-transparent) $header-height-small
      );
      background-blend-mode: multiply;
      z-index: z($stacking-header, background);
    }
  }

  &__headerLeft,
  &__headerRight {
    // Disabling the pointer events and enabling them again for the children gives us a more generous click area for the scroll gesture.
    // At the same time we don't loose any of the click functionality of buttons
    pointer-events: auto;
    position: relative;
    z-index: z($stacking-header, header-content);
  }

  &__headerLeft {
    display: flex;
    align-items: center;
    padding-left: $space-l;

    & > .IconLink {
      height: 100%;
      margin-left: -1 * $space-l;
      padding: 0 $space-l;
    }
  }

  &__headerRight {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    padding-right: $page-layout-padding;

    // Special case for breadcrumb, logo
    & > .CurrentPageBreadcrumb,
    & > .Logo {
      position: relative;
      right: -1 * $page-layout-padding;
      align-self: flex-start;
    }
    & > .IconLink {
      position: absolute;
      top: 100%;
      right: $page-layout-padding;
      white-space: nowrap;
      // Increase click area
      padding-left: $space-base;
      padding-bottom: $space-base;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 100vh; // Browser fallback
    min-height: calc(var(--vh, 1vh) * 100);

    // Padding does not work here because flexbox would ignore it in overflow case
    & > * {
      flex-grow: 1;
    }
  }

  &__scrollCanvas {
    overflow-x: hidden;
    overflow-y: auto;
    z-index: z($stacking, content);
    height: 100vh; // Browser fallback
    height: calc(var(--vh, 1vh) * 100);
  }

  &--hideScrollbar &__scrollCanvas {
    overflow-y: hidden;
  }

  &__aside {
    position: absolute;
    top: $header-height-small;
    right: $page-layout-padding;
    width: $aside-width;
    z-index: z($stacking, aside);
    text-align: right;

    & > * + * {
      margin-top: $space-l;
    }
  }

  &__contentImage {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
  }

  &--layout {
    &-default {
      .MainContentLayout__content {
        padding: $header-height-small $space-xxxl $page-layout-padding $space-xxxl;
      }
    }

    &-unbalanced-small {
      .MainContentLayout__content {
        padding: $header-height-large $page-layout-padding $page-layout-padding 2 * $page-layout-padding;
      }
    }

    &-unbalanced-large {
      .MainContentLayout__content {
        padding: $header-height-small $page-layout-padding $page-layout-padding $space-xxxl;
      }
    }

    &-full {
      .MainContentLayout__content {
        padding: $header-height-small 0 0;
      }
    }

    &-aside {
      .MainContentLayout__content {
        padding: $header-height-small (2 * $page-layout-padding + $aside-width) $page-layout-padding $space-xxxl;
      }
    }
  }

  &--hideHeaderBackground {
    .MainContentLayout__header:after {
      display: none;
    }
  }

  &--asideBottom {
    .MainContentLayout__aside {
      top: auto;
      bottom: $page-layout-padding;
    }
  }

  &--noPaddingBottom {
    .MainContentLayout__content {
      padding-bottom: 0;
    }
  }

  &--noContentStrech {
    .MainContentLayout__content > * {
      flex-grow: 0;
    }
  }
}
