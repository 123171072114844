.LabeledField {
  $error-height: 20px;

  display: block;

  &__label {
    @include scut-truncate();
    color: var(--primary-fg-support);
    font-weight: $font-weight-medium;
    height: $labeled-field-label-height;
  }

  &__error {
    display: none;
    color: var(--primary-fg-negative);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    height: $labeled-field-error-height;
    padding-top: $space-xxs;
  }

  &--error {
    .LabeledField__error {
      display: block;
    }
  }

  &:not(.LabeledField--autoWidth) {
    width: 100%;
  }

  &--autoWidth {
    display: table; // parent has width of content and breaks line
  }
}
