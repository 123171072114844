.SettingsPageLayout {
  overflow: hidden;

  &__canvas {
    position: relative;
    width: 100%;
    height: 100vh; // Browser fallback
    height: calc(var(--vh, 1vh) * 100);
    transition: transform $transition-base ease-in-out;
  }

  &__main {
    width: 100vw;
  }

  &__navigation {
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    width: $sidebar-width;
  }

  &--showNavigation {
    .SettingsPageLayout__canvas {
      transform: translateX($sidebar-width);
    }
  }
}
