.NumpadKeyboardLayout {
  display: grid;
  grid-template-columns: repeat(3, $keyboard-button-width);
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/Auto-placement_in_CSS_Grid_Layout
  grid-auto-rows: $keyboard-button-height;
  gap: $keyboard-button-gap;
  justify-content: start;

  &--noBackspace {
    // The enter key
    & > :nth-child(11) {
      grid-column-end: span 2;
    }
  }
}
