.MainNavigationTile {
  display: flex;
  position: relative;

  &__link {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary-fg-main);
    background: var(--primary-bg-main);
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 0 $space-base;
    gap: $space-s;
    transition: background-color $transition-base $ease-in-out-quad;

    & > .NotificationBubble {
      position: absolute;
      right: 0;
      top: 10%;
      transform: translateX(50%);
    }
  }

  &--height {
    &-default {
      height: $tile-height;
    }

    &-xl {
      height: $tile-height-xl;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $main-navigation-icon-height;
    color: var(--primary-fg-accent);
    width: 100%;

    & > .Image {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    font-size: $font-size-xl;
    line-height: $font-size-xl;
    font-weight: $font-weight-regular;
  }

  &__badge {
    position: absolute;
    top: $space-xxs;
    left: $space-xs;
  }

  &__notification {
    position: absolute;
    top: $space-xs;
    right: $space-xs;
    line-height: 0; // Prevent inline elements from growing
  }

  &__tileButton {
    position: absolute;
    top: 0;
    right: 0;
  }

  &--touched {
    .MainNavigationTile__link {
      background: var(--primary-active-bg-main);

      .MainNavigationTile__icon {
        mix-blend-mode: multiply;
      }
    }

    .MainNavigationTile__label {
      color: var(--primary-active-fg-main);
    }
  }

  &--brandLogo &__link {
    // Increase the horizontal padding a bit to give the logo some space
    padding: 0 $space-xl;
  }

  &--disabled &__link {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.MainNavigationTile--disabled,
  &.MainNavigationTile--inactive {
    .MainNavigationTile__icon {
      opacity: 0.5;
    }
  }

  &.MainNavigationTile--inactive {
    .MainNavigationTile__link {
      background: var(--primary-disabled-bg-support);
      color: var(--primary-fg-minor);
    }
  }

  &.MainNavigationTile--disabled {
    .MainNavigationTile__link {
      background: var(--primary-disabled-bg-main);
      color: var(--primary-disabled-fg-main);
    }
  }

  &--transparent {
    opacity: 0.92;
  }
}
