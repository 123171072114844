.TimeControlPopup {
  $popup-height: 200px;
  $popup-width: 130px;
  $triangle-height: 40px;
  $triangle-width: 20px;
  $button-size: 40px;
  $line-spacing-top: 14px;
  $line-height: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: z($stacking-global, timecontrol-popup);

  // TODO: A component should never position itself absolutely! Its the job of the parent.
  position: absolute;

  height: $popup-height;
  width: $popup-width;
  border: $border-width-base var(--primary-fg-minor);
  border-radius: $border-radius-base;
  background: var(--primary-bg-main);
  box-shadow: $balloon-box-shadow;
  padding: $space-s;
  padding-bottom: 0;

  // TODO: this transform seems way to complex to me
  transform: translate(calc(-100% - #{$timecontrol-handle-size}), calc(-50% - #{$timecontrol-handle-border-width} / 2));

  &:before {
    @include scut-triangle(right, $triangle-width $triangle-height, var(--primary-bg-main));
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    content: " ";
  }

  &__line {
    margin-top: $line-spacing-top;
    height: $line-height;
    width: 100%;
    background: var(--primary-bg-minor);
  }

  &__number {
    width: 100%;
    text-align: center;
    font-size: $font-size-xl;
    padding: $space-xxs;
  }
}
