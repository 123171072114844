// Margin

.u-space-xxxs {
  margin-bottom: $space-xxxs;
}
.u-space-xxs {
  margin-bottom: $space-xxs;
}
.u-space-xs {
  margin-bottom: $space-xs;
}
.u-space-s {
  margin-bottom: $space-s;
}
.u-space-base {
  margin-bottom: $space-base;
}
.u-space-l {
  margin-bottom: $space-l;
}
.u-space-xl {
  margin-bottom: $space-xl;
}
.u-space-xxl {
  margin-bottom: $space-xxl;
}
.u-space-xxl-media-s-only {
  @include media(s-only) {
    margin-bottom: $space-xxl;
  }
}
.u-space-xxl-media-m-only {
  @include media(m-only) {
    margin-bottom: $space-xxl;
  }
}
.u-space-xxl-media-l-only {
  @include media(l-only) {
    margin-bottom: $space-xxl;
  }
}
.u-space-xxl-media-s-to-l {
  @extend .u-space-xxl-media-s-only;
  @extend .u-space-xxl-media-m-only;
  @extend .u-space-xxl-media-l-only;
}
.u-space-xxxl {
  margin-bottom: $space-xxxl;
}
.u-space-auto {
  margin-bottom: auto;
}

.u-space-top-xxxs {
  margin-top: $space-xxxs;
}
.u-space-top-xxs {
  margin-top: $space-xxs;
}
.u-space-top-xs {
  margin-top: $space-xs;
}
.u-space-top-s {
  margin-top: $space-s;
}
.u-space-top-base {
  margin-top: $space-base;
}
.u-space-top-l {
  margin-top: $space-l;
}
.u-space-top-xl {
  margin-top: $space-xl;
}
.u-space-top-xxl {
  margin-top: $space-xxl;
}
.u-space-top-xxxl {
  margin-top: $space-xxxl;
}
.u-space-top-auto {
  margin-top: auto;
}

// Padding

.u-padding-base {
  padding: $space-base;
}
.u-padding-l {
  padding: $space-l;
}
.u-padding-xl {
  padding: $space-xl;
}
.u-padding-xxl {
  padding: $space-xxl;
}
.u-padding-xxxl {
  padding: $space-xxxl;
}
.u-padding-xxxxl {
  padding: $space-xxxxl;
}

.u-padding-top-base {
  padding-top: $space-base;
}
.u-padding-top-l {
  padding-top: $space-l;
}
.u-padding-top-xl {
  padding-top: $space-xl;
}
.u-padding-top-xxl {
  padding-top: $space-xxl;
}
.u-padding-top-xxxl {
  padding-top: $space-xxxl;
}
.u-padding-top-xxxxl {
  padding-top: $space-xxxxl;
}

.u-padding-bottom-base {
  padding-bottom: $space-base;
}
.u-padding-bottom-l {
  padding-bottom: $space-l;
}
.u-padding-bottom-xl {
  padding-bottom: $space-xl;
}
.u-padding-bottom-xxl {
  padding-bottom: $space-xxl;
}
.u-padding-bottom-xxxl {
  padding-bottom: $space-xxxl;
}
.u-padding-bottom-xxxxl {
  padding: $space-xxxxl;
}

// Extend click areas

.u-extended-click-area {
  padding: $space-xl;
}

// TODO: remove this hack
// Just for preview feature modal overlay in main navigation
.u-padding-top-left-right-xl {
  padding: $space-xl $space-xl 0 $space-xl;
}

// Space children
.u-space-children-base {
  & > * + * {
    margin-top: $space-base;
  }
}
.u-space-children-l {
  & > * + * {
    margin-top: $space-l;
  }
}
.u-space-children-xl {
  & > * + * {
    margin-top: $space-xl;
  }
}
.u-space-children-xxl {
  & > * + * {
    margin-top: $space-xxl;
  }
}
.u-space-children-xxxl {
  & > * + * {
    margin-top: $space-xxxl;
  }
}
